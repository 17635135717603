// export default {
//   demo: false,
//   brand: "STEPapp", // Footer brand
//   appName: "Padhao", // App Name
//   showCredits: true, // Show credits in login page
//   updateAnimation: "https://userindexer.stepapp.in/updateAnimations",
//   url: "https://padhao.stepapp.in",
//   contentUrl: "https://8ytwl5trrl.execute-api.ap-south-1.amazonaws.com/PROD",
//   calendarUrl: "https://calender.stepapp.in:3002",
//   updateAnimation: "https://userindexer.stepapp.in/updateAnimations",
//   forceResync: "https://userindexer.stepapp.in/updateChapter",
//   dashboardUrl: "https://7tfx1ljzua.execute-api.ap-south-1.amazonaws.com/PROD",
//   quizUrl: "https://2juw9d7jic.execute-api.ap-south-1.amazonaws.com/PROD",
//   assessmentUrl: "https://bcd9sxa6gj.execute-api.ap-south-1.amazonaws.com/PROD",
//   doubtUrl: "https://riwcbssft5.execute-api.ap-south-1.amazonaws.com/PROD"
// };


export default {
  demo: false,
  brand: "STEPapp", // Footer brand
  appName: "Padhao", // App Name
  showCredits: true, // Show credits in login page
  // updateAnimation: "https://userindexer.stepapp.in/updateAnimations",
  url: "https://padhao.stepapp.in",
  contentUrl: "https://api.stepapp.in",
  calendarUrl: "https://calender.stepapp.in:3002",
  updateAnimation: "https://8ytwl5trrl.execute-api.ap-south-1.amazonaws.com/PROD/updateanimations",
  forceResync: "https://8ytwl5trrl.execute-api.ap-south-1.amazonaws.com/PROD/updateanimations",
  dashboardUrl: "https://dashapi.stepapp.in",
  quizUrl: "https://quizapi.stepapp.in",
  assessmentUrl: "https://liveapi.stepapp.in",
  doubtUrl: "https://doubtapi.stepapp.in"
};
