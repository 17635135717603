import React, { useCallback } from 'react';
import "./DraggableItem.scss";
import Tooltip from "rc-tooltip";

const DraggableItem = ({
  item,
  index,
  onDragStart,
  onDragOver,
  onDrop,
  isDragging,
  isOver,
  style,
  addToast,
  overlay,
  overlayInnerStyle,
}) => {
  const className = `draggable-item ${isDragging ? "dragging" : ""} ${isOver ? "placeholder" : ""}`;

  const handleDrop = () => {
    onDrop(index);
    addToast("Question Position changed successfully", {
      appearance: "success",
      autoDismiss: true,
    });
  }

  return (
    <Tooltip
      placement="bottom"
      trigger={["hover"]}
      overlay={<span>{overlay}</span>}
      overlayInnerStyle={overlayInnerStyle}
    >
      <div
        key={index}
        draggable
        onDragStart={() => onDragStart(index)}
        onDragOver={(event) => {
          event.preventDefault();
          onDragOver(event, index);
        }}
        onDrop={handleDrop}
        className={className}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <div
          className={`item-content ${style}`}
          style={{
            backgroundColor: "#f7af86",
            height: "45px",
            width: "45px",
            color: "#000",
            cursor: "grab",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>{item + 1 < 10 ? `0${item + 1}` : item + 1}</div>
        </div>
      </div>
    </Tooltip>
  );
};

export default DraggableItem;
