import SectionFilter from "./section_filter/section_filter"
import Student_name_filter from "./student_name_filter/student_name_filter"
import SubjectFilter from "./subject_filter/subject_filter"
import Teacher_name_filter from "./teacher_name_filter/teacher_name_filter"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
function Filters(props) {
    const [isFilterOpen, setIsFilterOpen] = useState(false)

    const [filterInUse, setFilterInUse] = useState('')
    console.log("this is filter log",props);
    const { t } = useTranslation();
    // debugger
    return (
        <>
            <div className="filter_section">
                <div className="filter_title" onClick={() => { setIsFilterOpen(!isFilterOpen) }}>{t("dashboard.filter_analysis")}</div>
                <div className={`wrapper_for_page_padding filtering_mobile ${isFilterOpen ? "show" : ""}`}>
                    <div className="row">
                        <SectionFilter classNameList={props.classNameList} sectionNameList={props.sectionNameList} onApplyFilterClick={props.onApplyFilterClick} batchNameList={props.batchNameList} setFilterInUse={setFilterInUse} filterInUse={filterInUse} grade_subject_filter={props.grade_subject_filter} />
                        <div className="col-md-1 middle_border_block"><div><span>{t("dashboard.or")}</span></div></div>
                        <SubjectFilter classNameList={props.classNameList} subjectNameList={props.subjectNameList} onApplyFilterClick={props.onApplyFilterClick} setIsFilterOpen={setIsFilterOpen} setFilterInUse={setFilterInUse} filterInUse={filterInUse}  />
                        <div className="col-md-1 middle_border_block"><div><span>{t("dashboard.or")}</span></div></div>
                        <div className="col-md-4">
                            <div className="subfilter">
                                <Student_name_filter onApplyFilterClick={props.onApplyFilterClick} schoolId={props.schoolId} setIsFilterOpen={setIsFilterOpen} setFilterInUse={setFilterInUse} filterInUse={filterInUse} />
                                <div className="or_block"><div><span>{t("dashboard.or")}</span></div></div>
                                <Teacher_name_filter onApplyFilterClick={props.onApplyFilterClick} schoolId={props.schoolId} setIsFilterOpen={setIsFilterOpen} setFilterInUse={setFilterInUse} filterInUse={filterInUse} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
} export default Filters