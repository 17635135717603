import Body from 'elements/Body';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from "react-i18next";

import {
    getUserExtraSubjectContent
} from "../../../../services/dataServices/userContentService";


function SkillDetails(props) {
    const [courseData, setCourseData] = useState([]);

    const value = props.match.params.commerce_subject_name;
    console.log("commerce_subject_name", value)
    const { t } = useTranslation();

    const history = useHistory()

    useEffect(() => {
        const fetchData = async () => {
          try {
            const offlineData = await getUserExtraSubjectContent();
            setCourseData(offlineData);
          } catch (error) {
            console.error('Error fetching offline data:', error);
          }
        };
    
        fetchData();
      }, []);

    const handleLessonClick = (item) => {
        // console.log(`/${item.Subject}/chapter/skill-video-player`, { item })
        history.push(`/${item.Subject}/chapter/skill-video-player`, { item });
    }

    return (
        <Body
            component={
                <div id="CommonPageHolder">
                    <div className="container uk-animation-customslide">
                        <div className='mt-5 mb-3'>
                            <span className='font-weight-bold' style={{ fontSize: '24px' }}>{value}</span>
                        </div>
                        <div className='d-flex flex-column'>
                            {courseData.length>0 && courseData.filter(s=>s.Subject===value).map((item, index) => (
                                <div key={index}>
                                    <button
                                        className="btn btn-light mb-1"
                                        style={{ width: '100%', textAlign: 'start', fontSize: '18px' }}
                                        onClick={() => handleLessonClick(item)}
                                        aria-controls={`collapsibleContent${index}`}
                                    >
                                        {`${index + 1}. ${item['Chapter Name']}`}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        />
    );
}

export default SkillDetails;
