import React, { Component } from 'react';
import CanvasJSReact from '../../../../../assets/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
 
class PyramidChart extends Component {
	
	render() {
		 
		var dataPoint;
		var total;
		const options = {
			
			animationEnabled: true,
			title: {
				text: "SKILL DEVELOPMENT", 
				horizontalAlign: "left",
				fontSize:20,
			},
			legend: {
				horizontalAlign: "right",
				verticalAlign: "center",
				reversed: true
			},
			data: [{
				type: "pyramid",
				showInLegend: true,
				legendText: "{label}",
				indexLabel: "{label} - {percentage}",
				toolTipContent: "<b>{label}</b>: {y} <b>({percentage})</b>",
				dataPoints: [
					{ label: "Recall", y: 100,color:"#7030a0"},
					{ label: "Understand", y: 100,color:"#0070c0"},
					{ label: "Apply", y: 100,color:"#00b050"},
					{ label: "Analyze", y: 100,color:"#ffd54f"},
					{ label: "Evaluate", y: 100,color:"#ff9933"},
					{ label: "Create", y: 100,color:"#ff2f2f"}
				]
			}]
		}
		//calculate percentage
		dataPoint = options.data[0].dataPoints;
		options.data[0].dataPoints[0].percentage = this.props.bloomRecallData;
		options.data[0].dataPoints[1].percentage = this.props.bloomUnderstandData;
		options.data[0].dataPoints[2].percentage = this.props.bloomApplyData;
		options.data[0].dataPoints[3].percentage = this.props.bloomAnalyseData;
		options.data[0].dataPoints[4].percentage = this.props.bloomEvaluateData;
		options.data[0].dataPoints[5].percentage = this.props.bloomCreateData
		
		return (
			<CanvasJSChart options = {options} />
		);
	}
}

export default PyramidChart;