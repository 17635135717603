
import React from 'react';
import { Line } from 'react-chartjs-2';
import graphOptions from './options';
import store from 'store/store';
import { useTranslation } from 'react-i18next';

function TeacherDefaultGraph(props) {
    const {t} = useTranslation();
    var { user } = store.getState();  

    const options = graphOptions


    if (user.user_type === "superadmin") {

        if (props.performanceGraph.length > 0) {
            let allSections = props.performanceGraph[0].overall_subject_graph
            let grade_subjectWise = props.performanceGraph[0].board_school_gradewise_subject_graph
            let labels = [ t("dashboard.progress"),t("dashboard.total_score"), t("dashboard.overall_accuracy"), t("dashboard.time_efficiency")];
       
            let data = {
                labels,
                datasets: [
                    {
                        label: t(`subjects.${props.subjectName}`) + " "+t("super_admin_dashboard.average"),
                        data: [allSections.progress,allSections.total_score, allSections.overall_accuracy, allSections.time_efficiency],
                        borderColor: '#FC8643',
                        backgroundColor: '#FC8643',
                        fill: false,
                        lineTension: 0,
                        borderWidth: 1.3131,
                        pointBackgroundColor: "#FC8643"
                    }
                ]

            }
            return (
                <>
                    <div className='line_chart_section'>
                        <Line options={options} data={data} />
                    </div>
                </>
            )
        } else {
            return ("")
        }

    }
    else {


        if (props.performanceGraph.length > 0) {
            let allSections = props.performanceGraph[0].overall_subject_graph
            let grade_subjectWise = props.performanceGraph[0].board_school_gradewise_subject_graph
            let labels = [ t("dashboard.progress"),t("dashboard.total_score"), t("dashboard.overall_accuracy"), t("dashboard.time_efficiency")];
       
            let data = {
                labels,
                datasets: [
                    {
                        label: t("dashboard.grade") +" "+ props.gradeName,
                        data: [ allSections.progress,allSections.total_score, allSections.overall_accuracy, allSections.time_efficiency],
                        borderColor: '#FC8643',
                        backgroundColor: '#FC8643',
                        fill: false,
                        lineTension: 0,
                        borderWidth: 1.3131,
                        pointBackgroundColor: "#FC8643"
                    },
                    {
                        label: t("dashboard.section") +" "+ props.selectedBatch,
                        data: [ grade_subjectWise.progress,grade_subjectWise.total_score, grade_subjectWise.overall_accuracy, grade_subjectWise.time_efficiency],
                        borderColor: '#5164A6',
                        backgroundColor: '#5164A6',
                        fill: false,
                        lineTension: 0,
                        borderWidth: 1.3131,
                        pointBackgroundColor: "#5164A6"
                    },
                ]

            }
            return (
                <>
                    <div className='line_chart_section'>
                        <Line options={options} data={data} />
                    </div>
                </>
            )
        } else {
            return ("")
        }
    }




} export default TeacherDefaultGraph