import store from "../../store/store";
import {
  parseQueryParams,
  loginUserWithToken,
  getEncodedJWTToken,
  showWarningMessage,
} from "services/utility";
import { getSelectedBoardAndGradeFromLocal } from "../dataServices/userProfileService";
import {
  getSubjectDataToDisplay,
  getSubjectsFromLocal,
} from "../dataServices/userContentService";
import { refreshUser as refreshUserAction } from "../../reducers/user/actions";
import { setConfig } from "../../reducers/config/actions";
import { isLoggedInSelector } from "../../reducers/auth/selectors";
import routesNotAllowed from "./student-not-allowed-routes";
// import routesNotAllowed from "./student-not-allowed-routes-iitpace";

const routeGuard = async (props) => {
  let isIITPaceBranch = false;
  let next = {
    isAllowed: true,
    newLocation: "/",
  };
  // console.log("route guard ------------->", props.location);
  const { refreshUser = false } = parseQueryParams(props.location.search);
  const { t } = parseQueryParams(props.location.search);
  let isTokenLogin = false;
  if (t) {
    isTokenLogin = await loginUserWithToken(t);
    // console.log("$$returned",isTokenLogin);
  }
  const state = store.getState();
  // console.log("state in route guard ------->", state.config);
  if (
    state.user?.user_type === "teacher" &&
    props.location.pathname === "/"
  ) {
    next.isAllowed = false;
    next.newLocation = "/dashboard";
    return next;
  }
  let actualPath = props.location.pathname.split("/");
  if (
    state.user?.user_type === "student" &&
    routesNotAllowed.includes(actualPath[1])
  ) {
    showWarningMessage(
      "You are not authorize for this page, Therefore redirecting to concept page"
    );
    next.isAllowed = false;
    next.newLocation = "/concepts";
    return next;
  }
  // const inputData = {
  //   user_mobile: state.user.user_mobile,
  //   country_code: state.user.country_code,
  //   school_id: state.user.school_id,
  // };
  // let authToken = getEncodedJWTToken(inputData);
  // console.log("authToken ",authToken);
  const isLoggedIn = state.user && state.user?.id && state.user?.grade_name != "" ? true : false;
  // const isLoggedIn = await isLoggedInSelector(state);
  const canAccessLiveClasses = state.user?.school?.activatedProducts?.includes(
    "liveclasses"
  );
  // console.log("$$isLoggedIn", isLoggedIn);
  store.dispatch(setConfig({ enableChangeLanguage: false }));

  if (refreshUser) {
    store.dispatch(refreshUserAction());
  }
  // console.log("props.meta", props.meta.public);
  if (props.meta.public) return next;

  // If user is not loggedin and access private route
  if (props.meta.auth && !isLoggedIn) {
    // console.log("props.location.pathname", props.location.pathname);
    // console.log("state in if condition------->", state.config);
    store.dispatch(setConfig({ redirectUrl: props.location.pathname }));
    next.isAllowed = false;

    if (state.user && state.user?.user_type === "student") {
      next.newLocation = isIITPaceBranch ? "/login/student" : "/student/login";
      return next;
    }
    if (state.user && state.user?.user_type === "teacher") {
      next.newLocation = isIITPaceBranch ? "/login/teacher" : "/teacher/login";
      return next;
    }
    next.newLocation = "/login";
    return next;
  }

  // this is for when share URL and open this url ofter login
  if (state.config.redirectUrl != "" && isLoggedIn) {
    next.newLocation = state.config.redirectUrl;
    next.isAllowed = false;
    store.dispatch(setConfig({ redirectUrl: "" }));
    return next;
  }

  // If user is loggedin and access the public route
  if (props.meta.guest && isLoggedIn) {
    next.isAllowed = false;
    next.newLocation = "/concepts";
    if (state.user && state.user?.user_type === "student") return next;
    else if (state.user && state.user?.user_type === "teacher") {
      next.newLocation = "/home";
      return next;
    } else return next;
  }

  if (props.meta.guest && !isLoggedIn) {
    if (props.location.pathname === "/student/login") {
      next.isAllowed = false;
      next.newLocation = "/login";
      return next;
    }
    // } else if (props.location.pathname === "/teacher/login") {
    //   return true;
    // } else if (props.location.pathname === "/student/login") {
    //   return true;
    // } else return next.redirect("/login");
  }

  if (props.location.pathname === "/live-classes" && !canAccessLiveClasses) {
    next.isAllowed = false;
    next.newLocation = "/concepts";
    if (state.user && state.user?.user_type === "student") return next;
    else if (state.user && state.user?.user_type === "teacher") {
      next.newLocation = "/home";
      return next;
    } else return next;
  }
  // console.log('route guard - access allowed');
  //Code for getting feed data in case of routes other than select subject
  if (isLoggedIn && props.match.params.subjectslug) {
    let userData = await getSelectedBoardAndGradeFromLocal();
    if (!userData) {
      const { user } = store.getState();
      userData = user;
    }
    // console.log("userData", userData);
    if (userData) {
      const queryString =
        userData.selectedBoard +
        `&gradeId=` +
        encodeURIComponent(userData.selectedGrade) +
        `&schoolId=` +
        userData.selectedSchool +
        `&language=` +
        userData.selectedLanguage +
        `&platform=web`;
      let subjectContent = await getSubjectsFromLocal();
      let subjectContentData = subjectContent[0] ? subjectContent[0].data : [];
      if (subjectContentData == null) {
        subjectContentData = await getSubjectDataToDisplay(queryString);
      }
      if (subjectContentData) {
        // console.log("props.location.pathname", props.location.pathname);
        if (props.match.params.subjectslug) {
          for (
            let subjectIndex = 0;
            subjectIndex < subjectContentData.length;
            subjectIndex++
          ) {
            if (
              subjectContentData[subjectIndex].slug ==
              props.match.params.subjectslug
            ) {
              return next;
            }
            // if (subjectIndex + 1 == subjectContentData.length) {
            //   // console.log(
            //   //   "props.location.pathname no content",
            //   //   props.location.pathname
            //   // );
            //   store.dispatch(setConfig({ redirectUrl: "/nocontent" }));
            //   next.isAllowed = false;
            //   next.newLocation = "/nocontent";
            //   return next;
            // }
          }
        }
        // store.dispatch(setConfig({ redirectUrl: props.location.pathname }));
        return next;
      } else {
        // console.log("props.location.pathname no content", props.location.pathname);
        store.dispatch(setConfig({ redirectUrl: "/nocontent" }));
        next.isAllowed = false;
        next.newLocation = "/nocontent";
        return next;
      }
    }
  }
  return next;
};

export default routeGuard;
