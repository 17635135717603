import React from 'react';
import { Line } from 'react-chartjs-2';
import graphOptions from './options';
import store from 'store/store';
import { useTranslation } from 'react-i18next';

function GraphForChapter(props) {
    const { t } = useTranslation();

    var { user } = store.getState();
    console.log("userProfileData", user.user_type)

    const options = graphOptions

    if (user.user_type === "superadmin") {


        if (props.chapters.length !== 0) {
            let labels = [t("dashboard.progress"), t("dashboard.total_score"), t("dashboard.overall_accuracy"), t("dashboard.time_efficiency")];


            let allSections = props.chapters.chapter_performance_graph.overall_chapter_performance
            let grade_subjectWise = props.chapters.chapter_performance_graph.chapter_performance



            let data = {
                labels,
                datasets: [
                    {
                        label: t("dashboard.grade") + " " + props.className,
                        data: [allSections.progress, allSections.total_score, Math.floor(allSections.overall_accuracy), Math.floor(allSections.time_efficiency)],
                        borderColor: '#FC8643',
                        backgroundColor: '#FC8643',
                        fill: false,
                        lineTension: 0,
                        borderWidth: 1.3131,
                        pointBackgroundColor: "#FC8643"
                    }
                ]
            }
            return (
                <>
                    <div className='line_chart_section'>
                        <Line options={options} data={data} />
                    </div>


                </>
            )
        } else {
            return ("")
        }
    }
    else {

        if (props.chapters.length !== 0) {
            let labels = [t("dashboard.progress"), t("dashboard.total_score"), t("dashboard.overall_accuracy"), t("dashboard.time_efficiency")];


            let allSections = props.chapters.chapter_performance_graph.overall_chapter_performance
            let grade_subjectWise = props.chapters.chapter_performance_graph.chapter_performance

            let translated_batch = props.selectedBatch === "all" ? t("subjects.all") : props.selectedBatch

            let data = {
                labels,
                datasets: [
                    {
                        label: t("dashboard.grade") + " " + props.className,
                        data: [allSections.progress, allSections.total_score, Math.floor(allSections.overall_accuracy), Math.floor(allSections.time_efficiency)],
                        borderColor: '#FC8643',
                        backgroundColor: '#FC8643',
                        fill: false,
                        lineTension: 0,
                        borderWidth: 1.3131,
                        pointBackgroundColor: "#FC8643"
                    },
                    {
                        label: t("dashboard.section") + " " + translated_batch,
                        data: [grade_subjectWise.progress, grade_subjectWise.total_score, Math.floor(grade_subjectWise.overall_accuracy), Math.floor(grade_subjectWise.time_efficiency)],
                        borderColor: '#5164A6',
                        backgroundColor: '#5164A6',
                        fill: false,
                        lineTension: 0,
                        borderWidth: 1.3131,
                        pointBackgroundColor: "#5164A6"
                    }
                ]
            }
            return (
                <>
                    <div className='line_chart_section'>
                        <Line options={options} data={data} />
                    </div>


                </>
            )
        } else {
            return ("")
        }
    }

} export default GraphForChapter