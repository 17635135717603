import { LOGIN, LOGOUT } from "./types";
import { setUserLoggedOut } from "services/dataServices/userProfileService";

const INITIAL_STATE = {
  isAuthenticated: false,
  token: null,
};

export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case LOGIN: {
      return {
        ...state,
        isAuthenticated: true,
        token: payload.token,
      };
    }
    case LOGOUT: {
      setUserLoggedOut();
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }
    default:
      return state;
  }
}
