import React, { useEffect } from "react"
// import GraphForChapter from "../common/graph_for_chapter"
import GraphForChapter from "components/common/graph_for_chapter"
import { useState } from "react"
import { useTranslation } from "react-i18next"

function ChapterReportingDashboard(props) {
    // debugger

    const [graphData, setGraphData] = useState([])
    const { t } = useTranslation()

    console.log("hello--+", props);

    useEffect(() => {

        var chapters = []
        if (props.chapterWisePerformance) {
            chapters = props?.chapterWisePerformance[0]?.find(e => e.chapter_id == props.chapterId)
        } else {
            chapters = props?.chapterTopicPerformance.find(e => e.chapter_id == props.chapterId)
        }
        setGraphData(chapters)
    }, [props.chapterId])



    return (
        <>
            <div className="teacher_report">
                <div className="teacher_report_heading"><span>{graphData?.translated_chapter_name}</span> {t("super_admin_dashboard.performance")}</div>
                <GraphForChapter className={props.className} chapters={graphData} selectedBatch={props.selectedBatch} />


                <div className="insight_section">
                    <div className="teacher_report_heading"><span>{graphData?.translated_chapter_name}</span>&nbsp; {t("teacher_dashboard.insights")}</div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="insight_heading">{t("teacher_dashboard.topic_wise")} {t("teacher_dashboard.insights")}</div>
                            <div className="insight_block_bordered_card">
                                <div className="upper">
                                    <div className="upper_heading">{t("teacher_dashboard.strongest_topic")} :</div>
                                    <div className="upper_content">
                                        {props?.insightData?.topic_wise_insights?.strong_translated_topics?.length === 0 ? <div>{t("teacher_dashboard.no_data")}</div> : ""}
                                        {

                                            props?.insightData?.topic_wise_insights && props.insightData.topic_wise_insights.strong_translated_topics.map((val, index) => {
                                                return (<div>{index + 1}. {val}</div>)
                                            })

                                        }

                                    </div>
                                </div>
                                <div className="lower">
                                    <div className="lower_heading">{t("teacher_dashboard.weakest_topic")}:</div>
                                    <div className="lower_content">
                                        {props?.insightData?.topic_wise_insights?.weak_translated_topics?.length === 0 ? <div>{t("teacher_dashboard.no_data")}</div> : ""}
                                        {
                                            props?.insightData?.topic_wise_insights && props.insightData.topic_wise_insights.weak_translated_topics.map((val, index) => {
                                                return (<div>{index + 1}. {val}</div>)
                                            })

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="insight_heading">{t("teacher_dashboard.stu_wise")} {t("teacher_dashboard.insights")}</div>
                            <div className="insight_block_bordered_card">
                                <div className="upper">
                                    <div className="upper_heading">{t("teacher_dashboard.chapter_wise")} {t("teacher_dashboard.ranklist")} :</div>
                                    <div className="upper_content">

                                        {props?.insightData?.student_wise_insights?.strong_in_topics?.length === 0 ? <div>{t("teacher_dashboard.no_data")}</div> : ""}
                                        {
                                            props?.insightData?.student_wise_insights?.strong_in_topics && props.insightData.student_wise_insights.strong_in_topics
                                                .map((val, index) => {
                                                    return (<div>{index + 1}.  {val.first_name === '' || val.first_name === ' ' || val.first_name === null ? val.user_id : val.first_name + ' ' + val.last_name}<span>{Math.floor(val.score)}%</span></div>)
                                                })

                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
} export default ChapterReportingDashboard