import React from "react"
import { useTranslation } from "react-i18next"

function StudentCount(props) {
    const {t} = useTranslation();

    return (
        <>
            <div className="student_count_block">
                <div className="total_stu">
                    <svg width="40" height="29" viewBox="0 0 40 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.0556 0.483372C8.11668 0.724693 6.29429 1.80648 5.17922 3.3709C3.76459 5.34307 3.44837 7.87278 4.32212 10.1362C4.99615 11.892 6.62715 13.498 8.41625 14.1721C9.68943 14.6464 11.3371 14.7463 12.6352 14.4134C13.9167 14.0889 15.2398 13.29 16.1468 12.2998C19.0926 9.07106 18.4435 3.97836 14.7821 1.59012C13.4091 0.691407 11.6533 0.283658 10.0556 0.483372Z" fill="#FC8643" />
                        <path d="M26.0327 0.483372C24.0938 0.724693 22.2714 1.80648 21.1563 3.3709C19.7417 5.34307 19.4255 7.87278 20.2992 10.1362C20.9733 11.892 22.6043 13.498 24.3934 14.1721C25.6665 14.6464 27.3142 14.7463 28.6123 14.4134C29.8938 14.0889 31.2169 13.29 32.124 12.2998C35.0697 9.07106 34.4207 3.97836 30.7592 1.59012C29.3862 0.691407 27.6304 0.283658 26.0327 0.483372Z" fill="#FC8643" />
                        <path d="M9.04015 16.4688C4.43009 17.0014 0.843568 20.6295 0.327641 25.3061C0.211141 26.3463 0.211141 27.0287 0.335962 27.5113C0.469105 28.0439 1.0516 28.6264 1.58417 28.7595C1.85878 28.8344 3.33167 28.8594 6.60198 28.8594C10.9707 28.8594 11.2287 28.8511 11.1871 28.7096C10.9125 27.7693 10.8625 27.1119 10.9707 25.9302C11.262 22.6683 12.5851 19.6559 14.707 17.4424C14.9067 17.2261 15.0648 17.0263 15.0399 17.0097C14.94 16.9015 13.3506 16.5437 12.6766 16.4605C11.7945 16.3606 9.94718 16.3689 9.04015 16.4688Z" fill="#314075" />
                        <path d="M24.2435 16.4273C21.1146 16.7602 18.4185 18.2663 16.5795 20.7045C15.3146 22.3771 14.4658 24.8486 14.4658 26.8707C14.4658 27.6279 14.7654 28.2104 15.3396 28.5682L15.739 28.8179L26.69 28.8429C39.0639 28.8678 38.2401 28.9094 38.8309 28.2354C39.2969 27.7028 39.3884 27.2868 39.2719 26.1384C39.0639 24.0747 38.4065 22.3355 37.2332 20.7461C35.6604 18.6242 33.4386 17.1929 30.7924 16.6187C30.0768 16.4606 29.669 16.444 27.2808 16.419C25.7913 16.4107 24.4265 16.4107 24.2435 16.4273Z" fill="#314075" />
                    </svg>
                    <div>{t("super_admin_dashboard.total_students")}<br />  ({props.stats.total_students})</div>
                </div>
                <div className="sign">=</div>
                <div className="active_stu">
                    <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.41 0.750399C23.4356 1.01345 22.5304 1.76107 22.095 2.66791C20.9272 5.10461 22.6617 7.91511 25.329 7.90819C26.0408 7.90127 26.3863 7.82512 27.0083 7.52053C27.5818 7.22979 28.2936 6.54447 28.57 5.9976C28.9846 5.18075 29.0952 4.1147 28.8602 3.29093C28.5009 2.05874 27.4989 1.06883 26.2619 0.743476C25.792 0.61195 24.8937 0.618873 24.41 0.750399Z" fill="#FC8643" />
                        <path d="M15.1088 3.92786C15.0743 3.94171 13.5816 4.39167 11.7919 4.93162C9.63583 5.58232 8.46107 5.96998 8.28832 6.08074C6.98917 6.98066 7.542 9.05738 9.11756 9.20275C9.48381 9.23737 9.76022 9.16814 12.3309 8.39283L15.1503 7.54137L16.8779 8.70434C17.8246 9.3412 18.5986 9.895 18.5986 9.92961C18.5986 9.97114 18.3567 10.255 18.0596 10.5665C13.865 14.9207 11.4947 17.5027 11.3081 17.8973C10.9972 18.5688 11.239 19.5241 11.8402 19.9533C12.0475 20.1056 12.9528 20.4309 15.171 21.1578C16.8433 21.7047 18.3498 22.1962 18.5156 22.2446C18.7368 22.3069 18.799 22.3554 18.7713 22.4384C18.7506 22.5007 18.1978 23.5322 17.5482 24.7298C14.6942 29.9908 14.9084 29.5616 14.8808 30.0047C14.8048 31.0153 15.5856 31.8599 16.5946 31.8599C17.0368 31.8599 17.5689 31.6383 17.8453 31.3338C18.0388 31.1192 22.8692 22.2308 23.0074 21.8293C23.2354 21.1786 22.9175 20.3271 22.3094 19.9325C22.0814 19.7802 20.8444 19.358 16.9746 18.0981C16.56 17.9665 16.1868 17.8281 16.1523 17.7935C16.1108 17.7658 16.3527 17.4612 16.7397 17.0597C17.099 16.6928 17.6242 16.1252 17.9075 15.7998C18.1908 15.4814 19.4002 14.1938 20.5887 12.9409C21.7773 11.6879 22.8139 10.5388 22.883 10.4003C23.1732 9.8327 23.1179 9.12661 22.7448 8.60743C22.5997 8.4136 21.7359 7.78366 19.5936 6.33687C17.9697 5.24313 16.4771 4.25322 16.2698 4.14246C15.9312 3.95555 15.3161 3.83787 15.1088 3.92786Z" fill="#314075" />
                        <path d="M31.5136 6.30909C31.4168 6.33678 31.2648 6.406 31.175 6.45446C31.0229 6.53753 28.6043 8.64195 26.6971 10.3518L25.7849 11.1756L25.5499 10.9748C25.4186 10.871 25.0247 10.5387 24.6792 10.241C24.3337 9.95029 24.0227 9.72185 23.9951 9.74261C23.9606 9.75646 23.9191 9.91568 23.9053 10.0887C23.8431 10.6218 23.5459 11.1063 22.8065 11.8816L22.1224 12.6016L22.6199 13.0446C24.1817 14.4222 24.8036 14.9344 25.0869 15.059C25.5085 15.2459 26.0682 15.2459 26.4759 15.059C26.6625 14.969 27.6645 14.1314 29.0051 12.9477C30.2213 11.8747 31.7071 10.5595 32.2944 10.0334C33.4416 9.02268 33.6212 8.78732 33.7041 8.1643C33.7594 7.79049 33.6005 7.2367 33.3448 6.90442C32.9924 6.44062 32.0526 6.13603 31.5136 6.30909Z" fill="#314075" />
                        <path d="M9.33848 20.4099C8.82711 21.116 8.37794 21.7183 8.33648 21.746C8.30193 21.7806 6.77474 21.8567 4.95041 21.919C2.56633 22.009 1.52978 22.0713 1.26719 22.1405C0.00950464 22.4936 -0.391296 24.0927 0.555422 25.0133C1.04606 25.491 1.23264 25.5187 3.13989 25.4633C6.38085 25.3664 9.51815 25.2141 9.7531 25.1449C9.89131 25.1103 10.1055 24.9995 10.2299 24.9095C10.4303 24.7572 12.9733 21.4206 12.918 21.3721C12.9042 21.3583 12.6485 21.2683 12.3445 21.1645C11.6742 20.943 11.2112 20.6522 10.8933 20.2715C10.6376 19.96 10.3819 19.4408 10.375 19.24C10.375 19.1777 10.3543 19.1224 10.3267 19.1293C10.2921 19.1293 9.84985 19.7108 9.33848 20.4099Z" fill="#314075" />
                    </svg>
                    <div>{t("super_admin_dashboard.active_students")}<br />  ({props.stats.total_active_students})</div>
                </div>
                <div className="sign">+</div>
                <div className="inactive_stu">
                    <svg width="42" height="38" viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.4757 0.135615C20.2322 0.277992 20.034 0.659821 20.034 0.970461C20.034 1.29405 20.2322 1.66293 20.4871 1.81178C20.6739 1.9218 20.8212 1.94121 21.461 1.94121H22.2085L21.2119 3.0867C20.6683 3.72092 20.1756 4.32926 20.1246 4.44575C19.9265 4.89877 20.0963 5.4618 20.4984 5.68831C20.7419 5.81775 20.8212 5.82422 22.6049 5.82422C24.349 5.82422 24.4679 5.81775 24.6775 5.69479C24.9323 5.54594 25.1305 5.17705 25.1305 4.85347C25.1305 4.52988 24.9323 4.161 24.6775 4.01215C24.4906 3.90213 24.3434 3.88272 23.7035 3.88272H22.956L23.9526 2.73076C24.4963 2.103 24.9889 1.49467 25.0399 1.37818C25.2381 0.92516 25.0682 0.362124 24.6661 0.135615C24.4226 0.00618172 24.3434 -0.000289917 22.5483 -0.000289917C20.8042 0.00618172 20.6739 0.0126534 20.4757 0.135615Z" fill="#314075" />
                        <path d="M13.1951 5.94288C12.9516 6.06746 12.7534 6.40156 12.7534 6.67337C12.7534 6.95651 12.9516 7.27928 13.2064 7.40953C13.3933 7.50579 13.5405 7.52278 14.1804 7.52278H14.9279L13.9312 8.52508C13.3876 9.08003 12.8949 9.61232 12.844 9.71425C12.6458 10.1106 12.8157 10.6033 13.2177 10.8015C13.4612 10.9147 13.5405 10.9204 15.3242 10.9204C17.0684 10.9204 17.1873 10.9147 17.3968 10.8072C17.6516 10.6769 17.8498 10.3541 17.8498 10.071C17.8498 9.78787 17.6516 9.46509 17.3968 9.33485C17.2099 9.23858 17.0627 9.2216 16.4228 9.2216H15.6753L16.672 8.21363C17.2156 7.66435 17.7082 7.13205 17.7592 7.03012C17.9574 6.63373 17.7875 6.14108 17.3855 5.94288C17.142 5.82963 17.0627 5.82397 15.2676 5.82397C13.5235 5.82963 13.3933 5.83529 13.1951 5.94288Z" fill="#314075" />
                        <path d="M1.0085 8.68806C0.822078 8.78533 0.635654 8.97175 0.53839 9.16628C0.376282 9.48239 0.376282 9.62018 0.376282 23.1886C0.376282 36.7651 0.376282 36.8948 0.53839 37.2109C0.724814 37.5757 1.18682 37.8594 1.59209 37.8594C1.99736 37.8594 2.45937 37.5757 2.64579 37.2109C2.79169 36.9191 2.8079 36.7408 2.8079 34.9009V32.9151H21.1261H39.4443V34.9009C39.4443 36.7408 39.4605 36.9191 39.6064 37.2109C39.7928 37.5757 40.2548 37.8594 40.6601 37.8594C41.0654 37.8594 41.5274 37.5757 41.7138 37.2109C41.8678 36.9029 41.8759 36.7408 41.8759 32.4288V27.9708H30.1717C22.5526 27.9708 18.3216 27.9384 18.0379 27.8897C16.9356 27.679 15.9062 26.9009 15.4523 25.9282C15.1524 25.2879 15.047 24.7448 15.047 23.8289V23.1076H8.92747H2.8079V16.299C2.8079 9.62829 2.8079 9.47428 2.64579 9.16628C2.34589 8.57458 1.61641 8.36384 1.0085 8.68806Z" fill="#314075" />
                        <path d="M10.6783 13.4698C8.8384 13.8265 7.50911 15.7231 7.81712 17.563C8.07649 19.1517 9.27609 20.3513 10.8647 20.6107C13.2072 20.9997 15.3713 18.8356 14.9823 16.4931C14.6419 14.4263 12.7047 13.0727 10.6783 13.4698Z" fill="#FC8643" />
                        <path d="M20.4047 15.9017C19.0106 16.1773 17.8272 17.3769 17.5597 18.8035C17.5192 19.0466 17.4786 20.4327 17.4786 21.9078C17.4786 24.4205 17.4867 24.5907 17.6407 24.8906C17.7461 25.1014 17.9163 25.2716 18.1271 25.377C18.4432 25.5391 18.581 25.5391 30.1636 25.5391H41.8759V22.3861C41.8759 20.5867 41.8435 19.0547 41.7948 18.8035C41.5192 17.3445 40.344 16.1692 38.885 15.8936C38.35 15.7964 20.9315 15.7964 20.4047 15.9017Z" fill="#314075" />
                    </svg>
                    <div>{t("super_admin_dashboard.inactive_students")}<br />  ({props.stats.total_inactive_students})</div>
                </div>
            </div>
        </>
    )
}
export default StudentCount