const defaultType = "";

export default (state = defaultType, action) => {
  switch (action.type) {
    case "SET_HOST":
      return "HOST";
    case "SET_PLAYER":
      return "PLAYER";
    case "SET_STUDENT":
      console.log(`ACTION :: ${JSON.stringify(action)}`);
      return {
        type: "STUDENT",
        id: action.studentId,
        name: action.name,
        roomId: action.roomId,
      };
    // return "STUDENT";
    case "RESET_TYPE":
      return defaultType;
    default:
      return state;
  }
};
