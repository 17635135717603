import React from "react";
import { useTranslation } from "react-i18next";

function ChapterListing(props) {

    const { t } = useTranslation()

    const showChapterReport = (id) => {
        if (id === props.chapterId && props.topicId === '') {
            props.onTabSelect(2)
            return
        }
        props.setDashboardData('chapter_report', id)
    }

    return (
        <>
            <div className="chapter_listing_block_list student_listing">
                <table className="table table-borderless performance_section_table" style={{ marginBottom: "0px" }}>
                    <thead style={{ borderBottom: "1px solid lightgray" }}>
                        <tr>
                            <th style={{ maxWidth: "200px", textAlign: "left" }} >{t("dashboard.chapter")}</th>

                            <th>{t("dashboard.report")}</th>
                        </tr>
                    </thead>
                    {
                        props.chapterTopicPerformance && props.chapterTopicPerformance.map((value, index1) => {

                            return (
                                <tbody>
                                    <tr key={"chapter-" + index1} >
                                        <td>{(index1 + 1)}. {value.translated_chapter_name.toLowerCase()}</td>
                                        <td>
                                            <div className={`make_it_clickable ${props.topicId === '' && props.chapterId === value.chapter_id ? "active" : ""}`} onClick={() => { showChapterReport(value.chapter_id) }} style={{ textAlign: "center" }}>
                                                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 1V17.9179H17.5305M4.09947 12.623L9.39439 7.32808L13.2687 10.9441L18.9511 5.39091" stroke="#9C9C9C" strokeWidth="1.79511" strokeLinecap="round" />
                                                </svg>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>

                            )

                        })
                    }
                </table>
            </div>
        </>
    )
} export default ChapterListing