import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { isSafari } from "react-device-detect";
import { secureStorage } from "services/utility";
import learnOrange from "../../../assets/images/svg-elements/learnB.svg";
import practice from "../../../assets/images/svg-elements/practiseB.svg";
import practiceW from "../../../assets/images/svg-elements/practiceWhite.svg";
// import references from "../../../assets/images/svg-elements/references_sidebar_icn.png";
// import referencesB from "../../../assets/images/svg-elements/references_sidebar_icnB.png";
import learn from "../../../assets/images/svg-elements/learn.svg";
import dashboard from "../../../assets/images/svg-elements/dashboardB.svg";
import dashboardW from "../../../assets/images/svg-elements/dashboardw.svg";
import report from "../../../assets/images/svg-elements/report_tdb.png"
import reportw from "../../../assets/images/svg-elements/reportW.png"
import profile from "../../../assets/images/svg-elements/profileB.svg";
import profileW from "../../../assets/images/svg-elements/profileWhite.svg";
import stepappLogo from "../../../assets/images/logo/stepappLogo1.svg";
import store from "../../../store/store";
import SideNavButton from "../../../components/buttons/SideNavButton.jsx";
import classnames from "classnames";
import Emitter from "services/EventEmitter";
import logo from "../../../assets/images/logo/logo-Icon-light.svg";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "./LeftSidebarMenu.scss";
import askDoubtImg from "../../../assets/images/svg-elements/doubt_icon.png"
import askDoubtImgW from "../../../assets/images/svg-elements/doubt_iconW.png"
import resourceIcon from "../../../assets/images/svg-elements/resourceIcon.png"
import resourceIconW from "../../../assets/images/svg-elements/resourceIconW.png"
import stepwiseSolution from "../../../assets/images/svg-elements/stepwiseSolution.png"
import stepwiseSolutionW from "../../../assets/images/svg-elements/stepwiseSolutionW.png"


import {
  Nav,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

export const LeftSidebarJsx = (props) => {
  const state = store.getState();
  const [user, setUser] = useState(state.user);
  const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);
  const toggleMobile = () => setDropdownOpenMobile(!dropdownOpenMobile);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [showLogoutConfirmModal, setShowLogoutConfirmModal] = useState(false);
  const [check, setCheck] = useState(false);
  const { t } = useTranslation();
  console.log("this is leftsidbarMenu", props);
  console.log("user", user)
  const toggleTab = (tab) => {
    props.history.push("/" + tab);
  };

  const handleClose = () => {
    setShowLogoutConfirmModal(false);
  };

  const handelOpen = () => {
    setShowLogoutConfirmModal(true);
  };

  const styles = {
    mob: {
      display: check ? "flex" : "none",
      height: check ? "auto" : "0",
    },
  };

  const mainStyles = {
    mob: {
      height: check ? "" : "0",
    },
  };

  useEffect(() => {
    if (
      window.innerWidth < 768 &&
      (activeTab == "/" ||
        activeTab == "/concepts" ||
        activeTab == "/dashboard" ||
        activeTab == "/profile" ||
        activeTab == "/assessment" ||
        activeTab == "/create-quiz" ||
        activeTab == "/reports" ||
        activeTab == "/calendar" ||
        activeTab == "/home" ||
        activeTab == "/knowmore" ||
        activeTab == "/quiz-report" ||
        // activeTab == "/references" ||
        activeTab == "/bookmarks" ||
        activeTab == "/bookmarkList" ||
        activeTab == "/ask-a-doubt" ||
        // activeTab == "/steps-solution" ||
        // activeTab == "/resources"
        activeTab == "/acadza"

      ) &&
      (!secondActiveTab ||
        secondActiveTab == "/" ||
        secondActiveTab == "/undefined" ||
        secondActiveTab == "/dashboard")
    ) {
      setCheck(true);
    }
  }, [check]);


  let tabs = props.location.pathname.split("/");
  let secondActiveTab = "/" + tabs[2];
  let activeTab = "/" + tabs[1];
  let thirdActiveTab = "/" + tabs[3];
  let fourthActiveTab = "/" + tabs[4];

  const Picture = () => {
    if (user?.first_name) {
      return (
        <div className='btn-group dropup profile-user-dropdown dropdown nav-item nav-circle'>
          {user.first_name?.substr(0, 1)}
          {user.last_name?.substr(0, 1)}
        </div>
      );
    } else {
      return <i className='icon-profile-2'></i>;
    }
  };

  var bc;
  if (!isSafari) {
    bc = new BroadcastChannel("LOGOUT_MESSAGE");
  }

  const changeTheme = () => {
    if (!isDarkTheme) {
      secureStorage.setItem("theme", "dark");
      Emitter.emit("THEME_NAME", "dark");
    } else {
      secureStorage.setItem("theme", "light");
      Emitter.emit("THEME_NAME", "light");
    }
    setIsDarkTheme(!isDarkTheme);
  };
  // console.log("user type in leftSideMenu", user.user_type);
  // console.log("active tab", activeTab);
  // console.log("second active tab", secondActiveTab)
  // console.log("Third active tab", thirdActiveTab)


  const getNav = () => {
    if (user.user_type === "teacher") {
      return (
        <React.Fragment>
          <li className='student-list-item'>
            <div
              className='ev-nav-list-item'
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 10,
                flex: 1,
              }}
            >
              {/* Teacher */}
              {user.user_type === "teacher" && (
                <Fragment>
                  <Link
                    to='/dashboard'
                    style={{ width: "100%" }}

                    className={classnames({
                      active:
                        activeTab === "/principal-dashboard" ||
                        activeTab === "/dashboard",
                    })}
                  >
                    <SideNavButton label={t("left_side_bar_menu.dashboard")} img={dashboard} />
                  </Link>
                  <Link
                    to='/create-quiz'
                    style={{ width: "100%" }}
                    className={classnames({
                      active: activeTab === "/create-quiz" || activeTab === "/quiz-detail" || activeTab === "/homework-detail" || activeTab === "/print-pdf",
                    })}
                  >
                    <SideNavButton label={t("left_side_bar_menu.assessment")} img={practice} />
                  </Link>
                  <Link
                    to='/reports'
                    style={{ width: "100%" }}
                    //className={classnames({active: activeTab === "/reports" || activeTab ==="/report-details"})}
                    className={classnames({ active: activeTab === "/reports" || activeTab === "/quiz-report" })}
                  >
                    <SideNavButton label={t("left_side_bar_menu.reports")} img={report} />
                  </Link>
                  <Link
                    to='/profile'
                    style={{ width: "100%" }}
                    className={classnames({ active: activeTab === "/profile" || activeTab === "/bookmarks" || activeTab === "/bookmarkList" })}
                  >
                    <SideNavButton label={t("left_side_bar_menu.profile")} img={profile} />
                  </Link>
                  <Link
                    to='/concepts'
                    style={{ width: "100%" }}
                    id="sidebarLearnLink"
                    className={classnames({ active: activeTab === `/concepts` || secondActiveTab === '/chapters' || activeTab === "/knowmore" || secondActiveTab === "/skills-details" || thirdActiveTab === "/skill-video-player" })}
                  >
                    <SideNavButton label={t("left_side_bar_menu.teach")} img={learnOrange} />
                  </Link>
                  {/* <Link
                    to='/ask-a-doubt'
                    style={{ width: "100%" }}
                    className={classnames({
                      active: activeTab === "/ask-a-doubt" || activeTab === "/solution-list",
                    })}
                  >
                    
                    <SideNavButton
                      label={t("left_side_bar_menu.doubt-section")}
                      img={askDoubtImg}
                    />
                  </Link> */}

                  {/* <Link
                    to='/steps-solution'
                    style={{ width: "100%" }}
                    className={classnames({
                      active: activeTab === "/steps-solution",
                    })}
                  >
                    <SideNavButton
                      label={t("left_side_bar_menu.steps-solution-teaching")}
                      img={stepwiseSolution}
                    />
                  </Link> */}
                  {/* <Link
                    to='/resources'
                    style={{ width: "100%" }}
                    className={classnames({
                      active: activeTab === "/resources",
                    })}
                  >
                    <SideNavButton
                      label={t("left_side_bar_menu.resources")}
                      img={resourceIcon}
                    />
                  </Link> */}
                </Fragment>
              )}
            </div>
          </li>
        </React.Fragment>
      );
    } else if (user.user_type === "principal") {
      return (
        <React.Fragment>
          <li className='student-list-item'>
            <div
              className='ev-nav-list-item'
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 10,
                flex: 1,
              }}
            >
              {/* Principal */}
              {user.user_type === "principal" && (
                <Fragment>
                  <Link
                    to='/dashboard'
                    style={{ width: "100%" }}

                    className={classnames({
                      active:
                        activeTab === "/principal-dashboard" ||
                        activeTab === "/dashboard",
                    })}
                  >
                    <SideNavButton label={t("left_side_bar_menu.dashboard")} img={dashboard} />
                  </Link>
                  <Link
                    to='/reports'
                    style={{ width: "100%" }}

                    className={classnames({
                      active:
                        activeTab === "/reports"
                    })}
                  >
                    <SideNavButton label={t("left_side_bar_menu.reports")} img={report} />
                  </Link>
                  <Link
                    to='/profile'
                    style={{ width: "100%" }}
                    className={classnames({ active: activeTab === "/profile" || activeTab === "/bookmarks" || activeTab === "/bookmarkList" })}
                  >
                    <SideNavButton label={t("left_side_bar_menu.profile")} img={profile} />
                  </Link>
                  <Link
                    to='/concepts'
                    style={{ width: "100%" }}
                    id="sidebarLearnLink"
                    className={classnames({ active: activeTab === "/concepts" || secondActiveTab === "/chapters" || activeTab === "/knowmore" || secondActiveTab === "/skills-details" || thirdActiveTab === "/skill-video-player" })}
                  >
                    <SideNavButton label={t("left_side_bar_menu.teach")} img={learnOrange} />
                  </Link>
                  {/* <Link
                    to='/ask-a-doubt'
                    style={{ width: "100%" }}
                    className={classnames({
                      active: activeTab === "/ask-a-doubt" || activeTab === "/solution-list",
                    })}
                  >
                    <SideNavButton
                      label={t("left_side_bar_menu.doubt-section")}
                      img={askDoubtImg}
                    />
                  </Link> */}

                  {/* <Link
                    to='/steps-solution'
                    style={{ width: "100%" }}
                    className={classnames({
                      active: activeTab === "/steps-solution",
                    })}
                  >
                    <SideNavButton
                      label={t("left_side_bar_menu.steps-solution-teaching")}
                      img={stepwiseSolution}
                    />
                  </Link> */}
                  {/* <Link
                    to='/resources'
                    style={{ width: "100%" }}
                    className={classnames({
                      active: activeTab === "/resources",
                    })}
                  >
                    <SideNavButton
                      label={t("left_side_bar_menu.resources")}
                      img={resourceIcon}
                    />
                  </Link> */}

                </Fragment>
              )}
            </div>
          </li>
        </React.Fragment>
      );
    } else if (
      user.user_type === "superadmin"
    ) {
      return (

        <React.Fragment>
          <li className='student-list-item'>
            <div
              className='ev-nav-list-item'
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 10,
                flex: 1,
              }}
            >
              {/* superadmin */}
              {user.user_type === "superadmin" && (
                <Fragment>
                  <Link
                    to='/dashboard'
                    style={{ width: "100%" }}

                    className={classnames({
                      active:
                        activeTab === "/principal-dashboard" ||
                        activeTab === "/dashboard",
                    })}
                  >
                    <SideNavButton label={t("left_side_bar_menu.dashboard")} img={dashboard} />
                  </Link>

                  <Link
                    to='/profile'
                    style={{ width: "100%" }}
                    className={classnames({ active: activeTab === "/profile" || activeTab === "/bookmarks" || activeTab === "/bookmarkList" })}
                  >
                    <SideNavButton label={t("left_side_bar_menu.profile")} img={profile} />
                  </Link>
                  <Link
                    to='/concepts'
                    style={{ width: "100%" }}
                    id="sidebarLearnLink"
                    className={classnames({ active: activeTab === `/concepts` || secondActiveTab === '/chapters' || activeTab === "/knowmore"|| secondActiveTab === "/skills-details" || thirdActiveTab === "/skill-video-player" })}
                  >
                    <SideNavButton label={t("left_side_bar_menu.teach")} img={learnOrange} />
                  </Link>

                </Fragment>
              )}
            </div>
          </li>
        </React.Fragment>
      );
    } else if (user.user_type === "student" || user.user_type === "content_approver" ||
      user.user_type === "content_editor") {
      return (
        <React.Fragment>
          <li className='student-list-item'>
            <div
              className='ev-nav-list-item'
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 10,
                flex: 1,
              }}
            >
              <Link
                id='sidebarLearnLink'
                to='/'
                style={{ width: "100%" }}
                className={classnames({
                  active:
                    activeTab === "/concepts" || activeTab === "/knowmore" || secondActiveTab === "/skills-details" || thirdActiveTab === "/skill-video-player",
                })}
              >
                <SideNavButton
                  label={t("left_side_bar_menu.learn")}
                  img={learnOrange}
                />
              </Link>
              <Link
                to='/assessment'
                style={{ width: "100%" }}
                className={classnames({ active: activeTab === "/assessment" })}
              >
                <SideNavButton
                  label={t("left_side_bar_menu.practice")}
                  img={practice}
                />
              </Link>
              <Link
                to='/dashboard'
                style={{ width: "100%" }}
                className={classnames({
                  active: activeTab === "/dashboard",
                })}
              >
                <SideNavButton
                  label={t("left_side_bar_menu.dashboard")}
                  img={dashboard}
                />
              </Link>
              {
                (user.entitlements[0] === "ALL" && (user.grade_name === "11" || user.grade_name === "12")) ? (
                  <Link
                    to='/acadza'
                    style={{ width: "100%" }}
                    className={classnames({
                      active: activeTab === "/acadza",
                    })}
                  >
                    <SideNavButton
                      label={t("left_side_bar_menu.acadza")}
                      img={stepwiseSolution}
                    />
                  </Link>
                ) : ""
              }
              <Link
                to='/profile'
                style={{ width: "100%" }}
                className={classnames({
                  active: activeTab === "/profile" || activeTab === "/bookmarks" || activeTab === "/bookmarkList",
                })}
              >
                <SideNavButton
                  label={t("left_side_bar_menu.profile")}
                  img={profile}
                />
              </Link>

              {/* <Link
                to='/references'
                style={{ width: "100%" }}
                className={classnames({
                  active: activeTab === "/references",
                })}
              >
                <SideNavButton
                  label={t("left_side_bar_menu.references")}
                  img={referencesB}
                />
              </Link> */}

              {/* <Link
                to='/ask-a-doubt'
                style={{ width: "100%" }}
                className={classnames({
                  active: activeTab === "/ask-a-doubt" || activeTab === "/solution-list",
                })}
              >
                <SideNavButton
                  label={t("left_side_bar_menu.ask-a-doubt")}
                  img={askDoubtImg}
                />
              </Link> */}

              {/* <Link
                to='/steps-solution'
                style={{ width: "100%" }}
                className={classnames({
                  active: activeTab === "/steps-solution",
                })}
              >
                <SideNavButton
                  label={t("left_side_bar_menu.steps-solution")}
                  img={stepwiseSolution}
                />
              </Link> */}
              {/* <Link
                to='/resources'
                style={{ width: "100%" }}
                className={classnames({
                  active: activeTab === "/resources",
                })}
              >
                <SideNavButton
                  label={t("left_side_bar_menu.resources")}
                  img={resourceIcon}
                />
              </Link> */}
            </div>
            <img
              src={stepappLogo}
              className='footer-logo'
              style={{
                justifySelf: "flex-end",
                marginBottom: "5rem",
                width: "50%",
                alignSelf: "center",
              }}
              alt=''
            />
          </li>
        </React.Fragment>
      );
    }
  };

  return (
    <div>
      <React.Fragment>

        <div
          style={mainStyles.mob}
          className='side-menu flex-lg-column'
          id='leftSidebar'
        >
          {" "}
          {/* LOGO */}
          <div className='navbar-brand-box'></div>
          {/* end navbar-brand-box  */}
          {/* Start side-menu nav */}
          <div className='flex-lg-column my-auto mid-sidenav-content'>
            <Nav
              pills
              className='side-menu-nav justify-content-center showOnDesktop'
              role='tablist'
            >
              <React.Fragment>
                <NavItem id='logoInMobile'>
                  <div
                    className='d-block d-lg-none text-center'
                    style={{ paddingTop: "3px" }}
                  >
                    <Link
                      to={user.user_type === "student" ? "/concepts" : "/"}
                      className='logo logo-dark'
                    >
                      <span className='logo-sm'>
                        <img src={logo} alt='logo' height='40' width='40' />
                      </span>
                    </Link>
                  </div>
                </NavItem>

                {getNav()}

                {/* rest of the navigation will get through getNav() */}
              </React.Fragment>

              <Dropdown
                nav
                isOpen={dropdownOpenMobile}
                toggle={toggleMobile}
                className='profile-user-dropdown d-inline-block d-lg-none'
              >

                <DropdownMenu>
                  {/* {(user.user_type === 'student' || */}
                  {(user.user_type === "content_approver" ||
                    user.user_type === "content_editor") && (
                      <Fragment>
                        <DropdownItem
                          onClick={() => {
                            toggleTab("profile");
                          }}
                        >
                          {t("left_side_bar_menu.profile")}
                          <i className='icon-profile-2 float-right text-muted'></i>
                        </DropdownItem>

                        <DropdownItem divider />
                        <DropdownItem
                          onClick={() => {
                            changeTheme();
                          }}
                        >
                          Theme{" "}
                          <i className='icon-bright float-right text-muted'></i>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          onClick={() => {
                            toggleTab("bookmarks");
                          }}
                        >
                          Bookmark{" "}
                          <i className='icon-bookmark-1 float-right text-muted'></i>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          onClick={() => {
                            toggleTab("calendar");
                          }}
                        >
                          Calendar{" "}
                          <i className='icon-calendar float-right text-muted'></i>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          onClick={() => {
                            toggleTab("leaderboard");
                          }}
                        >
                          Rank{" "}
                          <i className='icon-leaderboard float-right text-muted'></i>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem divider />
                        {(user.user_type === "student" ||
                          user.user_type === "principal") && (
                            <Fragment>
                              {" "}
                              <DropdownItem
                                onClick={() => {
                                  toggleTab("dashboard");
                                }}
                              >
                                {t("left_side_bar_menu.dashboard")}
                                <i className='icon-dashboard float-right text-muted'></i>
                              </DropdownItem>
                              <DropdownItem divider />
                            </Fragment>
                          )}
                      </Fragment>
                    )}

                  {(user.user_type === "teacher" ||
                    user.user_type === "principal" ||
                    user.user_type === "superadmin") && (
                      <Fragment>
                        <DropdownItem
                          onClick={() => {
                            toggleTab("dashboard");
                          }}
                        >
                          {t("left_side_bar_menu.dashboard")}
                          <i className='icon-dashboard float-right text-muted'></i>
                        </DropdownItem>

                        <DropdownItem divider />
                        <DropdownItem
                          onClick={() => {
                            toggleTab("reports");
                          }}
                        >
                          {t("left_side_bar_menu.reports")}
                          <i className='icon-reports float-right text-muted'></i>
                        </DropdownItem>
                        <DropdownItem divider />

                        <DropdownItem divider />

                        <DropdownItem
                          onClick={() => {
                            toggleTab("profile");
                          }}
                        >
                          {t("left_side_bar_menu.profile")}
                          <i className='ri-profile-line float-right text-muted'></i>
                        </DropdownItem>
                        <DropdownItem divider />
                      </Fragment>
                    )}

                  <DropdownItem
                    onClick={() => {
                      handelOpen();
                    }}
                  >
                    Log out{" "}
                    <i className="icon-logout-1 float-right text-muted"></i>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
            {(user.user_type === "student" || user.user_type === "content_approver" ||
              user.user_type === "content_editor") && (
                <Nav tabs style={styles.mob} className="side-menu-nav showOnMobile" role="tablist">
                  <NavItem>
                    <Link
                      to="/"
                      className={classnames({
                        active: activeTab === "/concepts",
                      })}
                      id="activeLearnInMobile"
                    >
                      <SideNavButton label={t("left_side_bar_menu.learn")} img={learn} tag="Mobile" />
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to="/assessment"
                      className={classnames({
                        active: activeTab === "/assessment",
                      })}
                    >
                      <SideNavButton
                        label={t("left_side_bar_menu.practice")}
                        img={practiceW}
                        tag="Mobile"
                      />
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to="/dashboard"
                      className={classnames({ active: activeTab === "/dashboard" })}
                    >
                      <SideNavButton
                        label={t("left_side_bar_menu.dashboard")}
                        img={dashboardW}
                        tag="Mobile"
                      />
                    </Link>
                  </NavItem>
                  {
                    (user.entitlements[0] === "ALL" && (user.grade_name === "11" || user.grade_name === "12")) ? (
                      <NavItem>
                        <Link
                          to='/acadza'
                          style={{ width: "100%" }}
                          className={classnames({
                            active: activeTab === "/acadza",
                          })}
                        >
                          <SideNavButton
                            label={t("left_side_bar_menu.acadza")}
                            img={stepwiseSolutionW}
                            tag="Mobile"
                          />
                        </Link>
                      </NavItem>
                    ) : ""
                  }
                  <NavItem>
                    <Link
                      to="/profile"
                      className={classnames({ active: activeTab === "/profile" })}
                    >
                      <SideNavButton
                        label={t("left_side_bar_menu.profile")}
                        img={profileW}
                        tag="Mobile"
                      />
                    </Link>
                  </NavItem>

                  {/* <NavItem>
                    <Link
                      to="/references"
                      className={classnames({ active: activeTab === "/references" })}
                    >
                      <SideNavButton
                        label={t("left_side_bar_menu.references")}
                        img={references} tag="Mobile"
                      />
                    </Link>
                  </NavItem> */}

                  {/* <NavItem>
                    <Link
                      to="/ask-a-doubt"
                      className={classnames({ active: activeTab === "/ask-a-doubt" })}
                    >
                      <SideNavButton
                        label={t("left_side_bar_menu.ask-a-doubt")}
                        img={askDoubtImgW} tag="Mobile"
                      />
                    </Link>
                  </NavItem> */}

                  {/* <NavItem>
                    <Link
                      to="/steps-solution"
                      className={classnames({ active: activeTab === "/steps-solution" })}
                    >
                      <SideNavButton
                        label={t("left_side_bar_menu.steps-solution")}
                        img={stepwiseSolutionW}
                        tag="Mobile"
                      />
                    </Link>
                  </NavItem> */}

                  {/* <NavItem>
                    <Link
                      to="/resources"
                      className={classnames({ active: activeTab === "/resources" })}
                    >
                      <SideNavButton
                        label={t("left_side_bar_menu.resources")}
                        img={resourceIconW}
                        tag="Mobile"
                      />
                    </Link>
                  </NavItem> */}
                </Nav>
              )}
            {(user.user_type === "teacher") && (
              <Nav tabs style={styles.mob} className="side-menu-nav showOnMobile" role="tablist">
                <NavItem>
                  <Link
                    to="/dashboard"
                    className={classnames({
                      active: activeTab === "/dashboard",
                    })}
                    id="activeLearnInMobile"
                  >
                    <SideNavButton label={t("left_side_bar_menu.dashboard")} img={dashboardW} tag="Mobile" />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to="/create-quiz"
                    className={classnames({ active: activeTab === "/create-quiz" })}
                  >
                    <SideNavButton
                      label={t("left_side_bar_menu.assessment")}
                      img={practiceW}
                      tag="Mobile"
                    />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to="/reports"
                    className={classnames({ active: activeTab === "/reports" })}
                  >
                    <SideNavButton
                      label={t("left_side_bar_menu.reports")}
                      img={reportw}
                      tag="Mobile"
                    />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to="/profile"
                    className={classnames({ active: activeTab === "/profile" })}
                  >
                    <SideNavButton
                      label={t("left_side_bar_menu.profile")}
                      img={profileW}
                      tag="Mobile"
                    />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to="/concepts"
                    className={classnames({ active: activeTab === "/concepts" })}
                  >
                    <SideNavButton label={t("left_side_bar_menu.teach")} img={learn} tag="Mobile" />
                  </Link>
                </NavItem>
                {/* <NavItem>
                  <Link
                    to="/ask-a-doubt"
                    className={classnames({ active: activeTab === "/ask-a-doubt" })}
                  >
                    <SideNavButton
                      label={t("left_side_bar_menu.doubt-section")}
                      img={askDoubtImgW} tag="Mobile"
                    />
                  </Link>
                </NavItem> */}

                {/* <NavItem>
                  <Link
                    to="/steps-solution"
                    className={classnames({ active: activeTab === "/steps-solution" })}
                  >
                    <SideNavButton
                      label={t("left_side_bar_menu.steps-solution-teaching")}
                      img={stepwiseSolutionW}
                      tag="Mobile"
                    />
                  </Link>
                </NavItem> */}

                {/* <NavItem>
                  <Link
                    to="/resources"
                    className={classnames({ active: activeTab === "/resources" })}
                  >
                    <SideNavButton
                      label={t("left_side_bar_menu.resources")}
                      img={resourceIconW}
                      tag="Mobile"
                    />
                  </Link>
                </NavItem> */}
              </Nav>
            )}

            {(user.user_type === "principal" || user.user_type === "superadmin") && (
              <Nav tabs style={styles.mob} className="side-menu-nav showOnMobile" role="tablist">
                <NavItem>
                  <Link
                    to="/dashboard"
                    className={classnames({
                      active: activeTab === "/dashboard",
                    })}
                    id="activeLearnInMobile"
                  >
                    <SideNavButton label={t("left_side_bar_menu.dashboard")} img={dashboardW} tag="Mobile" />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to="/reports"
                    className={classnames({
                      active: activeTab === "/reports",
                    })}
                    id="activeLearnInMobile"
                  >
                    <SideNavButton label={t("left_side_bar_menu.reports")} img={reportw} tag="Mobile" />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to="/profile"
                    className={classnames({ active: activeTab === "/profile" })}
                  >
                    <SideNavButton
                      label={t("left_side_bar_menu.profile")}
                      img={profileW}
                      tag="Mobile"
                    />
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    to="/concepts"
                    className={classnames({ active: activeTab === "/concepts" })}
                  >
                    <SideNavButton label={t("left_side_bar_menu.teach")} img={learn} tag="Mobile" />
                  </Link>
                </NavItem>
                {/* <NavItem>
                  <Link
                    to="/ask-a-doubt"
                    className={classnames({ active: activeTab === "/ask-a-doubt" })}
                  >
                    <SideNavButton
                      label={t("left_side_bar_menu.doubt-section")}
                      img={askDoubtImgW} tag="Mobile"
                    />
                  </Link>
                </NavItem> */}

                {/* <NavItem>
                  <Link
                    to="/steps-solution"
                    className={classnames({ active: activeTab === "/steps-solution" })}
                  >
                    <SideNavButton
                      label={t("left_side_bar_menu.steps-solution-teaching")}
                      img={stepwiseSolutionW}
                      tag="Mobile"
                    />
                  </Link>
                </NavItem> */}

                {/* <NavItem>
                  <Link
                    to="/resources"
                    className={classnames({ active: activeTab === "/resources" })}
                  >
                    <SideNavButton
                      label={t("left_side_bar_menu.resources")}
                      img={resourceIconW}
                      tag="Mobile"
                    />
                  </Link>
                </NavItem> */}
              </Nav>
            )}

          </div>

          <Modal
            isOpen={showLogoutConfirmModal}
            hidefunction={() => {
              handleClose();
            }}
          >
            <ModalHeader closeButton>{"STEPapp"}</ModalHeader>
            <ModalBody>{"Do you want to Logout?"}</ModalBody>
            <ModalFooter>

              <button
                onClick={() => {
                  handleClose();
                }}
                className='btn btn-primary'
              >
                {" "}
                {"No"}
              </button>
            </ModalFooter>
          </Modal>
        </div>
      </React.Fragment>
    </div>
  );
};

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};

export default React.memo(
  connect(mapStatetoProps, {
    withTranslation,
  })(LeftSidebarJsx)
);
