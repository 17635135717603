import GraphForTopic from "components/common/graph_for_topic"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
// import GraphForTopic from "../common/graph_for_topic"

function TopicrReportingDashboard(props) {
    const [graphData, setGraphData] = useState([])
const {t} = useTranslation()

    useEffect(() => {
        const chapters = props.chapterWisePerformance[0].find(e => e.chapter_id == props.chapterId)

        const topic = chapters.topics.find(e => e.topic_id == props.topicId)
        setGraphData(topic)
    }, [props.topicId])

    return (
        <>
            <div className="teacher_report">
                <div className="teacher_report_heading"><span>{graphData?.topic_name}</span> {t("super_admin_dashboard.performance")}</div>
                <GraphForTopic topic={graphData} selectedBatch={props.selectedBatch}/>
            </div>

        </>
    )
} export default TopicrReportingDashboard