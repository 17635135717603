import React from 'react';
import {useGlobal} from 'reactn';
import Config from "../../../config";

const Copyright = () => {
    const version = useGlobal('version')[0];
    return (
        <div id="copyright" className="uk-position-bottom-center uk-position-small uk-visible@m uk-position-z-index">
            <span className="uk-text-small uk-text-muted">© 2021 {Config.brand} - v{version}</span>
        </div>
    );
};

export default Copyright;
