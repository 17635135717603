import Body from 'elements/Body';
import React from 'react';
import "./skills.scss"
import { PDFViewer } from 'react-view-pdf';

function SkillVideoPlayer(props) {
    const {item} = props.location.state;

    return (
        <Body component={
            <div id="CommonPageHolder">
                <div className="container uk-animation-customslide">
                    <div>
                        <div className='mt-3 mb-3'>
                            <span className='font-weight-bold' style={{ fontSize: '28px' }}>{item['Chapter Name']}</span>
                        </div>
                        {item.Video && <video controls autostart autoPlay src={"https://cdn.stepapp.in"+item.Video} type="video/mp4" />}
                        {item.Pdf && <PDFViewer url={"https://cdn.stepapp.in"+item.Pdf} />}
                    </div>
                </div>
            </div>
        }
        />
    );
}

export default SkillVideoPlayer;
