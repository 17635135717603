import React from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';


function OverallInsighGraph(props) {
    const {t}=useTranslation()

    const { innerWidth: width, innerHeight: height } = window;


    const options = {
        maintainAspectRatio: width > 900,
        type: 'line',
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: '',
            },
        },
        scales: {
            yAxes: [{
                display: true,
                ticks: {
                    suggestedMin: 0,
                    suggestedMax: 100,    // minimum will be 0, unless there is a lower value.
                    callback: function (value, index, values) {
                        return value + "%";
                    }
                }
            }]
        }
    };
    let labels = props.overallInsigetGraph.label;
    let data = {
        labels,
        datasets: [
            {
                label: t("super_admin_dashboard.total_score"),
                data: props.overallInsigetGraph.total_score,
                borderColor: '#FC8643',
                backgroundColor: '#FC8643',
                fill: false,
                lineTension: 0,
                borderWidth: 1.3131,
                pointBackgroundColor: "#FC8643"
            },
            {
                label: t("super_admin_dashboard.progress"),
                data: props.overallInsigetGraph.progress,
                borderColor: '#37CE34',
                backgroundColor: '#37CE34',
                fill: false,
                lineTension: 0,
                borderWidth: 1.3131,
                pointBackgroundColor: "#37CE34"
            },
            {
                label: t("super_admin_dashboard.active_student_percentage"),
                data: props.overallInsigetGraph.active_user,
                borderColor: '#5164A6',
                backgroundColor: '#5164A6',
                fill: false,
                lineTension: 0,
                borderWidth: 1.3131,
                pointBackgroundColor: "#5164A6"
            }
        ]
    }
    return (
        <>
            <div className='line_chart_section'>
                <Line options={options} data={data} />
            </div>


        </>
    )

}
export default OverallInsighGraph