import api from '../api.js'
import config from '../../pwaConfig.json'
import db from '../../services/IndexDBStorage'
import { getSelectedBoardAndGradeFromLocal } from './userProfileService'
import store from '../../store/store'
import Axios from 'axios'

export async function getUserContent(queryString) {
  const { user } = store.getState()
  const isPrimaryGrade = user
    ? ['1', '2', '3', '4', '5'].includes(user.grade_name)
    : false

  let url = `${config.apiEndPoint}/${isPrimaryGrade ? 'feed-primary' : 'feed'
    }/learning?disableConcepts=true&boardId=${queryString}`

  console.log("console at api level "+ url.split(' ').join('') ) 
  return await api.get(url.split(' ').join(''))
}

export async function getUserExtraSubjectContent(queryString) {
  const { user } = store.getState()
  return await require(`../../assets/offlineData/${user.grade_name}_${user.lang_pref}`);
  // return await require(`../../assets/offlineData/11_en`);
}

export function getLRSContent(queryString) {
  let url = `${config.apiEndPoint}` + `/lrs?verb=COMPLETED` + queryString

  return api.get(url.split(' ').join(''))
}

export async function getUserContentIfAvailable(queryString) {
  let { data: userData } = await getUserContent(queryString)
  if (
    userData &&
    userData.content.subjects &&
    userData.content.subjects.length > 0
  ) {
    return userData
  }
  //return null;
}

export async function getSubjectsFromLocal() {
  //let userData = getUserProfileData();
  const subjects = await db.subjectContent
    .where('user')
    .equals('user')
    .toArray()
  if (subjects) {
    return subjects
  }
  return null
}

export async function getFeedRailFromLocal(asset) {
  let feedRailArray = []
  let count = await db.feedRailDataUpdated.count()
  let index = 0
  do {
    let element = await db.feedRailDataUpdated
      .where('index')
      .equals(index)
      .toArray()
    feedRailArray.push(element[0])
    index++
  } while (index < count)
  let feedRail = feedRailArray.filter((rail) => rail.asset_type == asset)
  
  if (feedRail && feedRail.length > 0) {
    return feedRail[0]
  }
  return null
}

export async function getFeedRailFromLocalSequentially() {
  // getting data sequentially through indexedDB
  let feedRailArray = []
  let count = await db.feedRailDataUpdated.count()
  let index = 0
  do {
    let element = await db.feedRailDataUpdated
      .where('index')
      .equals(index)
      .toArray()
    feedRailArray.push(element[0])
    index++
  } while (index < count)
  if (feedRailArray && feedRailArray.length > 0) {
    return feedRailArray
  }
  return null
}

export async function getLRSDataFromLocal() {
  const lrs = await db.lrsSync.where('user').equals('user').toArray()
  if (lrs && lrs.length) {
    return lrs[0].data
  }
  return null
}

export async function getLRSSyncDataByChapter(chapterSlug) {
  const lrs = await db.lrsSync
    .where('learn_object.chapterSlug')
    .equals(chapterSlug)
    // .reverse()
    .sortBy('context.timestamp')

  return lrs
  // }
  return null
}
export async function getLRSSyncData(subjectslug, learnObjectId) {
  if (subjectslug && learnObjectId) {
    var lrs = await db.lrsSync
      .where('[learn_object.subjectSlug+learn_object_id]')
      .equals([subjectslug, learnObjectId])
      // .reverse()
      .sortBy('context.timestamp')
  } else {
    var lrs = await db.lrsSync.reverse().sortBy('context.timestamp')
  }

  
  return lrs
  return null
}
async function getLRSSyncPendingData() {
  let lrs = await getLRSSyncData()
  if (lrs && lrs.length > 0) {
    let arr = lrs.filter((d1) => {
      if ('isSavedToserver' in d1) {
        if (d1.isSavedToserver) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    })

    return arr
  }
  return null
}
export async function syncLRSDataWithServer() {
  let pendingLRSData = await getLRSSyncPendingData()
  console.log("pendingLRSData", pendingLRSData);
  if (pendingLRSData && pendingLRSData.length > 0) {
    console.log("insidePendingLRSData");
    let updatedPendingData = pendingLRSData.map((objectLRS) => {
      objectLRS.isSavedToserver = true
      return objectLRS
    })

    updatedPendingData = updatedPendingData.reverse();
    console.log("updatedPendingData : "+JSON.stringify(updatedPendingData));
    let result = await sendLRSDataTOServer(updatedPendingData);
    //let result = 0;
    console.log("result: " + result);
    if (result) {
      const userData = await getSelectedBoardAndGradeFromLocal()
      const queryStringForLRS =
        `&user_id=` +
        userData.userId +
        `&board_name=` +
        userData.selectedBoard +
        `&grade_name=` +
        encodeURIComponent(userData.selectedGrade)

      console.log("queryStringForLRS: " + queryStringForLRS);
      const lrsSyncData = await getLRSContent(queryStringForLRS)
      console.log("lrsSyncData", lrsSyncData.data.body);
      await saveLRSSyncDataToLocalStorage(lrsSyncData.data.body, true)
    }
  }
  else {

    console.log("No unsynced data left")

    const userData = await getSelectedBoardAndGradeFromLocal()
    const queryStringForLRS =
      `&user_id=` +
      userData.userId +
      `&board_name=` +
      userData.selectedBoard +
      `&grade_name=` +
      encodeURIComponent(userData.selectedGrade)

    console.log("queryStringForLRS: " + queryStringForLRS);


    const lrsSyncData = await getLRSContent(queryStringForLRS)
    console.log("lrsSyncData", lrsSyncData.data.body);
    await saveLRSSyncDataToLocalStorage(lrsSyncData.data.body, true)
  }
}

async function sendLRSDataTOServer(data) {
  const headers = {
    'Content-Type': 'application/json',
  }
  try {
    const result = await api.post(
      `${config.apiEndPoint}/insert-records`,
      data,
      {
        headers: headers,
      },
    )

    console.log("user content service : "+JSON.stringify(result));
    return result.code
  } catch (error) {
    return null
    console.log('Error Response from LRS API', error)
  }
}

export async function getSelectedSubjectFromLocal(slug) {
  //let userData = getUserProfileData();
  const subjects = await db.subjectContent
    .where('user')
    .equals('user')
    .toArray()
  if (subjects && subjects.length > 0) {

    let filteredSubjects = subjects[0].data.filter(
      (subject) => subject.slug === slug,
    )
    if (filteredSubjects && filteredSubjects.length > 0) {
      return filteredSubjects[0]
    }
  }
  return null
}

export async function getSelectedChapterFromLocal(subjectslug, chapterslug) {
  //let userData = getUserProfileData();
  console.log(subjectslug)
  console.log(chapterslug)
  console.log("Raguccccc")
  const subjects = await db.subjectContent
    .where('user')
    .equals('user')
    .toArray()
  console.log(subjects)

  if (subjects && subjects.length > 0) {

    let filteredSubjects = subjects[0].data.filter(
      (subject) => subject.slug === subjectslug,
    )
    console.log("RRRRR=>", filteredSubjects)
    if (filteredSubjects && filteredSubjects.length > 0) {
      let filteredChapter = filteredSubjects[0].chapter.filter(
        (chapter) => chapter.slug === chapterslug,
      )
      console.log("RRRRR=> CCCCCC", filteredChapter)
      if (filteredChapter && filteredChapter.length > 0) {
        console.log("RRRRR=> CCCCCC1111", filteredChapter)
        return filteredChapter[0]
      }
    }
  }
  return null
}
export async function getNextTopicFromLocal(
  subjectslug,
  chapterslug,
  currentTopic,
) {
  let chapter = await getSelectedChapterFromLocal(subjectslug, chapterslug)
  if (chapter) {
    let index = chapter.topic.findIndex((x) => x.slug === currentTopic)
    if (index >= 0) {
      if (index === chapter.topic.length - 1) {
        return { islast: true, nextrTopic: {} }
      } else {
        return { islast: false, nextrTopic: chapter.topic[index + 1] }
      }
    }
  }
  return null
}
export async function getSelectedTopicFromLocal(
  subjectslug,
  chapterslug,
  topicslug,
) {
  const subjects = await db.subjectContent
    .where('user')
    .equals('user')
    .toArray()
  if (subjects && subjects.length > 0) {

    let filteredSubjects = subjects[0].data.filter(
      (subject) => subject.slug === subjectslug,
    )

    if (filteredSubjects && filteredSubjects.length > 0) {
      let filteredChapter = filteredSubjects[0].chapter.filter(
        (chapter) => chapter.slug === chapterslug,
      )
      if (filteredChapter && filteredChapter.length > 0) {
        let filteredTopic = filteredChapter[0].topic.filter(
          (topic) => topic.slug === topicslug,
        )
        if (filteredTopic && filteredTopic.length > 0) {
          return filteredTopic[0]
        }
      }
    }
  }
  return null
}


export async function saveSubjectDataToLocalStorage(data) {
  if (data) {
    if (db.subjectContent) db.subjectContent.clear()
    db.subjectContent.add({ user: 'user', data }).then(() => { })
  }
}

export async function saveFeedRailDataToLocalStorage(data) {
  if (data) {
    data.map(async (obj) => {
      await db.feedRailDataUpdated.put({ ...obj }).then(() => { })
    })
  }
}
export async function saveIslandDataToLocalStorage(data) {
  if (data) {
    await db.chapterData.put({ ...data }).then(() => { })
  }
}
export async function getIslandDataFromLocal(slugParma, lang) {
 
  return null;
}

// export async function saveLRSSyncDataToLocalStorage(data, isClear = false) {
//   if (data) {
//     if (isClear) await db.lrsSync.clear()
//      console.log("Data to saveLRS ", data);
//      await Promise.all(data.map(async (obj) => {
//       await db.lrsSync.put({ ...obj }).then(() => { console.log("its saved in local db")})
//     }))
//   }
// }

export async function saveLRSSyncDataToLocalStorage(data, isClear = false) {
  if (data) {
    if (isClear) await db.lrsSync.clear();

    console.log("Data to saveLRS ", data);

   
    await db.lrsSync.bulkAdd(data)
      .then(() => {
        console.log("Data saved in local db");
      })
      .catch(error => {
        console.error("Error saving data to local db", error);
      });
  }
}

export async function getSubjectDataToDisplay(queryString) {
  
  let subjectontentData = await getUserContentIfAvailable(queryString)

  console.log("subject content data "+ JSON.stringify(subjectontentData));

  if (
    subjectontentData.content.subjects &&
    subjectontentData.content.subjects.length != 0
  ) {
    saveSubjectDataToLocalStorage(subjectontentData.content.subjects)
    subjectontentData.feed.items.forEach((element, index) => {
      let indexObject = {
        index: index,
      }
      let newElement = Object.assign(element, indexObject)
      return newElement
    })
    saveFeedRailDataToLocalStorage(subjectontentData.feed.items)
    return subjectontentData.content.subjects
  } else {
    alert('Sorry, No data available')
  }
}

export async function storeRating(topicslug, rating) {
  db.rating.put({ topicslug: topicslug, rating: rating }).then(() => { })
}
export async function getRating(topicslug) {
  let objRating = await db.rating.where('topicslug').equals(topicslug).first()
  return objRating
}
export async function getBase64AndHitS3Api(
  evt,
  questionId,
  userId,
  assessmentId = 123,
) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(evt)
    reader.onload = async function (upload) {
      const b64 = upload.target.result.replace(/^data:image.+;base64,/, '')
      const responseApi = await uploadS3Image(
        evt.name,
        questionId,
        b64,
        userId,
        assessmentId,
      )
      if (responseApi != 'undefined') {
        const Data = {
          data: { link: responseApi.data.location },
          status: 200,
          success: true,
        }
        resolve(Data)
      } else {
        reject(responseApi)
      }
    }
  }).catch((error) => {
    console.log(error)
  })
}
export async function uploadS3Image(
  imageName,
  questionId,
  encodedImage,
  userId,
  assessmentId = 123,
) {
  return new Promise((resolve, reject) => {
    Axios.post(config.apiEndPoint + '/image-upload', {
      imageName: imageName,
      assessmentId: assessmentId,
      userId: userId,
      questionId: questionId,
      encodedImage: encodedImage,
    })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
        console.log(error)
      })
  }).catch((error) => {
    console.log(error)
  })
}


export async function getSortedTopicsByChapter(chapterSlug) {
  const lrs = await db.lrsSync
    .where('learn_object.chapterSlug')
    .equals(chapterSlug)
    .reverse()
    .limit(1)
    .sortBy('context.timestamp')

  return lrs
  return null
}
