
import React from "react"
function Loader(props) {
    return (
        <>
            <div className={`loader ${props.showLoader ? "" : "hide"}`}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        </>
    )
} export default Loader