import Dexie from 'dexie'
import { applyEncryptionMiddleware } from 'dexie-encrypted'
import nacl from 'tweetnacl'
const keyPair = nacl.sign.keyPair.fromSeed(new Uint8Array(32))

const db = new Dexie('UnifiedStepAppDb')

db.version(1).stores({
  //schoolList: "school_name",
  gradeData: 'grade',
  selectedSchool: 'id',
  lrsData: '++id',
  preSelectedSchool: 'id',
  //userData: "id",
  subjectContent: 'user',
  lrsSync:
    'context.timestamp,learn_object_type,[learn_object.subjectSlug+learn_object_id],learn_object.chapterSlug',
  //feedRailData: "asset_type",
  bookmarks: 'url,bookmarkName,subjectName,concept_id',
  //conceptBookmark: "concept_id,bookmarkName,subjectName,url",
  rating: 'topicslug,rating',
  feedRailDataUpdated: 'index',
  questionBookmark: 'questionId,subject',
})
db.version(3).stores({
  //schoolList: "school_name",
  gradeData: 'grade',
  selectedSchool: 'id',
  lrsData: '++id',
  preSelectedSchool: 'id',
  //userData: "id",
  subjectContent: 'user',
  lrsSync:
    'context.timestamp,learn_object_type,[learn_object.subjectSlug+learn_object_id],learn_object.chapterSlug',
  //feedRailData: "asset_type",
  bookmarks: 'url,bookmarkName,subjectName,concept_id',
  //conceptBookmark: "concept_id,bookmarkName,subjectName,url",
  rating: 'topicslug,rating',
  feedRailDataUpdated: 'index',
  questionBookmark: 'questionId,subject',
  chapterData: 'slug,lang_pref',
  addNotes: 'conceptId,content',
})
applyEncryptionMiddleware(db, keyPair.publicKey, {
  //schoolList: encrypt.DATA,
  gradeData: applyEncryptionMiddleware.DATA,
  selectedSchool: applyEncryptionMiddleware.DATA,
  lrsData: applyEncryptionMiddleware.DATA,
  preSelectedSchool: applyEncryptionMiddleware.DATA,
  //userData: encrypt.DATA,
  subjectContent: applyEncryptionMiddleware.DATA,
  //feedRailData: encrypt.NON_INDEXED_FIELDS,
  feedRailDataUpdated: applyEncryptionMiddleware.NON_INDEXED_FIELDS,
  chapterData: applyEncryptionMiddleware.NON_INDEXED_FIELDS,
  //bookmarks: encrypt.NON_INDEXED_FIELDS,
})

export default db
