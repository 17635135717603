import React, { useState } from "react";
import { calculateDiamonds, checkingChapterEntry } from "../../../services/utility";
import { NavLink } from "reactstrap";
import store from "../../../store/store";
import { useHistory } from "react-router-dom";
import notification_bell from "../../../img/notification_bell.png";
import goldIcon from "../../../assets/images/evgoldCoin.svg";
import diamondIcon from "../../../assets/images/islandDiamond.svg";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { remove as removeUser } from "../../../reducers/user/actions";
import { clearUiStates } from "../../../reducers/ui-states/actions";
import { secureStorage } from "../../../services/utility";
import notification from "./../../../assets/unifiled_svg/notification.svg";
import setting from "./../../../assets/unifiled_svg/setting.svg";
import search from "./../../../assets/unifiled_svg/search.svg";
import leftarrow from "./../../../assets/unifiled_svg/leftArrow.svg";
import { isSafari } from "react-device-detect";
import { useToasts } from "react-toast-notifications";
import { useGlobal } from "reactn";
import "./mainHeader.scss";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { getSubjectsFromLocal } from "../../../services/dataServices/userContentService"
import Lottie from 'react-lottie'
import SelectSearch from 'react-select-search';
import animationData from '../../../assets/lottie/Log-Out'
// import 'react-select-search/style.css'

export const MainHeaderJsx = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  const { t } = useTranslation();
  const state = store.getState();
  let userData = state.user

  const [user, setUser] = useState(state.user);
  const [headerBreadcrums, setHeaderBreadcrums] = useState([]);
  const history = useHistory();
  const [showLogoutConfirmModal, setShowLogoutConfirmModal] = useState(false);
  const setToken = useGlobal("token")[1];
  const { addToast } = useToasts();
  const [searchResult, setSearchResult] = useState([])

  var bc;
  if (!isSafari) {
    bc = new BroadcastChannel("LOGOUT_MESSAGE");
  }

  const onSearchResultClick = (value) => {
    if (checkingChapterEntry(value.payWall)) {
      props.history.push(`/${value.subject_slug}/${value.slug}/topics`, { isFromConcept: true })
    }
    else {
      props.history.push("/plans")

    }
  }

  const onLogoutClick = async () => {
    const username = user.username;
    const userType = user.user_type.trim();
    console.log("UserTypemahesh 1 : ", userType);
    console.log("UserTypemahesh 2 : ", props);
    await localStorage.removeItem("token");
    await localStorage.removeItem("user");
    await store.dispatch(removeUser());
    await store.dispatch(clearUiStates());
    await secureStorage.removeItem("reduxstate");
    await secureStorage.removeItem("token");
    await secureStorage.removeItem("user");
    localStorage.clear();

    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name);
    });
    await setToken(null);
    await setUser({});
    addToast(`User ${username} logged out!`, {
      appearance: "success",
      autoDismiss: true,
    });
    if (!isSafari) {
      bc.postMessage("LOGOUT CALL FROM OTHER TAB");
    }
    if (
      userType === "student" ||
      userType === "content_approver" ||
      userType === "content_editor"
    ) {
      if (props.history) props.history.push("/login");
      else props.history.push("/login");
    } else if (userType === "teacher") {
      if (props.history) props.history.push("/teacher/login");
      else props.history.push("/teacher/login");
    } else if (userType === "principal") {
      if (props.history) props.history.push("/teacher/login");
      else props.history.push("/teacher/login");
    } else if (userType === "superadmin") {
      if (props.history) props.history.push("/teacher/login");
      else props.history.push("/teacher/login");
    } else {
      if (props.history) props.history.push("/login");
      else props.history.push("/login");
    }
    console.log(localStorage);
  };



  const toggleHeaderTab = (link) => {
    console.log(link);
    if (link != "#") {
      history.push(link);
    }
  };

  const handleSearch = async () => {
    let searchData = [];
    let data_1 = await getSubjectsFromLocal();
    if (data_1 && data_1[0])
      searchData = data_1[0].data;

    let result = [];
    searchData.map((subs) => {
      let arr = subs.chapter.filter(f => f.chapter_name.toLowerCase());
      result = [...result, ...arr];
    });

    const transformedResult = result.map(chapter => ({
      name: chapter.chapter_name,
      value: chapter
    }));

    setSearchResult(transformedResult);
  };

  useEffect(() => {
    handleSearch()
  }, [])

  useEffect(() => {
    let path = props.history.location.pathname;
    let headerBreadcrums = [
      { link: '#', title: t("profile_page.class") + ' ' + user.grade_name },
    ];

    if (path.includes("chapters")) {
      let sub = path.split("/")[1].split("-")[0];
      headerBreadcrums = [
        { link: "/concepts", title: t("profile_page.class") + ' ' + user.grade_name },
        { link: "#", title: t(`subjects.${sub}`) },
      ];
    }

    if (path.includes("skills-details")) {
      headerBreadcrums = [
        { link: "/concepts", title: t("profile_page.class") + ' ' + user.grade_name },
        { link: "#", title: t("subjects.skills-details") },
      ];
    }

    if (path.includes("skill-video-player")) {
      headerBreadcrums = [
        { link: "/concepts", title: t("profile_page.class") + ' ' + user.grade_name },
        { link: `/${path.split("/")[1]}/skills-details`, title: t("subjects.skills-details") },
        { link: "#", title: t("subjects.skill-video-player") },
      ];
    }



    setHeaderBreadcrums(headerBreadcrums);
  }, []);

  const handleClose = () => {
    setShowLogoutConfirmModal(false);
  };

  const handelOpen = () => {
    setShowLogoutConfirmModal(true);
  };


  return (
    <div className='page-layout-wrapper' style={{ position: "relative" }}>
      <div className='header-frame'>
        <React.Fragment>
          <div className='header-container'>
            <section className='header-content-section'>
              <div className='left-arrow-mob'>
                <img src={leftarrow} alt='left arrow' />
              </div>

              <SelectSearch
                search
                options={searchResult}
                onChange={onSearchResultClick}
                name="chapter"
                placeholder="Search chapter"
              />

              {/* <div className='input-group md-form form-sm form-1 pl-0 search-input' style={{ position: "relative" }}> */}
              {/* <div className='input-group-prepend'>
                  <span
                    className='input-group-text purple lighten-3'
                    id='basic-text1'
                  >
                    <i
                      className='fa fa-search text-white'
                      aria-hidden='true'
                    ></i>
                  </span>
                </div>
                <input
                  onChange={(e) => {
                    setSearchText(e.target.value)
                  }}
                  className='form-control my-0 py-1'
                  type='text'
                  placeholder='search topics'
                  aria-label='Search'
                /> */}
              {/* <select
                  id='search'
                  onChange={onSearchResultClick}
                  className='custom-select'
                  style={{ backgroundColor: "transparent", border: "0px", color: "black" }}
                  name='search'
                >
                  <option value="" disabled selected>
                    Select the chapter
                  </option>
                  {searchResult.map((search, i) => {
                    return (
                      <option key={i} value={JSON.stringify(search)}>
                        {search.chapter_name}
                      </option>
                    );
                  })}
                </select> */}
              {/* </div> */}
              {user.user_type &&
                <div className='btn-column' id='page-header-breadcrums'>
                  {headerBreadcrums ? (
                    headerBreadcrums?.length ? (
                      headerBreadcrums.map((header, counter) => (
                        <NavLink
                          className='btn btn-blue-rnd px-2'
                          key={counter}
                          onClick={() => { toggleHeaderTab(header.link) }}
                        >
                          <p className='nav-Text cst-text-elipse' key={counter}>
                            {header.title}
                          </p>
                        </NavLink>
                      ))
                    ) : (
                      <div></div>
                    )
                  ) : (
                    <div></div>
                  )}
                </div>}
              {user.user_type && <div className='middle-actions-col d-flex'>


                <Tooltip title={t("tooltip_titles.knowledge_gems")}>
                  <div className='main-column'>
                    <div className='btn main-box'>
                      <img src={diamondIcon} alt='diamond' />
                      {calculateDiamonds()}
                    </div>
                  </div>
                </Tooltip>
                {/* <Tooltip title={t("tooltip_titles.activiy_coins")}>
                  <div className='main-column'>
                    <div className='btn main-box'>
                      <img src={goldIcon} alt='au' />
                      {2500}
                    </div>
                  </div>
                </Tooltip> */}
              </div>}
              <div className='right-actions-col'>
                <div className='notification-col mob-search'>
                  <span>
                    <img src={search} alt='search icon' />
                  </span>
                </div>
                <div className='notification-col'>
                  <span className='notification-icon'>
                    <img src={notification} alt='Notification icon' />
                  </span>
                  <div className='notification-box-container'>
                    <img src={notification_bell} alt='' />
                    <span>{t("header.notifications")}</span>
                  </div>
                </div>

                <div className='setting-col'>
                  <span className='setting-icon'>
                    <img src={setting} alt='setting icon' />
                  </span>
                  <div className='setting-option-block'>
                    <ul>
                      {/* <li>
                        <NavLink
                          onClick={() => {
                            toggleHeaderTab("/profile");
                          }}
                        >
                          <i className='fa fa-sliders custom-icon'></i>
                          <span className='txt'>{t("header.account")}</span>
                        </NavLink>
                      </li> */}
                      <li>
                        <NavLink
                          onClick={() => {
                            toggleHeaderTab("/profile");
                          }}
                        >
                          <i className='fa fa-cogs custom-icon'></i>
                          <span className='txt'>{t("header.profile")}</span>
                        </NavLink>
                      </li>
                      <li className='btn-frame'>
                        <button
                          aria-hidden='true'
                          onClick={() => {
                            handelOpen();
                          }}
                          className='btn btn-logout'
                        >
                          {t("header.logout")}
                        </button>
                      </li>
                    </ul>

                    {/* Logout Pop Up Modal */}
                    <Modal
                      className='modal-dialog modal-md modal-dialog-centered'
                      isOpen={showLogoutConfirmModal}
                    >
                      <ModalBody className='modalLogout'>
                        <div className='modalImg'>
                          <Lottie
                            options={defaultOptions}
                            height={200}
                            width={200}
                          />
                        </div>
                        <h3>
                          {t("header.logout")}{" "}
                          <span>{t("header.logout_msg")}</span>
                        </h3>
                      </ModalBody>
                      <ModalFooter className='logoutFooter'>
                        <button
                          onClick={() => {
                            handleClose();
                          }}
                          className='btn btn-primary'
                        >
                          {" "}
                          {t("common_modal_popup.logout.no")}
                        </button>
                        <button
                          variant='primary'
                          onClick={() => {
                            onLogoutClick();
                          }}
                          className='btn btn-outline'
                        >
                          {" "}
                          {t("common_modal_popup.logout.yes")}
                        </button>
                      </ModalFooter>
                    </Modal>
                  </div>
                  {/* setting-option-block// */}
                </div>
                {/* Setting Col// */}
              </div>
              {/* right-actions-col// */}
            </section>
          </div>
        </React.Fragment>
      </div>
      {/* {searchText !== "" && searchText !== " " &&
        <ul style={{ position: "absolute", backgroundColor: "#15388c", zIndex: 9999, maxHeight: "50vh", overflowY: "scroll", borderBottomLeftRadius: "10px", borderBottomRightRadius: "20px" }}>
          {searchResult.map((search) => {
            return (
              <li
                onClick={() => onSearchResultClick(search)}
                style={{
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  color: "#fff",
                  borderTop: "1px solid white",
                }}
              >
                {search.chapter_name}
              </li>
            )
          })}
        </ul>
      } */}
    </div>
  );
};

export default MainHeaderJsx;
