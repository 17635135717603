import React from 'react';

export default function SideNavButton({ label, img, active, tag }) {
  if(tag === 'Mobile'){
    return (
      <button style={{
          width: '100%', border: 'none', outline: 'none', cursor: 'pointer', borderRadius: '9px 9px 0 0',
          backgroundColor: (active) ? '#15388C' : '#fff',
          color: (active) ? '#fff' : '#767676'
        }}>
      <div className='innerMenu'>
          <img src={img} alt="" className="h-6 w-6" style={{width:'15px'}} />
          <div style={{ fontWeight: '500', fontSize:'11px'}}>
            {label}
          </div>
      </div>
      </button>
  )
  }
  else
    return (
        <button className='p-1 md:p-2 2xl:p-3' style={{
            width: '100%', border: 'none', outline: 'none', cursor: 'pointer',
            backgroundColor: (active) ? '#FC8643' : '#fff',
            color: (active) ? '#fff' : '#fc8643'
          }}>
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: "center" }}>
            <img src={img} alt="" className="h-6 w-6" style={{ width: '21px'}} />
            <div className='2xl:text-xl pl-3 xl:pl-4' style={{ fontWeight: '500'}}>
              {label}
            </div>
        </div>
        </button>
    )
}