import React, { useContext, useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  Tabs,
  Tab,
  Dropdown,
  Accordion,
  Card,
  AccordionContext,
  useAccordionButton,
} from "react-bootstrap"
import Loader from "components/common/loader"
import StudentListing from "components/common/student_listing"
import DefaultDashboardComponent from "pages/PrincipalDashboard/newDashboard/component/principle/default_dashboard_component"
import ReportingDashboardComponent from "pages/PrincipalDashboard/newDashboard/component/principle/reporting_dashboard_component"
import ProfileDashboardComponent from "pages/PrincipalDashboard/newDashboard/component/principle/profile_dashboard_component"
import ChapterReportingDashboard from "pages/PrincipalDashboard/newDashboard/component/principle/chapter_reporting_dashboard"
import TopicrReportingDashboard from "pages/PrincipalDashboard/newDashboard/component/principle/topic_reporting_dashboard"
import ChapterAnalysis from "pages/PrincipalDashboard/newDashboard/component/principle/chapter_analysis"
import ChapterListing2 from "components/common/ChapterListing_2"
import "./indexnew.scss"
import store from "store/store"
import { useTranslation } from "react-i18next"
import CustomCount from "components/common/CustomCount"

const mapStateToProps = (state) => {
  const user = state.user
  return user
}

const mapDispatchToProps = (dispatch) => {
  return {}
}
function TeacherDashboardNew(props) {
  const [studentCount, setStudentCount] = useState([])
  const [gradeList, setGradeList] = useState([])
  const [batches, setBatches] = useState([])
  const [selectedBatch, setSelectedBatch] = useState("")

  const [className, setClassName] = useState(props.className)
  const [subjectName, setSubjectName] = useState(props.subjectName)

  const [selectedGradeId, setSelectedGradeId] = useState("")
  const [selectedSubjectId, setSelectedSubjectId] = useState("")
  const [TeacherAnalysisTabs, setTeacherAnalysisTabs] = useState("1")

  const [showLoader, setShowLoader] = useState(false)
  const [studentList, setStudentList] = useState([])
  const [chapterTopicPerformance, setChapterTopicPerformance] = useState([])
  const [userPerformanceGraph, setUserPerformance_graph] = useState([])
  const [userOverview, setUserOverview] = useState([])
  const [userId, setUserId] = useState("")
  const [userDetails, setUserDetails] = useState([])
  const [performanceGraph, setPerformance_graph] = useState([])
  const [chapterId, setChapterId] = useState("")
  const [topicId, setTopicId] = useState("")
  const [chapterWisePerformance, setChapterWisePerformance] = useState([])
  const [dropdownLoading, setDropdownLoading] = useState(true)
  const [arrayForSubjectSelection, setArrayForSubjectSelection] = useState([])
  const [selectedTopicChapter, setselectedTopicChapter] = useState([])
  const [selectedTopicList, setSelectedTopicList] = useState([])
  const [dashboardType, setDashboardType] = useState("")
  const [insightData, setInsightData] = useState([])
  const [isSubjectsAvailable, setIsSubjectsAvailable] = useState(true)
  const [selectedSubjectStudCount, setSelectedSubjectStudCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [studentListLoading, setStudentListLoading] = useState(false)
  const [teacherBatchesList, setTeacherBatchesList] = useState()
  const [gradeSubjectFilter, setGradeSubjectFilter] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    startApp()
  }, [])

  useEffect(() => {
    loadSelectedGrade()
    loadChapterTopicListing()
  }, [selectedSubjectId, selectedGradeId, subjectName, selectedBatch])

  useEffect(() => {
    if (pageNumber !== 1) {
      getMoreStudents()
    }
  }, [pageNumber])

  const { user } = store.getState()

  const getMoreStudents = async () => {
    if (studentListLoading) {
      return
    }
    if (studentList.length < 9) {
      return
    }
    if (studentList.length % 10 !== 0) {
      return
    }


    setStudentListLoading(true)
    const res = await fetch(
      `https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/grade-subjectwise-analysis?language=${user.lang_pref}&school_id=${props.school_id}&board_id=${props.board_name}&grade_id=${encodeURIComponent(selectedGradeId)}&subject_id=${selectedSubjectId}&student_count=${selectedSubjectStudCount}&page=${pageNumber}&batch=${encodeURIComponent(selectedBatch)}`
    )

    const data = await res.json()
    if (data.statusCode === 200) {
      if (data.student_list.length > 0) {
        setStudentList((studentList) => [...studentList, ...data.student_list])
        setStudentListLoading(false)
      }
    }
  }
  const loadChapterTopicListing = async () => {

    const res = await fetch(
      `https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/teacher-chapter-topic-analysis?language=${user.lang_pref}&school_id=${props.school_id}&board_id=${props.board_name}&grade_id=${encodeURIComponent(selectedGradeId)}&subject_id=${selectedSubjectId}&batch=${selectedBatch}`
    )
    const data = await res.json()
    if (data.statusCode === 200) {
      setChapterWisePerformance([data.chapter_topic_details])
      setDropdownLoading(false)
    }
  }

  const startApp = async () => {
    setShowLoader(true)
    const res = await fetch(
      `https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/filterwise-analysis?school_id=${props.school_id}&board_id=${props.board_name}&user_id=${props.id}`
    )
    const data = await res.json()
    if (data.statusCode === 200) {
      if (data.teachers_batches.length > 0) {
        setTeacherBatchesList(data.teachers_batches)
        setGradeSubjectFilter(data.grade_subject_filter)
        let teacher_class = data.grade_subject_filter.find(
          (e) => e.grade_name === data.teachers_batches[0].grade
        )

        let subject = teacher_class.subjects.find(
          (e) => e.subject === data.teachers_batches[0].subject_name
        )
        setSubjectName(data.teachers_batches[0].subject_name)
        setClassName(data.teachers_batches[0].grade)

        setBatches(data.batches)
        setSelectedBatch(data.teachers_batches[0].batch)
        setGradeList([teacher_class])
        setSelectedSubjectId(subject.subjectId)
        setSelectedSubjectStudCount(
          subject.StudentCount ? subject.StudentCount : 20
        )

        setSelectedGradeId(teacher_class.grade_id)
      } else {
        let teacher_class = data.grade_subject_filter.find(
          (e) => e.grade_name === props.grade_name
        )

        if (teacher_class?.subjects) {
          setSubjectName(teacher_class.subjects[0].subject)
          setClassName(teacher_class.grade_name)

          setBatches(data.batches)
          setSelectedBatch(data.batches[0].batch_name)
          setGradeList([teacher_class])
          setSelectedSubjectId(teacher_class.subjects[0].subjectId)
          setSelectedSubjectStudCount(
            teacher_class.subjects[0].StudentCount
              ? teacher_class.subjects[0].StudentCount
              : 20
          )

          setSelectedGradeId(teacher_class.grade_id)
        } else {
          setShowLoader(false)
          setIsSubjectsAvailable(false)
        }
      }
    }
  }

  const loadSelectedGrade = async () => {
    setStudentList([])

    const res = await fetch(
      `https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/grade-subjectwise-analysis?language=${user.lang_pref}&school_id=${props.school_id}&board_id=${props.board_name}&grade_id=${encodeURIComponent(selectedGradeId)}&subject_id=${selectedSubjectId}&student_count=${selectedSubjectStudCount}&page=${pageNumber}&batch=${encodeURIComponent(selectedBatch)}`
    )

    const data = await res.json()
    if (data.statusCode === 200) {
      setPerformance_graph(data.performance_graph)
      setStudentList(data.student_list)
      setChapterTopicPerformance(data.chapter_topic_performance)
      setStudentCount(data)
      setShowLoader(false)
    }
  }
  const setDashboardData = (type, user_id) => {
    setShowLoader(true)
    setDashboardType(type)
    switch (type) {
      case "":
        setDefaultDashboard()
        setUserId(user_id)
        setChapterId(user_id)
        setShowLoader(false)
        break
      case "report":
        setReportDashboard(user_id)
        setUserId(user_id)
        break
      case "profile":
        setProfileDashboard()
        setReportDashboard(user_id)
        setUserId(user_id)
        break
      case "chapter_report":
        setChapterReportingDashboard(user_id)
        setChapterId(user_id)
        setTopicId("")
        break

    }
  }
  const setDefaultDashboard = () => { }
  const setProfileDashboard = () => { }
  const setChapterReportingDashboard = async (chapter_id) => {
    setShowLoader(false)
    setInsightData([])

    const { user } = store.getState()
    const res = await fetch(
      `https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/insights?language=${user.lang_pref}&school_id=${props.school_id}&board_id=${props.board_name}&grade_id=${encodeURIComponent(selectedGradeId)}&subject_id=${selectedSubjectId}&chapter_id=${chapter_id}&batch=${selectedBatch}`
    )
    const data = await res.json()
    if (data.statusCode === 200) {
      setInsightData(data.insights)
    }
  }
  const setDashboardDataForTopic = (type, chapter_id, topic_id) => {
    setChapterId(chapter_id)
    setTopicId(topic_id)
    setDashboardType(type)
  }

  const setReportDashboard = async (user_id) => {
    const { user } = store.getState()
    const res = await fetch(
      "https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/users-report-analysis?school_id=" +
      props.school_id +
      "&language=" +
      user.lang_pref +
      "&board_id=" +
      props.board_name +
      "&grade_id=" +
      encodeURIComponent(selectedGradeId) +
      "&subject_id=" +
      selectedSubjectId +
      "&user_id=" +
      user_id
    )
    const data = await res.json()
    if (data.statusCode === 200) {
      setUserPerformance_graph(data.performance_graph)
      setUserDetails(data.userDetails)
      setUserOverview(data.overview)
      setShowLoader(false)
    }
  }

  const resetData = () => {
    setSelectedTopicList([])
    setArrayForSubjectSelection([])
    setselectedTopicChapter([])

  }

  const handleSubChange = (e, value) => {
    let original_value = [...arrayForSubjectSelection]
    let selected_chapter_listing = [...selectedTopicChapter]

    if (e.target.checked) {
      original_value = []
      selected_chapter_listing = []
      let chapter_info = value.chapter_performance_graph
      chapter_info.chapter_id = value.chapter_id
      chapter_info.translated_chapter_name = value.translated_chapter_name
      chapter_info.type = "chapter"
      original_value.push(chapter_info)

      selected_chapter_listing.push({ chapter_id: value.chapter_id })
      original_value = original_value.filter((value, index) => {
        return original_value[index].type !== "topic"
      })
      setSelectedTopicList([])
    } else {
      if (original_value.filter((x) => x.type === "chapter").length === 1) {
        original_value = []
        selected_chapter_listing = []
        setSelectedTopicList([])
      } else {
        original_value.splice(
          original_value.findIndex(
            ({ chapter_id }) => chapter_id == value.chapter_id
          ),
          1
        )
        selected_chapter_listing.splice(
          selected_chapter_listing.findIndex(
            ({ chapter_id }) => chapter_id == value.chapter_id
          ),
          1
        )
      }
    }
    setArrayForSubjectSelection(original_value)
    setselectedTopicChapter(selected_chapter_listing)
  }

  useEffect(() => {
    console.log(topicId)
  }, [topicId])

  const onTabSelect = async (k) => {

    const { user } = store.getState()
    if (parseInt(k) === 2) {
      setInsightData([])
      setDashboardType("chapter_analysis")
      const res = await fetch(
        `https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/insights?language=${user.lang_pref}&school_id=${props.school_id}&board_id=${props.board_name}&grade_id=${encodeURIComponent(selectedGradeId)}&subject_id=${selectedSubjectId}&batch=${selectedBatch}`
      )
      const data = await res.json()
      if (data.statusCode === 200) {
        setInsightData(data.insights)
      }
    } else {
      setDashboardType("")
      setInsightData([])
    }
    setTeacherAnalysisTabs(k)
    setChapterId("")
    setTopicId("")
    setUserId("")
  }

  const onClickSelectedBatch = (value) => {
    resetData();
    setTeacherAnalysisTabs("1")
    setPageNumber(1)
    let selected_grade_data = gradeSubjectFilter.find(
      (e) => e.grade_name === value.grade
    )

    setSelectedGradeId(selected_grade_data.grade_id)
    setClassName(value.grade)
    setSubjectName(value.subject_name)

    let subject_data = selected_grade_data.subjects.find(
      (e) => e.subject === value.subject_name
    )

    setSelectedSubjectId(subject_data.subjectId)
    setDashboardType("")
    setShowLoader(true)
    setSelectedSubjectStudCount(
      subject_data.StudentCount ? subject_data.StudentCount : 20
    )
    setSelectedBatch(value.batch)
  }

  return (
    <>
      <div id='CommonPageHolder' style={{ background: "#fff" }}>
        <div className='container-fluid' style={{ marginTop: "20px" }}>
          <div className='page_header_teacher'>{t("teacher_dashboard.teacher_db")}</div>

          <div className='page_subheader_teacher'>{t("teacher_dashboard.your_classes")}</div>
          <div className='content_wrapper'>
            <div className='classListWrapper'>
              {(() => {
                if (teacherBatchesList && teacherBatchesList.length > 0) {
                  return teacherBatchesList.map((val, index) => {
                    return (
                      <div
                        key={index}
                        className={`classListItem ${selectedBatch === val.batch &&
                          subjectName === val.subject_name &&
                          className === val.grade
                          ? "active"
                          : ""
                          }`}
                        onClick={() => {
                          onClickSelectedBatch(val)
                        }}
                      >
                        {t("dashboard.batch") + " " + val.grade + " " + val.batch + " " + t(`subjects.${val.subject_name.toLowerCase()}`)}
                      </div>
                    )
                  })
                } else {
                  return (
                    batches &&
                    batches.map((batch, bindex) => {
                      return (
                        gradeList &&
                        gradeList.map((value, index) => {
                          return value.subjects.map((subVal, ind) => {
                            return (
                              <div
                                key={ind}
                                onClick={() => {
                                  setTeacherAnalysisTabs("1")
                                  setPageNumber(1)
                                  setSelectedGradeId(value.grade_id)
                                  setClassName(value.grade_name)
                                  setSubjectName(subVal.subject)
                                  setSelectedSubjectId(subVal.subjectId)
                                  setDashboardType("")
                                  setShowLoader(true)
                                  setSelectedSubjectStudCount(
                                    subVal.StudentCount
                                      ? subVal.StudentCount
                                      : 20
                                  )
                                  setSelectedBatch(batch.batch_name)
                                  setArrayForSubjectSelection([])
                                }}
                                className={`classListItem ${selectedSubjectId === subVal.subjectId &&
                                  batch.batch_name === selectedBatch
                                  ? "active"
                                  : ""
                                  }`}
                              >
                                {t("assessment.class")}{value.grade_name} {batch.batch_name}{" "}
                                {subVal.subject}
                              </div>
                            )
                          })
                        })
                      )
                    })
                  )
                }
              })()}

              {!isSubjectsAvailable ? t("assessment.no_grade_available") : ""}
            </div>
            <div className='container3'>
              <CustomCount
                headerText={studentCount?.total_users}
                subHeader={t('dashboard.total_students')}
                backgroundColor='#40A3F3'
                id='Total_student'
                studentCount={studentCount}
              />
              <CustomCount
                headerText={studentCount?.active_users_count}
                subHeader={t('dashboard.active_students')}
                backgroundColor='#1DD165'
                id='active_student'
                studentCount={studentCount}
              />
              <CustomCount
                headerText={studentCount?.inactive_users_count}
                subHeader={t('dashboard.inactive_students')}
                backgroundColor='#F06D6C'
                id='inactive_student'
                studentCount={studentCount}
              />
            </div>
            <div
              className='principle_component_wrapper'
              style={{
                marginTop: "20px",
                display: `${isSubjectsAvailable ? "" : "none"}`,
              }}
            >
              <div className='row'>
                <div className='col-md-12 col-lg-4 listing_tab'>
                  <Tabs
                    activeKey={TeacherAnalysisTabs}
                    onSelect={(k) => {
                      onTabSelect(k)
                    }}
                    className='custom_student_progress_graph'
                    justify
                  >
                    <Tab
                      eventKey='1'
                      title={t('dashboard.student_wise_analysis')}
                      className='custom_tab_content teacher_student_listing'
                    >
                      <StudentListing
                        classId=''
                        subjectId=''
                        setDashboardData={setDashboardData}
                        studentList={studentList}
                        userId={userId}
                        studentListLoading={studentListLoading}
                        dashboardType={dashboardType}
                      />
                    </Tab>
                    <Tab
                      eventKey='2'
                      title={t('dashboard.chapter_wise_analysis')}
                      className='custom_tab_content teacher_student_listing'
                    >
                      <ChapterListing2
                        chapterTopicPerformance={chapterWisePerformance}
                        setDashboardData={setDashboardData}
                        setDashboardDataForTopic={setDashboardDataForTopic}
                        chapterId={chapterId}
                        topicId={topicId}
                        onTabSelect={onTabSelect}
                      />
                    </Tab>
                  </Tabs>
                </div>
                <div className='col-md-12 col-lg-8'>
                  {dashboardType === "" ? (
                    <>
                      <Dropdown
                        className={`custom_studen-class-selection teacher ${dropdownLoading ? "disabled" : ""
                          }`}
                      >
                        <Dropdown.Toggle id='dropdown-basic'>
                        {t('dashboard.chapter_select')}
                          {/* {t('dashboard.select')} <span>{t('dashboard.chapter')}</span> */}
                          <div className='dash_drop_btn_list'>
                            {dropdownLoading ? (
                              <svg
                                version='1.1'
                                xmlns='http://www.w3.org/2000/svg'
                                width='60px'
                                height='60px'
                                viewBox='0 0 80 30'
                                style={{ textAlign: "center", margin: "auto" }}
                                className='m-auto text-center'
                              >
                                <circle cx={10} cy={10} r={10} fill='#f16b24'>
                                  <animate
                                    attributeName='cx'
                                    from={10}
                                    to={40}
                                    dur='0.5s'
                                    calcMode='spline'
                                    keySplines='0.42 0 0.58 1'
                                    keyTimes='0;1'
                                    repeatCount='indefinite'
                                  />
                                </circle>
                                <circle cx={10} cy={10} r={0} fill='#e4ac13'>
                                  <animate
                                    attributeName='r'
                                    from={0}
                                    to={10}
                                    dur='0.5s'
                                    calcMode='spline'
                                    keySplines='0.42 0 0.58 1'
                                    keyTimes='0;1'
                                    repeatCount='indefinite'
                                  />
                                </circle>
                                <circle cx={40} cy={10} r={10} fill='#f16b24'>
                                  <animate
                                    attributeName='cx'
                                    from={40}
                                    to={70}
                                    dur='0.5s'
                                    calcMode='spline'
                                    keySplines='0.42 0 0.58 1'
                                    keyTimes='0;1'
                                    repeatCount='indefinite'
                                  />
                                </circle>
                                <circle cx={70} cy={10} r={10} fill='#f16b24'>
                                  <animate
                                    attributeName='r'
                                    from={10}
                                    to={0}
                                    dur='0.5s'
                                    calcMode='spline'
                                    keySplines='0.42 0 0.58 1'
                                    keyTimes='0;1'
                                    repeatCount='indefinite'
                                  />
                                </circle>
                              </svg>
                            ) : (
                              <svg
                                width='18'
                                height='15'
                                viewBox='0 0 18 15'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M17.3932 0.60599L17.3932 5.92263L9.01212 14.4145L0.631023 5.92263L0.631023 0.605989L9.01212 9.13477L17.3932 0.60599Z'
                                  fill='#D1D1D1'
                                />
                              </svg>
                            )}
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Accordion defaultActiveKey='0'>
                            {chapterWisePerformance.map((subject, index) => {
                              return (
                                <div key={index}>
                                  {subject.map((chapter, chapter_index) => {
                                    return (
                                      <Card key={chapter.chapter_name}>
                                        <Card.Header>
                                          <label
                                            style={{
                                              fontWeight: "bold",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <input
                                              type='checkbox'
                                              onChange={(e) => {
                                                handleSubChange(e, chapter)
                                              }}
                                              checked={
                                                selectedTopicChapter.find(
                                                  (e) =>
                                                    e.chapter_id ===
                                                    chapter.chapter_id
                                                )
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {chapter.translated_chapter_name.toLowerCase()}
                                          </label>
                                        </Card.Header>
                                      </Card>
                                    )
                                  })}
                                </div>
                              )
                            })}
                          </Accordion>
                        </Dropdown.Menu>
                      </Dropdown>
                      {(() => {
                        if (arrayForSubjectSelection.length === 0) {
                          return (
                            <div className='showing_results'>
                              {t(`subjects.${subjectName}`)} {t("super_admin_dashboard.performance")}
                            </div>
                          )
                        } else if (arrayForSubjectSelection.length > 0) {
                          let isTopic = arrayForSubjectSelection.find(
                            (e) => e.type === "topic"
                          )
                          if (isTopic) {
                            let topic_length = arrayForSubjectSelection.filter(
                              (e) => e.type === "topic"
                            ).length
                            let chapter = arrayForSubjectSelection.find(
                              (e) => e.type === "chapter"
                            )
                            let first_selected_topic =
                              arrayForSubjectSelection.find(
                                (e) => e.type === "topic"
                              )
                            return (
                              <div className='showing_results'>
                                {t("dashboard.showing_result_for")} {t(`subjects.${subjectName}`)} {` > `}{" "}
                                {chapter.translated_chapter_name} {` > `}{" "}
                                {first_selected_topic.topic_name}{" "}
                                {topic_length > 1
                                  ? " and " + (topic_length - 1) + " more"
                                  : ""}
                              </div>
                            )
                          } else {
                            let isTreasure = arrayForSubjectSelection.find(
                              (e) => e.type === "Treasure"
                            )
                            if (isTreasure) {
                              return (
                                <div className='showing_results'>
                                  {t("dashboard.showing_result_for")} {t(`subjects.${subjectName}`)} {` > `}{" "}
                                  {arrayForSubjectSelection[0].translated_chapter_name}{" "}
                                  {` > Tresure`}
                                </div>
                              )
                            } else {
                              return (
                                <div className='showing_results'>
                                  {user.lang_pref === "mr" ?
                                    <>{t(`subjects.${subjectName}`)} विषयासाठी परिणाम दाखवत आहे</>
                                    :
                                    <>{t("dashboard.showing_result_for")} {t(`subjects.${subjectName}`)}</>
                                  }
                                  {` > `}
                                  {arrayForSubjectSelection[0].translated_chapter_name}
                                  {arrayForSubjectSelection.length > 1
                                    ? " and " +
                                    (arrayForSubjectSelection.length - 1) +
                                    " more"
                                    : ""}
                                </div>
                              )
                            }
                          }
                        }
                      })()}
                      <DefaultDashboardComponent
                        className={className}
                        subjectName={subjectName}
                        performanceGraph={performanceGraph}
                        chapterTopicPerformance={chapterTopicPerformance}
                        arrayForSubjectSelection={arrayForSubjectSelection}
                        selectedBatch={selectedBatch}
                        gradeName={props.grade_name}
                      />
                    </>
                  ) : (
                    ""
                  )}

                  {dashboardType === "chapter_analysis" ? (
                    <ChapterAnalysis
                      className={className}
                      subjectName={subjectName}
                      performanceGraph={performanceGraph}
                      chapterTopicPerformance={chapterTopicPerformance}
                      arrayForSubjectSelection={arrayForSubjectSelection}
                      insightData={insightData}
                      selectedBatch={selectedBatch}
                    />
                  ) : (
                    ""
                  )}

                  {dashboardType === "report" ? (
                    <ReportingDashboardComponent
                      className={className}
                      subjectName={subjectName}
                      userPerformanceGraph={userPerformanceGraph}
                      userDetails={userDetails}
                      showLoader={showLoader}
                      studentList={studentList}
                      userId={userId}
                      selectedGradeId={selectedGradeId}
                      selectedSubjectId={selectedSubjectId}
                      school_id={props.school_id}
                      selectedBatch={selectedBatch}
                    />
                  ) : (
                    ""
                  )}

                  {dashboardType === "profile" ? (
                    <ProfileDashboardComponent
                      className={className}
                      subjectName={subjectName}
                      studentList={studentList}
                      userId={userId}
                      showLoader={showLoader}
                      userPerformanceGraph={userPerformanceGraph}
                      userOverview={userOverview}
                      school_id={props.school_id}
                      userDetails={userDetails}
                      selectedGradeId={selectedGradeId}
                      selectedSubjectId={selectedSubjectId}
                    />
                  ) : (
                    ""
                  )}

                  {dashboardType === "chapter_report" ? (
                    <ChapterReportingDashboard
                      chapterTopicPerformance={chapterTopicPerformance}
                      chapterId={chapterId}
                      chapterWisePerformance={chapterWisePerformance}
                      insightData={insightData}
                      selectedBatch={selectedBatch}
                      className={className}
                    />
                  ) : (
                    ""
                  )}

                  {dashboardType === "topic_report" ? (
                    <TopicrReportingDashboard
                      chapterTopicPerformance={chapterTopicPerformance}
                      chapterId={chapterId}
                      topicId={topicId}
                      chapterWisePerformance={chapterWisePerformance}
                      selectedBatch={selectedBatch}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <Loader showLoader={showLoader} />
        </div>
      </div>
    </>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherDashboardNew)
