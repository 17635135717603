import React, { PureComponent, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import contentGuard from "../../services/guards/content-guard";
import store from "store/store";
import { secureStorage, showNoInternetAlert } from "services/utility";
import routeGuard from "../../services/guards/route-guard";
import Body from "../../elements/Body";

//Import Components
import LeftSidebarMenu from "./leftsidebar-menu/LeftSidebarMenu";
import Emitter from "services/EventEmitter";
import MainHeader from "./main-header/mainHeader";
/* import Nav from "../../features/Nav/Nav"; */

class Index extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userType: null,
      themeName: "",
      isInstalled:
        secureStorage.getItem("pwanotinstalled") === "true" ? false : true,
      isLaunchedInBrowser:
        secureStorage.getItem("launchmode") === "browser-tab" ? true : false,
      isDeferred:
        window.deferredPrompt === null ||
        typeof window.deferredPrompt == "undefined"
          ? false
          : true,
      showClosebutton: false,
      showChildren: false,
    };

    this.capitalizeFirstLetter.bind(this);
    
  }

  //function for capital first letter of current page pathname
  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidMount() {
    Emitter.on("THEME_NAME", (newValue) => {
      this.setState({ themeName: newValue });
    });
  }
  componentWillMount = async () => {
    console.log("auth layout called again and again "+this.props);
    await routeGuard(this.props).then((result) => {        //routeGuard code initialised here
      if (result.isAllowed) {
        const theme = secureStorage.getItem("theme");
        if (theme == null) {
          this.setState({ themeName: "light" });
        } else {
          this.setState({ themeName: theme });
        }
        if (secureStorage.getItem("showClosebutton") != null) {
          this.setState({
            showClosebutton: secureStorage.getItem("showClosebutton"),
          });
        }
       

        if (navigator.onLine) {
          contentGuard(this.props).then((result) => {          //contenguard code initialised here
            let currentage = this.capitalizeFirstLetter(
              this.props.location.pathname
            );
            const { user } = store.getState();
            this.setState({
              userType: user.user_type ? user.user_type : "student",
            });
            document.title = currentage + " | Stepapp";
            if (!result) {
              window.location.replace("/plans");
            }
            this.setState({ showChildren: true });
          });
        } else {
          showNoInternetAlert();
          this.setState({ showChildren: true });
        }
        
      } else {
        window.location.replace(result.newLocation);
      }
    });
  };

  componentWillUnmount = () => {
    Emitter.off("THEME_NAME"); // remove THEME_NAME after component unmount
  };

  checkActiveUrl = () => {
    const url = this.props.location.pathname;
    if (url.startsWith("/assessment")) {
      return "layout-wrapper d-lg-flex unified-theme-quiz";
    } else {
      return "layout-wrapper d-lg-flex unified-theme-light";
    }
  };

  ///////////////////install PWA code
  installPWA = () => {
    if (window.deferredPrompt) {
      window.deferredPrompt.prompt();
      window.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // When user accepts install popup
          this.setState({ isInstalled: true });
          this.setState({
            isLaunchedInBrowser:
              secureStorage.getItem("launchmode") === "browser-tab"
                ? true
                : false,
          });
          this.setState({
            isDeferred:
              window.deferredPrompt === null ||
              typeof window.deferredPrompt == "undefined"
                ? false
                : true,
          });
        } else {
          // When user denies install popup
          this.setState({ isInstalled: false });
          this.setState({
            isLaunchedInBrowser:
              secureStorage.getItem("launchmode") === "browser-tab"
                ? true
                : false,
          });
          this.setState({
            isDeferred:
              window.deferredPrompt === null ||
              typeof window.deferredPrompt == "undefined"
                ? false
                : true,
          });
        }
      });
    }
  };

  hideCloseButton = () => {
    this.setState({ showClosebutton: true });
    secureStorage.setItem("showClosebutton", true);
  };

  render() {
    
    const classNames = "layout-wrapper d-lg-flex unified-theme-light";
    let splitArray = this.props.match.path.split("/");
    const pageName = this.props.match.path
      ? splitArray[splitArray.length - 1]
      : null;
    return (
      <Body
        loading={!this.state.showChildren}
        component={
          <React.Fragment>
            { window.innerWidth < 768 ? null:<MainHeader {...this.props}/>}
            <div className="page-layout-wrapper">
            <div
              id="unified-theme"
              className={
                "layout-wrapper d-lg-flex unified-theme-" + this.state.themeName
              }
            >
              {/* Navigation menu */}
              <LeftSidebarMenu {...this.props} />

              {/* render page content */}
              {!this.state.showClosebutton &&
                !this.state.isInstalled &&
                this.state.isLaunchedInBrowser &&
                this.state.isDeferred && (
                  <div className="install-overlay shadow-lg">
                    <div class="section-flex">
                      <button
                        class="btn-close-install"
                        id="button"
                        onClick={() => this.hideCloseButton()}
                      >
                        <i className="icon-plus rotate-45"></i>
                      </button>
                      <button
                        class="btn-rounded-install"
                        id="button"
                        onClick={() => this.installPWA()}
                      >
                        <icon class="icon-install"></icon> Install App{" "}
                      </button>
                    </div>
                  </div>
                )}
              <div
                className="layout-full mx-auto my-0"
                key={this.props.children}
                id="layout-full"
              >
                {this.props.children}
              </div>
            </div>
            </div>
          </React.Fragment>
        }
      />
    );
  }
}

export default withRouter(Index);
