import React from 'react';
import { Line } from 'react-chartjs-2';
import graphOptions from './options';
import { useTranslation } from 'react-i18next';


function ChapterWiseGraph(props) {
    const { t } = useTranslation();


    const options = graphOptions

    if (props.arrayForSubjectSelection) {
        let dataset = []
        let all_section = []
        let you = []
        let youline = []
        let your_grade = []


        let total_score = 0
        let overall_accuracy = 0
        let time_efficiency = 0
        let progress = 0
        let count = props.arrayForSubjectSelection.filter(x => x.type === 'topic').length
        let rank = "NA"


        if (props.arrayForSubjectSelection.find(e => e.type === 'topic')) {

            props.arrayForSubjectSelection.map((value, index) => {

                if (value.type === 'topic') {
                    total_score += value.topic_performance_graph.overall_topic_performance.total_score
                    overall_accuracy += value.topic_performance_graph.overall_topic_performance.overall_accuracy
                    time_efficiency += value.topic_performance_graph.overall_topic_performance.time_efficiency
                    progress += value.topic_performance_graph.overall_topic_performance.progress
                }

            })
            all_section = [Math.round(progress / count), Math.round(total_score / count), Math.round(overall_accuracy / count), Math.round(time_efficiency / count)]

            total_score = 0
            overall_accuracy = 0
            time_efficiency = 0
            progress = 0
            count = props.arrayForSubjectSelection.filter(x => x.type === 'topic').length
            props.arrayForSubjectSelection.map((value, index) => {
                if (value.type === 'topic') {
                    total_score += value.topic_performance_graph.topic_performance.total_score
                    overall_accuracy += value.topic_performance_graph.topic_performance.overall_accuracy
                    time_efficiency += value.topic_performance_graph.topic_performance.time_efficiency
                    progress += value.topic_performance_graph.topic_performance.progress
                }

            })
            you = [Math.round(total_score / count), Math.round(overall_accuracy / count), Math.round(time_efficiency / count), Math.round(progress / count), rank]
            youline = [Math.round(progress / count), Math.round(total_score / count), Math.round(overall_accuracy / count), Math.round(time_efficiency / count)]

            if (props.userDetails) {
                total_score = 0
                overall_accuracy = 0
                time_efficiency = 0
                progress = 0

                props.arrayForSubjectSelection.map((value, index) => {
                    if (value.type === 'topic') {
                        total_score += value.topic_performance_graph.batchwise_topic_performance.total_score
                        overall_accuracy += value.topic_performance_graph.batchwise_topic_performance.overall_accuracy
                        time_efficiency += value.topic_performance_graph.batchwise_topic_performance.time_efficiency
                        progress += value.topic_performance_graph.batchwise_topic_performance.progress
                    }

                })
                your_grade = [Math.round(progress / count), Math.round(total_score / count), Math.round(overall_accuracy / count), Math.round(time_efficiency / count)]
            }


        } else {
            props.arrayForSubjectSelection.map((value, index) => {
                if (value.type === "Treasure") {
                    return
                }

                total_score += value.overall_chapter_performance.total_score
                overall_accuracy += value.overall_chapter_performance.overall_accuracy
                time_efficiency += value.overall_chapter_performance.time_efficiency
                progress += value.overall_chapter_performance.progress
                count = index + 1
            })
            all_section = [Math.round(progress / count), Math.round(total_score / count), Math.round(overall_accuracy / count), Math.round(time_efficiency / count)]


            total_score = 0
            overall_accuracy = 0
            time_efficiency = 0
            progress = 0
            count = 0

            props.arrayForSubjectSelection.map((value, index) => {
                if (value.type === "Treasure") {
                    return
                }
                total_score += value.chapter_performance.total_score
                overall_accuracy += value.chapter_performance.overall_accuracy
                time_efficiency += value.chapter_performance.time_efficiency
                progress += value.chapter_performance.progress
                count = index + 1
                rank = value.chapter_performance.rank
            })
            you = [Math.round(total_score / count), Math.round(overall_accuracy / count), Math.round(time_efficiency / count), Math.round(progress / count), rank]
            youline = [Math.round(progress / count), Math.round(total_score / count), Math.round(overall_accuracy / count), Math.round(time_efficiency / count)]

            if (props.userDetails) {
                total_score = 0
                overall_accuracy = 0
                time_efficiency = 0
                progress = 0
                count = 0
                props.arrayForSubjectSelection.map((value, index) => {
                    if (value.type === "Treasure") {
                        return
                    }
                    total_score += value.batchwise_chapter_performance.total_score
                    overall_accuracy += value.batchwise_chapter_performance.overall_accuracy
                    time_efficiency += value.batchwise_chapter_performance.time_efficiency
                    progress += value.batchwise_chapter_performance.progress
                    count = index + 1
                })
                your_grade = [Math.round(progress / count), Math.round(total_score / count), Math.round(overall_accuracy / count), Math.round(time_efficiency / count)]
            }


        }
        if (props.setYouDataCall) {
            props.setYouDataCall(you)
        }
        dataset = [
            {
                label: t("dashboard.your_grade"),
                data: all_section,
                borderColor: '#FC8643',
                backgroundColor: '#FC8643',
                fill: false,
                lineTension: 0,
                borderWidth: 1.3131,
                pointBackgroundColor: "#FC8643"
            },
            {
                label: t("dashboard.your_section"),
                data: your_grade,
                borderColor: '#5164A6',
                backgroundColor: '#5164A6',
                fill: false,
                lineTension: 0,
                borderWidth: 1.3131,
                pointBackgroundColor: "#5164A6"
            },
            {
                label: props.section === 'user_dash' ? t("dashboard.your_performance") : props.userDetails?.fullname === '' || props.userDetails?.fullname === ' ' ? props.userDetails?.user_id : props.userDetails?.fullname + "'s" + " " + t("super_admin_dashbaord.performance"),
                data: youline,
                borderColor: '#37CE34',
                backgroundColor: '#37CE34',
                fill: false,
                lineTension: 0,
                borderWidth: 1.3131,
                pointBackgroundColor: "#37CE34"
            }
        ]

        let labels = [t("dashboard.progress"), t("dashboard.total_score"), t("dashboard.overall_accuracy"), t("dashboard.time_efficiency")];
        let data = {
            labels,
            datasets: dataset
        }
        return (
            <>
                <div className='line_chart_section'>
                    <Line options={options} data={data} />
                </div>


            </>
        )
    } else {
        return ("")
    }

} export default ChapterWiseGraph