import React from "react";
import {useTranslation} from "react-i18next";
import down_arrow_b2b from '../../../assets/images/down_arrow_b2b.png'

function ClassWiseFilter(props) {
    const {t} = useTranslation()

    console.log("cwf_props", props)


    const toggleOption = (value) => {

        if (value == props.displayedClass) {
            props.setdisplayedClass(t("super_admin_dashboard.all_classes"))
        } else {
            props.setdisplayedClass(value)
        }


        let board_name = value.split(' ')[0];
        let grade_name = value.split(' ')[1];
        console.log("toggle props ", props)
        let selected_board = props.boardWiseClasses.find(e => e.board_name === board_name)
        props.setSelectedBoard([{ board_id: selected_board.board_id, board_name: board_name }])
        let selected_grade = selected_board.grades.find(e => e.grade_name === grade_name)
        props.setSelectedGrade(selected_grade)
        props.setSelectedGradeId(selected_grade.grade_id)
        props.setSelectedClassSubjects(selected_grade.subjects)
        props.setSelectedSubjectId("all")
        props.setSelectedSubject("all")
        props.setDashboardType("SuperAdmin")
    }

    return (
        <>
            <div className="form_elm">
                <div className="form-group select_btn_wrap">
                    <label htmlFor="grade" className="select_btn">
                        <img src={down_arrow_b2b} alt="down_arrow" />
                    </label>

                    {props.selectedSchools.length == 0 ? <div className="c-multi-select-dropdown">
                        <div className="c-multi-select-dropdown__selected">
                            <div>{t("super_admin_dashboard.all_classes")}</div>

                        </div>
                        <ul className="c-multi-select-dropdown__options">


                            <li className="c-multi-select-dropdown__option" >
                                <span style={{ color: "red" }}>{t("super_admin_dashboard.select_a_school_first")}</span>
                            </li>


                        </ul>

                    </div> :
                        <MultiSelectDropdown options={props.classList} selected={props.displayedClass} toggleOption={toggleOption} />}

                </div>
            </div>


        </>
    )
}

export default ClassWiseFilter

const MultiSelectDropdown = ({ options, selected, toggleOption }) => {
    const {t}=useTranslation()
    console.log("check11", selected)

    return (
        <div className="c-multi-select-dropdown">
            <div className="c-multi-select-dropdown__selected">
                <div>{selected == "" ? t("super_admin_dashboard.all_classes") : selected} </div>
            </div>
            <ul className="c-multi-select-dropdown__options">
                {options.map((option, i) => {
                    const isSelected = option.includes(selected);
                    console.log("isSelected11", isSelected)

                    return (
                        <li key={i} className="c-multi-select-dropdown__option" onClick={() => toggleOption(option)}>
                            <input type="checkbox" checked={isSelected} className="c-multi-select-dropdown__option-checkbox"></input>
                            <span>{option}</span>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}