import React from 'react'
import store from 'store/store'
function Acadza() {
    const { user } = store.getState()

    const url = `https://acadza.com/signup-new?alpha=${user.user_mobile}&beta=${user.user_mobile}&course=jee`
    return (
        <div style={{ height: '90vh'}}>
            <iframe allow="fullscreen" allowfullscreen src={url} height={"100%"} width={"100%"} title='acadza' />
        </div>
    )
}
export default Acadza