
import React from 'react';
import { Line } from 'react-chartjs-2';
import graphOptions from './options';
import { useTranslation } from 'react-i18next';


function PrincipleAllSubjectGraph(props) {
    const {t} = useTranslation();
    const options = graphOptions

    console.log("all_sub",props)

        let datasets=[]
        let colorList=["#FC8643","#314075","#1ee84d","#6e3516","#e11e93",'#1badd1','#4be358','#d41775','#19d159']

        props.princilpleAllSubject && props.princilpleAllSubject.map((sub,index)=> {
            return datasets.push({
            label: t(`subjects.${sub.subject_name}`),
            data: [ sub.overall_subject_graph.progress,sub.overall_subject_graph.total_score, sub.overall_subject_graph.overall_accuracy,sub.overall_subject_graph.time_efficiency],
            borderColor: colorList[index],
            backgroundColor: colorList[index],
            fill: false,
            lineTension: 0,
            borderWidth: 1.3131,
            pointBackgroundColor: colorList[index],
        })}   
       )
       let labels = [ t("dashboard.progress"),t("dashboard.total_score"), t("dashboard.overall_accuracy"), t("dashboard.time_efficiency")];
       
        let data = {
            labels,
            datasets: datasets
        }
        return (
            <>
                <div className='line_chart_section'>
                    <Line options={options} data={data} />
                </div>
            </>
        )

} export default PrincipleAllSubjectGraph