import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { showErrorMessage } from "services/utility";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import '../onboarding.scss'
export class SelectGrades extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    selectedGrade: "",
    spinnerForFeed: this.props.spinnerForFeed,
    gradeArray: [],
  };
  // componentWillReceiveProps() {
  //   this.setState({ spinnerForFeed: this.props.spinnerForFeed });
  // }

  selectGradeConvert(number) {
    switch (number) {
      case "1":
        return "I";
      case "2":
        return "II";
      case "3":
        return "III";
      case "4":
        return "IV";
      case "5":
        return "V";
      case "6":
        return "VI";
      case "7":
        return "VII";
      case "8":
        return "VIII";
      case "9":
        return "IX";
      case "10":
        return "X";
      case "11":
        return "XI";
      case "12":
        return "XII";
      case "MYP 1":
        return "MYP 1"
      case "MYP 2":
        return "MYP 2"
      case "MYP 3":
        return "MYP 3"
      case "MYP 4&5":
        return "MYP 4&5"
      default:
        return number
    }
  }

  spinnerState() {
    this.setState({ spinnerForFeed: true });
  }


  componentDidMount() {
    if(this.props.selectedSchool == 1){
      this.generateGradeList()
    }else {

      this.filterGrade()
    }
  }

  filterGrade() {
    if (this.props.board === "IB") {
      this.setState({
        gradeArray: window.activatedGradesList

      })
    } else {
      this.setState({
        gradeArray: this.props.selectSchoolAllData.activated_grades.split(',')
      })
    }
  }

  onProceed(e) {
    e.preventDefault();
    if (this.state.selectedGrade.length > 0) {
      this.spinnerState();
      this.props.selectedgrade(this.state.selectedGrade);
    } else {
      showErrorMessage(
        this.props.t("onboarding.select_grade.please_select_grade"),
        1
      );
    }
  }

  generateGradeList=(data)=> {
    let arr=[];
    let temp = window.profileData.school.activatedBoards.filter((element) =>
    element.name.includes(this.props.board)
  );
  temp[0].activatedGrades &&
    temp[0].activatedGrades.forEach((element) => {
      arr.push(element);
    });

    this.setState({
      gradeArray: arr
    })
    console.log('gradeArray', this.state.gradeArray)
    }

  disabledButton = { height: "40px", backgroundColor: "#A5A5A5", border: "none", borderRadius: "8px" }
  enabledButton = { height: "40px", backgroundColor: "#FC8643", cursor: "pointer", border: "none", borderRadius: "8px", color: "#ffffff" }

  render() {
    const {t} = this.props;
    let selectedGradeState = (value) => {
      this.setState({ selectedGrade: value });
    };

    return (
      <div className="uk-cover-container uk-flex uk-flex-center uk-flex-middle uk-overflow-hidden uk-light">
        <div className="uk-flex uk-flex-center uk-flex-middle uk-position-z-index">
          <div style={{ width: "min(90vw, 400px)", borderRadius: "2rem", backgroundColor: "#fff", padding: "0.7rem 2rem" }}>
            <div className='text-left my-4' style={{ font: "normal normal 500 1.2rem/1.6rem Poppins", color: "#FC8643" }}>{t("Onboarding.Select_grade.select_grade")}</div>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(4,1fr)", gap: '0.5rem' }}>
              {this.state.gradeArray.map((element, i) => {
                return (
                  <div
                    key={i}
                    //style={{ border: "1px solid #969696",borderRadius: "8px", height: "2.8rem", position: "relative", display: "grid", placeItems: "center"}}
                    onClick={(e) => {
                      selectedGradeState(element)
                    }}
                    className="input_label_container"
                  >
                    <input
                      type="radio"
                      name="radio1"
                      id={element}
                      defaultValue={element}
                    />
                    <label className="mb-0 label_styles" htmlFor={element}
                    //style={{color: "#767676", border: "none", display: "grid", placeItems: "center", width: "100%", height: "100%"}}
                    >{this.selectGradeConvert(element)}</label>
                  </div>
                )
              })}
            </div>
            {!this.props.spinnerForFeed ?
              <button
                type="submit"
                className="w-100 mt-3"
                style={this.state.selectedGrade.length > 0 ? this.enabledButton : this.disabledButton}
                disabled={this.state.selectedGrade.length > 0 ? false : true}
                onClick={(e) => { this.onProceed(e) }}
              >
                {t("Onboarding.Select_grade.proceed")}
              </button>
              :
              <div className="text-center mt-2" style={{ height: "3rem", borderRadius: "8px" }}>
                <Spinner
                  animation="border"
                  style={{ color: "#1b1f5f" }}
                  size="lg"
                />
              </div>
            }
            <div className='mt-3' style={{ font: "normal normal 600 0.7rem/1.2rem Poppins", color: "#2B2B2B" }}>
            {t("Onboarding.Select_grade.please_select_grade")} .
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SelectGrades);
