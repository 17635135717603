import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import "./printPdfInputstyle.scss";
import { Button, Spinner } from "reactstrap";
import DraggableItem from "./DragableItem";
import Tooltip from "rc-tooltip";
import "antd/dist/antd.css";
import "rc-tooltip/assets/bootstrap_white.css";
import QuestionCard from "./QuestionCard";
import { useToasts } from "react-toast-notifications";
import PrintPdfQuizModal from "./PrintPdfQuizModal";


const PrintPDF = (props) => {
  const [printType, setPrintType] = useState("");
  const [activeCard, setActiveCard] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [updatedQuestionsList, setUpdatedQuestionsList] = useState([]);
  const [spaceForSubj, setSpaceForSubj] = useState(false);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [draggingOverIndex, setDraggingOverIndex] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [originalTasks, setOriginalTasks] = useState([]);
  const [sortSubQues, setSortSubQues] = useState(true);
  const { addToast } = useToasts();
  const subjectiveQuesRef = useRef(null);
  const componentRef = useRef();
  const history = useHistory();
  const { t } = useTranslation();

  const printFunc = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    onAfterPrint: () => console.log("Printing completed!"),
  });

  const handlePrint = (type) => {
    setPrintType(type);
    setTimeout(printFunc, 0);
    
  };

  const { questionsListArr } = props.location.state;
  const totalMarks = useMemo(
    () =>
      questionsListArr.reduce(
        (acc, val) => acc + Number(val.q_question_marks.positive),
        0
      ),
    [questionsListArr]
  );

  const totalDuration = useMemo(
    () =>
      questionsListArr.reduce(
        (acc, val) => acc + Number(val.q_question_duration),
        0
      ),
    [questionsListArr]
  );

  const totalDurationInMin = useMemo(
    () => Math.ceil(totalDuration / 60),
    [totalDuration]
  );

  const colorList = useMemo(
    () => ["#3cc1b9", "#C15C3C", "#3C99C1", "#E1B929"],
    []
  );

  useEffect(() => {
    const initialTasks = [...questionsListArr];
    setOriginalTasks(initialTasks);
    setTasks(initialTasks);
    setUpdatedQuestionsList(initialTasks);
  }, [questionsListArr]);

  const resetTasks = () => {
    setLoading(true);
    setTimeout(() => {
      setTasks(originalTasks);
      setUpdatedQuestionsList(originalTasks);
      setSortSubQues(true);
      setLoading(false);
    }, 500);
  };

  const sortTasks = () => {
    setLoading(true);
    setTimeout(() => {
      const sortedTasks = [...tasks].sort((a, b) => {
        if (a.type === "subjective" && b.type !== "subjective") return 1;
        if (a.type !== "subjective" && b.type === "subjective") return -1;
        return a.q_question_marks.positive - b.q_question_marks.positive;
      });
      setTasks(sortedTasks);
      setUpdatedQuestionsList(sortedTasks);
      setSortSubQues(false);
      setLoading(false);
    }, 500);
  };

  const onDrop = (index) => {
    if (activeCard === null || activeCard === undefined) return;

    const updatedTasks = [...tasks];
    const [removedTask] = updatedTasks.splice(activeCard, 1);
    updatedTasks.splice(index, 0, removedTask);

    setTasks(updatedTasks);
    setUpdatedQuestionsList(updatedTasks);

    setDraggingOverIndex(null);
  };

  const onDragStart = (index) => {
    setActiveCard(index);
    setDraggedIndex(index);
  };

  const onDragOver = (e, index) => {
    e.preventDefault();
    setDraggingOverIndex(index);
  };

  const dynamicSubjective = () => {
    setSpaceForSubj((prev) => !prev);
  };

  const dynamicAnswer = () => {
    setShowAnswer((prev) => !prev);
  };

  const renderedQuestions = useMemo(() => {
    return updatedQuestionsList.map((ques, index) => (
      <QuestionCard
        key={ques._id}
        ques={ques}
        index={index}
        showAnswer={showAnswer}
        spaceForSubj={spaceForSubj}
        colorList={colorList}
        t={t}
      />
    ));
  }, [updatedQuestionsList, showAnswer, spaceForSubj, colorList, t]);



  const [isAnswerChecked, setIsAnswerChecked] = useState(false);
  const [isSpaceChecked, setIsSpaceChecked] = useState(false);
  const handleAnswerToggle = () => {
    if (!spaceForSubj) {
      setIsAnswerChecked((prev) => !prev);
      dynamicAnswer(); 
    }
  };

  const handleSpaceToggle = ()=>{
    if(!showAnswer){
      setIsSpaceChecked((prev) => !prev);
      dynamicSubjective();
    }
  }

  return (
    <>
      <div className="row no-gutters">
        <div className="col-md-12  ">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0.3rem",
              paddingTop: "1rem",
            }}
            className="printHead"
          >
            <button
              onClick={() => history.goBack()}
              className="quize_edit backarrIcon"
            >
              <icon class="icon-back-arrow arrIconRes "></icon>
            </button>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "1rem",
              }}
              className="printMr"
            >
              <div
                style={{ display: "flex", gap: "10px" }}
                className="dynamicBtnBottom"
              >
                <Tooltip
                  placement="bottom"
                  trigger={spaceForSubj ? "hover " : ""}
                  overlay={
                    <span>Please turn off 'Show Space' Toggle button.</span>
                  }
                  overlayInnerStyle={{
                    backgroundColor: "#fc8643",
                    color: "#fff",
                    borderColor: "#fc8643",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "0.2px solid #A8A8A8",
                      borderRadius: "10px",
                      gap: "7px",
                      cursor: spaceForSubj ? "not-allowed" : "pointer",
                    }}
                    className={"showAnwerToggel"}
                    onClick={handleAnswerToggle}
                  >
                    <span
                      style={{
                        paddingBottom: "4px",
                        textTransform: "capitalize",
                      }}
                      className={`sliderText ${
                        spaceForSubj ? "answerToggelReplace" : ""
                      }`}
                    >
                      Show Answer
                    </span>
                    <label className="switch toggel">
                      <input
                        type="checkbox"
                        checked={isAnswerChecked}
                        onChange={handleAnswerToggle}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  trigger={showAnswer ? "hover" : ""}
                  overlay={
                    <span>Please turn off 'Show Answer' Toggle button.</span>
                  }
                  overlayInnerStyle={{
                    backgroundColor: "#fc8643",
                    color: "#fff",
                    borderColor: "#fc8643",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "0.2px solid #A8A8A8",
                      borderRadius: "10px",
                      gap: "7px",
                    }}
                    className={`subjectiveText spaceForSubj`}
                    onClick={handleSpaceToggle}
                  >
                    <span style={{ textTransform: "capitalize" }}>
                      Show Space
                    </span>
                    <label className="switch toggel">
                      <input
                        type="checkbox"
                        checked = {isSpaceChecked}
                        onChange={handleSpaceToggle}
                      />
                      <span
                        class="slider round"
                        className={`slider round ${
                          showAnswer ? "sliderOff" : ""
                        } `}
                      ></span>
                    </label>
                  </div>
                </Tooltip>
              </div>
              <button
                style={{
                  font: "1rem/normal Poppins",
                  color: "#FFF",
                  borderRadius: "5px",
                  padding: "0.5rem 0.5rem",
                  border: "none",
                  backgroundColor: "#F16B24",
                  marginRight: "10px",
                }}
                className="prntBtn"
                onClick={() => handlePrint("all")}
              >
                 Print
              </button>
            </div>
          </div>
          <div ref={componentRef} className="">
            <div className="printableDiv " style={{ pageBreakInside: "avoid" }}>
              <div className="stundentInfo">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <PrintPdfQuizModal questionsListArr={questionsListArr} />
                    <div>
                      <p className="totalMarks">Total Marks: {totalMarks}</p>
                    </div>
                  </div>
                  <div>
                    <h4 className="durationWhilePrint">
                      Duration: {totalDurationInMin}Mins
                    </h4>
                  </div>
                </div>

                <div class="form-container studentInputWhilePrint">
                  <div class="form-group student-name">
                    <label for="student-name">Student Name</label>
                    <input type="text" id="student-name" disabled />
                  </div>
                  <div class="form-group roll-no">
                    <label for="roll">Roll No</label>
                    <input type="text" id="roll" disabled />
                  </div>
                  <div class="form-group class">
                    <label for="class">Class</label>
                    <input type="text" id="class" disabled />
                  </div>
                  <div class="form-group date">
                    <label for="date">Date</label>
                    <input
                      type="text"
                      id="date"
                      className="dateinput"
                      disabled
                    />
                  </div>
                </div>
                <div className="borderBottom"></div>

                <div className="questionAdjust">
                  <div
                    style={{ display: "flex" }}
                    className="questionAdjustHeading"
                  >
                    <h5 className="">Question Sequence</h5>
                    <span className="">
                      {
                        "(You can change question sequence by just drag and drop)"
                      }
                    </span>
                  </div>
                  <div className="quesAdjBtnAndBox">
                    <div
                      className="questionAdjust"
                      style={{
                        display: "flex",
                        gap: "20px",
                        flexWrap: "wrap",
                      }}
                    >
                      {tasks.map((task, index) => (
                        <React.Fragment>
                          <DraggableItem
                            key={index}
                            item={index}
                            index={index}
                            onDragStart={onDragStart}
                            onDragOver={onDragOver}
                            onDrop={onDrop}
                            isDragging={index === draggedIndex}
                            isOver={index === draggingOverIndex}
                            addToast={addToast}
                            overlay={
                              task.type === "subjective"
                                ? "subjective"
                                : task.type === "mcq"
                                ? "mcq"
                                : "mcms"
                            }
                            overlayInnerStyle={{
                              backgroundColor:
                                task.type === `subjective`
                                  ? "#678ef1"
                                  : "#fc8643",
                              color: "#fff",
                            }}
                            style={task.type === "subjective" ? "dragSubj" : ""}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                    <div className="sortResetBtn">
                      {sortSubQues ? (
                        <div onClick={sortTasks} className="sortButton">
                          {loading ? (
                            <Button
                              className=""
                              style={{
                                border: "0.2px solid grey",
                                padding: "10px",
                                backgroundColor: "#4361FC",
                                color: "#fff",
                                marginBottom: "2px",
                              }}
                              disabled
                            >
                              <Spinner size="sm">loading</Spinner>
                              <span
                                style={{ marginLeft: "5px", fontSize: "16px" }}
                              >
                                loading
                              </span>
                            </Button>
                          ) : (
                            <button
                              onClick={() => {
                                sortTasks();
                                addToast("Question Sorted successfully!", {
                                  appearance: "success",
                                  autoDismiss: true,
                                });
                              }}
                              className="sortBtnHover"
                              style={{
                                border: "0.2px solid grey",
                                padding: "10px",
                                borderRadius: "8px",
                                backgroundColor: "#4361FC",
                                color: "#fff",
                              }}
                            >
                              Sort Question
                            </button>
                          )}
                        </div>
                      ) : (
                        <div onClick={resetTasks} className="sortButton">
                          {loading ? (
                            <Button
                              className=""
                              style={{
                                border: "0.2px solid grey",
                                padding: "10px",
                                backgroundColor: "#FC4343",
                                color: "#fff",
                                marginBottom: "2px",
                              }}
                              disabled
                            >
                              <Spinner size="sm">loading</Spinner>
                              <span
                                style={{ marginLeft: "5px", fontSize: "16px" }}
                              >
                                loading
                              </span>
                            </Button>
                          ) : (
                            <button
                              onClick={() => {
                                resetTasks();
                                addToast("Question Reset successfully!", {
                                  appearance: "success",
                                  autoDismiss: true,
                                });
                              }}
                              className="resetBtnHover"
                              style={{
                                border: "0.2px solid grey",
                                padding: "10px",
                                borderRadius: "8px",
                                backgroundColor: "#FC4343",
                                color: "#fff",
                              }}
                            >
                              Reset Question
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div ref={subjectiveQuesRef}>{renderedQuestions}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintPDF;
