import OverallInsighGraph from "pages/SuperAdmin/components/overall_insight/overall_insight_graph"
import StudentsRankClass from "pages/SuperAdmin/components/overall_insight/students_rank_class"
import StudentCount from "pages/SuperAdmin/components/overall_insight/student_count"
import React from "react"
import {useState} from "react"
import {useEffect} from "react"
import { useTranslation } from "react-i18next"
import TotalStudentTable from "./total_student_table"
function LandingComponent(props) {
  const [overallInsigetGraph, setOverallInightGraph] = useState([])
  const [graph, setGraph] = useState([])
  const [stats, setStats] = useState([])
  const {t} = useTranslation();

  const nth = (n) => {
    var s = ["th", "st", "nd", "rd"],
      v = n % 100
    return n + (s[(v - 20) % 10] || s[v] || s[0])
  }
  useEffect(() => {
    let classList = []
    let label = []
    let active_user = []
    let progress = []
    let total_score = []
    if (graph.length !== 0) {
      let progress_count = 0
      let active_user_count = 0
      let total_score_count = 0
      let count = 0
      graph.map((val, index) => {
        progress_count += val.progress
        active_user_count += val.active_users
        total_score_count += val.total_score

        let v = nth(val.grade_name)
        label.push(v)

        classList.push({title: val.grade_name, id: val.grade_id})
        active_user.push(val.active_users)
        progress.push(val.progress)
        total_score.push(val.total_score)
        count++
      })
      label.push(t("super_admin_dashboard.all_classes"))
      active_user.push(Math.round(active_user_count / count))
      progress.push(Math.round(progress_count / count))
      total_score.push(Math.round(total_score_count / count))
    }
    setOverallInightGraph({label, active_user, progress, total_score})
    // props.setClassList(classList)
  }, [graph])
  useEffect(() => {
    getOverallInsightData()
  }, [])
  const getOverallInsightData = async () => {
    // setLoader(true)
    let url = `https://duknujr2a2.execute-api.ap-south-1.amazonaws.com/prod/super-admin-analysis?school_chain_id=0&school_id=${props.schoolId}&action=principle`

    const res = await fetch(url)
    const data = await res.json()
    if (data.statusCode === 200) {
      setStats(data.stats)
      setGraph(data.graph)
      // setLoader(false)
    }
  }
  return (
    <>
      <div className='principle_component'>
        <div className='principle_component_heading'>
          {t("dashboard.showing_overall_insights")}
        </div>
        <div className='wrapper_for_page_padding'>
          <div className='row'>
            <div className='col-md-7'>
              <StudentCount stats={stats} />
              <OverallInsighGraph overallInsigetGraph={overallInsigetGraph} />
            </div>
            <div className='col-md-5'>
              <StudentsRankClass classList={graph} schoolId={props.schoolId} />
            </div>
          </div>
          <TotalStudentTable board_name ={props.board_name} schoolId={props.schoolId} stats={stats} graph={graph} />
        </div>
      </div>
    </>
  )
}
export default LandingComponent
