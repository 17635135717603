import PyramidChart from 'pages/Quizzes/components/Quiz/components/Pyramid Chart';
import React from 'react'
import {useState} from 'react';
import {Modal,ModalBody,ModalHeader} from 'reactstrap';
import * as XLSX from "xlsx"
import infoIcon from "../../../../assets/images/infoIcon.svg";
import { useTranslation } from "react-i18next";

const StudentLeaderBoardTable = (props) => {
    const [showModal,setShowModal] = useState(false)
    const [userValue,setUserValue] = useState(null)
    const { t } = useTranslation();

    const downloadExcel = async (value) => {
        let data = []
        if(value === "active") {
            data = props.participate.map((s) => ({
                "Rank": s.rank,
                "Name": s.full_name,
                "Score": s.score,
                "Accuracy": s.accuracy,
                "Correct Response": s.correct_responses,
                "Incorrect Response": s.incorrect_responses,
                "active/inactive": "active",
            }))
        } else if(value === "inactive") {
            data = props.notparticipate.map((s) => ({
                "NAME": s.full_name,
                "active/inactive": "inactive",
            }))
        }

        const worksheet = await XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook,worksheet,"Sheet1");
        XLSX.writeFile(workbook,"DataSheet.xlsx");

    }

    const modalHanlder = (value) => {
        setShowModal(true)
        setUserValue(value)
    }

    const toggle = () => {
        setShowModal(!showModal)
    }

    const closeBtn = (
        <button
            style={{
                position: "absolute",
                top: "25px",
                right: "25px",
                transform: "translate(100%, -100%)",
                width: '48px',
                height: '48px',
                borderRadius: '50%',
                borderStyle: 'none',
                border: ' 1px solid #ffffff',
                backgroundColor: '#fff',
                fontSize: "1.5rem"
            }}
            type='button'
            onClick={toggle}>
            &times;
        </button>
    )

    const showBloomModal = () => {
        return (
            <Modal
                isOpen={showModal}
                toggle={toggle}
                size={"md"}
                centered={true}
                backdrop='static'
                fade={true}
            >
                <ModalHeader style={{borderBottom: "none"}} toggle={toggle} close={closeBtn}>
                    <span className='pt-small font-weight-bold'>{userValue?.full_name}'s Bloom Report</span>
                </ModalHeader>
                <ModalBody>
                    {userValue && <PyramidChart
                        bloomRecallData={userValue.user_bloom.rightRecallCount + "/" + (userValue.user_bloom.rightRecallCount + userValue.user_bloom.wrongRecallCount)}
                        bloomUnderstandData={userValue.user_bloom.rightUnderstandCount + "/" + (userValue.user_bloom.rightUnderstandCount + userValue.user_bloom.wrongUnderstandCount)}
                        bloomApplyData={userValue.user_bloom.rightApplyCount + "/" + (userValue.user_bloom.rightApplyCount + userValue.user_bloom.wrongApplyCount)}
                        bloomAnalyseData={userValue.user_bloom.rightAnalyseCount + "/" + (userValue.user_bloom.rightAnalyseCount + userValue.user_bloom.wrongAnalyseCount)}
                        bloomEvaluateData={userValue.user_bloom.rightEvaluateCount + "/" + (userValue.user_bloom.rightEvaluateCount + userValue.user_bloom.wrongEvaluateCount)}
                        bloomCreateData={userValue.user_bloom.rightCreateCount + "/" + (userValue.user_bloom.rightCreateCount + userValue.user_bloom.wrongCreateCount)}
                    />}
                    <div style={{width: "90%",height: "20px",backgroundColor: "#fff",display: "flex",marginTop: "-12px",position: 'relative'}} />

                </ModalBody>

            </Modal>
        )
    }

    console.log("all_props_is",props)
    console.log("userValue",userValue)
    return (
        <div>
            {props.participate &&
                <div className='p-3' style={{border: "4px solid #F3F6F9",borderRadius: "16px"}}>
                    <div className='row d-flex align-items-center justify-content-between mx-3'>
                        <span className='pt-small font-weight-bold' style={{color: "#212121"}}>{t("assessment.leaderboard")}</span>
                        <button
                            className='border-0 text-white px-3 py-2 pt-extra-small font-weight-normal'
                            style={{background: "#F8A364",borderRadius: "6px"}}
                            onClick={() => downloadExcel("active")}
                        >
                            {t("assessment.download_report")}
                        </button>
                    </div>
                    <div style={{marginTop: "2rem",minHeight: "220px",maxHeight: "220px",overflowY: "scroll"}}>
                        <table className='table table-borderless'>
                            <thead style={{background: "#F3F6F9",borderRadius: "6px",position: "sticky",top: 0}}>
                                <tr
                                    style={{color: "#B5B5C3",letterSpacing: "3%"}}
                                    className="pt-extra-small font-weight-bold"
                                >
                                    <th scope='col'>{t("assessment.rank")}</th>
                                    <th scope='col'> {t("assessment.name")}</th>
                                    <th scope='col'>{t("assessment.marks")} ({t("assessment.out_of")} {props.totalMarks})</th>
                                    <th scope='col'>{t("assessment.accuracy")}</th>
                                    <th scope='col'>{t("assessment.correct")}</th>
                                    <th scope='col'>{t("assessment.incorrect")}</th>
                                </tr>
                            </thead>
                            {props.participate.map((stu,index) => {
                                return (
                                    <tbody key={index}>
                                        <tr>
                                            <td className='pt-extra-small font-weight-bold'>{stu.rank}</td>
                                            <td className='pt-extra-small font-weight-bold'>
                                                <div className='d-inline-flex'>{stu.full_name}
                                                    <img
                                                        src={infoIcon}
                                                        alt=""
                                                        className='ml-sm-2'
                                                        style={{cursor: "pointer", background: "#d9d9d9", borderRadius: "50%", padding: "1px 4px"}}
                                                        onClick={() => modalHanlder(stu)} 
                                                    />
                                                </div>
                                            </td>
                                            <td className='pt-extra-small font-weight-bold'>{stu.score}</td>
                                            <td className='pt-extra-small font-weight-bold'>{stu.accuracy.toFixed(0)}%</td>
                                            <td className='pt-extra-small font-weight-bold'>{stu.correct_responses}</td>
                                            <td className='pt-extra-small font-weight-bold'>{stu.incorrect_responses}</td>
                                        </tr>
                                    </tbody>
                                )
                            })}
                        </table>
                    </div>
                </div>}

            <div className='my-4' />

            {props.notparticipate &&

                <div className='p-3 col-xs-12 col-md-7' style={{border: "4px solid #F3F6F9",borderRadius: "16px",width: '100%'}}>
                    <div className='row d-flex align-items-center justify-content-between mx-3'>
                        <span className='pt-small font-weight-bold' style={{color: "#212121"}}>{t("assessment.student_not_participate")}</span>
                        <button
                            className='border-0 text-white px-3 py-2 pt-extra-small font-weight-normal'
                            style={{background: "#F8A364",borderRadius: "6px"}}
                            onClick={() => downloadExcel("inactive")}
                        >
                            {t("assessment.download_report")}
                        </button>
                    </div>
                    <div style={{marginTop: "2rem",minHeight: "220px",maxHeight: "220px",overflowY: "scroll"}}>
                        <table className='table table-borderless'>
                            <thead style={{background: "#F3F6F9",borderRadius: "6px",position: "sticky",top: 0}}>
                                <tr
                                    style={{color: "#B5B5C3",letterSpacing: "3%"}}
                                    className="pt-extra-small font-weight-bold">
                                    <th scope='col'> {t("assessment.rank")}</th>
                                    <th scope='col'> {t("assessment.name")}</th>
                                </tr>
                            </thead>
                            {props.notparticipate.map((stu,index) => {
                                return (
                                    <tbody key={index}>
                                        <tr>
                                            <td className='pt-extra-small font-weight-bold'>{index + 1}</td>
                                            <td className='pt-extra-small font-weight-bold'>{stu.full_name}</td>
                                        </tr>
                                    </tbody>
                                )
                            })}
                        </table>
                    </div>
                </div>
            }
            {showBloomModal()}
        </div>
    )
}

export default StudentLeaderBoardTable