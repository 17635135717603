// import Graph from "../common/graph";
import { ProgressBar } from "react-bootstrap";
import React, { useState } from "react";
import TeacherChapterSelectionGraph from "components/common/teacher_chapter_selection_graph";
import TeacherDefaultGraph from "components/common/teacher_default_graph";
import PrincipleAllSubjectGraph from "components/common/principle_allSubject_graph";
import PrincipleAllBatchGraph from "components/common/principle_allBatch_graph";
import { useTranslation } from "react-i18next";

function DefaultDashboardComponent(props) {
    console.log("DefaultDashboardComponent",props)
    const {t} = useTranslation()
    const [className, setClassName] = useState(props.className)
    const [subjectName, setSubjectName] = useState(props.subjectName)
    // debugger
    if (className !== props.className) {

        setClassName(props.className)
    }
    if (subjectName !== props.subjectName) {
        setSubjectName(props.subjectName)
    }

    const onClickHandler = (e) => {

        const hiddenElement = e.currentTarget.parentElement.getElementsByClassName('collapse')
        e.target.className.indexOf("dd-arrow showing") > -1 ? e.target.classList.remove("showing") : e.target.classList.add("showing");
        for (var i = 0; i < hiddenElement.length; i++) {
            hiddenElement[i].className.indexOf("collapse show") > -1 ? hiddenElement[i].classList.remove("show") : hiddenElement[i].classList.add("show");
        }
    }

console.log("default_dashboard_component",props)
    return (
        <>
            <div className="container-fluid">

                {
                    props.arrayForSubjectSelection && props.arrayForSubjectSelection.length !== 0 ?
                        <TeacherChapterSelectionGraph className={props.className} arrayForSubjectSelection={props.arrayForSubjectSelection} selectedBatch={props.selectedBatch} section="" /> 
                        :
                         props.subjectName === "all" ?
                          <PrincipleAllSubjectGraph  princilpleAllSubject={props.princilpleAllSubject} selectedBatch={props.selectedBatch} gradeName={props.className}/>

                        :
                        props.selectedBatch === "all" ?
                        <PrincipleAllBatchGraph  princilpleAllBatch={props.princilpleAllBatch} selectedBatch={props.selectedBatch} gradeName={props.className}/>
                       :
                       props.performanceGraph && <TeacherDefaultGraph  subjectName={subjectName} performanceGraph={props.performanceGraph} selectedBatch={props.selectedBatch} gradeName={props.className}/>

                }


           {props.subjectName !== "all" && <div className="performance_section">
                    <div className="performance_section_heading">{t("super_admin_dashboard.class")} - <span>{className} { props.selectedBatch === undefined ?"" :props.selectedBatch === "all" ? t("dashboard.all_batch") : t(`${props.selectedBatch}`) } {t(`subjects.${subjectName}`)}</span> {t("super_admin_dashboard.performance")}</div>

                    <div className="section_scrollable">
                        <table className="table table-bordered performance_section_table">
                            <thead>
                                <tr>
                                    <th>{t("dashboard.chapter_name")}</th>
                                    <th>{t("dashboard.total_score")}</th>
                                    <th>{t("dashboard.progress")}</th>
                                </tr>
                            </thead>
                            {
                                props.chapterTopicPerformance && props.chapterTopicPerformance.map((val, index) => {
                                    let progress = val.total_score;
                                    return (
                                        <tbody key={index}>
                                            <tr key={"chapter-" + index} className="d-down" onClick={onClickHandler}>
                                                <td className="dd-arrow">
                                                    {(index + 1)}. {val.translated_chapter_name.toLowerCase()}
                                                    <span>
                                                        {/* <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.1767 0.612128L12.1767 4.30802L6.35059 10.2112L0.524434 4.30801L0.524434 0.612127L6.35059 6.54095L12.1767 0.612128Z" fill="#9C9C9C"></path>
                                                        </svg> */}
                                                    </span>
                                                </td>
                                                <td><div className="progressandper"><span>{val.total_score}%</span><ProgressBar now={val.total_score} /></div></td>
                                                <td><div className="progressandper"><span>{val.progress}%</span><ProgressBar now={val.progress} /></div></td>
                                            </tr>
                                            {
                                                val.topic.map((tval, tindex) => {
                                                    return (
                                                        <tr key={"topic-" + tindex} className="collapse">
                                                            <td>
                                                                {(index + 1)}.{(tindex + 1)}. {tval.translated_topic_name.toLowerCase()}
                                                            </td>
                                                            <td><div className="progressandper"><span>{Math.round(progress === 0 && tval.topic_name.toLowerCase() == "treasure" ? 0 : tval.total_score)}%</span><ProgressBar now={progress === 0 && tval.topic_name.toLowerCase() == "treasure" ? 0 : tval.total_score} /></div></td>
                                                            <td><div className="progressandper"><span>{Math.round(progress === 0 && tval.topic_name.toLowerCase() == "treasure" ? 0 : tval.progress)}%</span><ProgressBar now={progress === 0 && tval.topic_name.toLowerCase() == "treasure" ? 0 : tval.progress} /></div></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    )
                                })
                            }
                        </table>
                    </div>
                </div>
                 }

            </div>
        </>
    )
} export default DefaultDashboardComponent