import * as firebase from "firebase/app";
import "firebase/analytics";
import firebaseConfig from "./firebaseConfig.json";
import "firebase/messaging";
let messaging = null;

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(
    "BGEQLVnO0hcTpqliqTAUnyk8qhZWWslpv2AFxmCaFahTCnN2XTjkSDFBnDsnL1LBMiBiZJUfxUffjGCpFAuUoOM"
  );
}
export { messaging };
const analytics = firebase.analytics;

export default analytics();
