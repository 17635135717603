import React, { useEffect, useState } from "react";
import downArrow from "../../../../../../assets/images/down_arrow_b2b.png"
import {useToasts} from "react-toast-notifications";
import { useTranslation } from "react-i18next";



function SubjectFilter(props) {
    const [classNameList, setClassNameList] = useState(props.classNameList);
    const [subjectNameList, setSubjectNameList] = useState(props.subjectNameList);
    const [subjectList,setSubjectList]=useState([]);
    const [newSubjectList,setNewSubjectList]=useState([]);
    const [isClassSelected,setIsClassSelected]=useState(false);
    const [className, setClassName] = useState('')
    const [subjectName, setSubjectName] = useState('')
    const {addToast}=useToasts();
    console.log("this is subject filter",classNameList);
    const {t}=useTranslation();

    const appyFilter = () => {

        if (!className) {
            return;
        }
        if (!subjectName) {
            return;
        }
        props.setIsFilterOpen(false)
        props.onApplyFilterClick("subject", className, subjectName, '');
    }
    useEffect(() => {
        if (className !== '') {
            let selected_subject_data = classNameList.find(g => g.grade_id === className);
            setSubjectNameList(selected_subject_data.subjects)
        }
    }, [className])

    useEffect(() => {
        if (props.filterInUse !== 'subject_filter') {
            setClassName('');
            setSubjectName('')
            setIsClassSelected(false);
        }
    }, [props.filterInUse])

    const handleClassCheck=()=>{
        if(!isClassSelected){
            addToast("Please select class first", {
                appearance: "warning",
                autoDismissTimeout: 2000,
              });
        }
    }
    let styling={
        post:{
            backgroundColor: isClassSelected ? "orange":""
        }
    }

    const handleclasschange=(e)=>{
        setClassName(e);
        let list=classNameList.map((d,i)=>{
            if(e===d.grade_id){
                setSubjectList(d.subjects);
                console.log("this is subjectlist",d.subjects);
                let newSubjectlist=d.subjects.filter((ele,ind)=>{
                    console.log("if",ele);
                    if(ele.batches.length>0){
                        return true;
                    }
                    return false;
                })
                console.log("newsubjectlist",newSubjectlist);
                setNewSubjectList(newSubjectlist);

            }
            return d;
        })
        if(e !== ""){
            setIsClassSelected(true);
        }
        else{
            setIsClassSelected(false);
        }
    }
    return (
        <>
            <div className="col-md-3">
                <div className="form_elm">
                    <div className="form-group select_btn_wrap">
                        <label htmlFor="grade" className="select_btn" style={{backgroundColor:"orange"}}>
                            <img src={downArrow} alt="down_arrow" />
                        </label>
                        <select
                            className="form-control"
                            name=""
                            value={className}
                            onChange={(e) => { handleclasschange(e.target.value); setSubjectName(''); props.setFilterInUse('subject_filter') }}>
                            <option value="" disabled>{t("dashboard.select_class")}</option>
                            {
                                classNameList.map((e, i) => {
                                    return (<option value={e.grade_id} key={i}>{t("super_admin_dashboard.class")} {e.grade_name}</option>)
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="form_elm">
                    <div className="form-group select_btn_wrap" onClick={handleClassCheck}>
                        <label htmlFor="grade" className="select_btn" style={styling.post}>
                            <img src={downArrow} alt="down_arrow" />
                        </label>
                        <select
                            className="form-control"
                            name=""
                            value= {subjectName}
                            disabled={!isClassSelected}
                            onChange={(e) => { setSubjectName(e.target.value); props.setFilterInUse('subject_filter') }}>
                            <option value="" disabled>{t("dashboard.select_subject")}</option>
                            {
                                newSubjectList.map((e, i) => {
                                    return (<option value={e.subjectId} key={i}>{t(`subjects.${e.subject.toLowerCase()}`)}</option>)
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="apply_filter_button" onClick={() => { appyFilter() }}>{t("dashboard.apply_filter")}</div>
            </div>
        </>
    )
}
export default SubjectFilter