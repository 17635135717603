import React, { useEffect, useState } from 'react'
import { showErrorMessage } from "services/utility";
import api from "services/api";
import config from "pwaConfig.json";
import { Spinner } from 'reactstrap'
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import logo_dark from "assets/images/logo/logo-dark.svg";

const SelectSchoolNew = (props) => {
  const [schooldChain, setSchoolChain] = useState(null)
  const [selectedSchool, setSelectedSchool] = useState('')
  const [spinnerForFeed, setSpinnerForFeed] = useState(false)
  const [allSchools, setAllSchools] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const { t } = useTranslation();
  //useEffect(() => {
  //  getSchools()
  //}, [])

  const getSchools = async (e) => {
    setSpinnerForFeed(true)
    // document.getElementById("school_select_input").value=""
    setSearchValue('')
    setSelectedSchool('')
    setSchoolChain(e.target.value)
    try {
      await api.get(`${config.apiEndPoint}/schoollist?schoolChainId=${e.target.value}`).then((res) => {
        setAllSchools([])
        let sortedArray = res.data.sort((a, b) => {
          if (a.school_name < b.school_name) {
            return -1
          }
        })
        setAllSchools(sortedArray)
        setSpinnerForFeed(false)
      })
    }
    catch (e) {
      console.log('err', e)
      setSpinnerForFeed(true)
    }
  }

  console.log("allSchools_list", allSchools)

  const handleSelectSchool = (e) => {
    console.log("selected_school", e.target.value)
    setSelectedSchool(e.target.value)
  }

  const handleSchoolSelection = (e) => {
    setSearchValue(e.target.value)
    let selectedSchoolId = allSchools.find((sch) => sch.school_name === e.target.value)
    setSelectedSchool(selectedSchoolId?.id)
    props.handleSelectedSchoolData(selectedSchoolId)
  }

  const onProceed = (e) => {
    const {t} = this.props;
    setSpinnerForFeed(true)
    e.preventDefault()
    if (selectedSchool !== "") {
      setSpinnerForFeed(true)
      props.selectedSchoolFunc(selectedSchool)
      setSpinnerForFeed(false)
    } else {
      showErrorMessage(t("Onboarding.Select_school.kindly_select_school"), 3);
    }
  }

  let disabledButton = { height: "40px", backgroundColor: "#A5A5A5", border: "none", borderRadius: "8px" }
  let enabledButton = { height: "40px", backgroundColor: "#FC8643", cursor: "pointer", border: "none", borderRadius: "8px", color: "#ffffff" }

  console.log("selectedSchool_is", selectedSchool)

  const schoolChainID = [
    { id: 501, name: 'Lucknow Project_2023' },
    { id: 502, name: 'Varanasi Schools_2023-24' },
    { id: 504, name: 'Mizoram Police Schools' },
    { id: 507, name: 'Super 2000_APS 2023' },
    { id: 508, name: 'Army Public Schools_2023-24' },
    { id: 509, name: 'Super Sangamner_2023-24' },
    { id: 480, name: 'Private Schools_2022-23' },
    { id: 519, name: 'Rajasthan Project_ICT Enabled 2023-24' },
    { id: 523, name: 'Ghaziabad Nagar Nigam Schools_2023-24' },
    { id: 525, name: 'Santkabir Nagar' },
  ]

  console.log('allSchools_is', allSchools)
  console.log('selectedSchool_is', selectedSchool)

  return (
    <div>
      <div className="row no-gutters">
        <div className="col-md-12 no-gutters" style={{ display: "grid", placeItems: "center" }}>
          <div style={{ width: "min(90vw, 400px)", backgroundColor: "#fff", borderRadius: "1.5rem" }}>
            <div className="row no-gutters my-auto ">  
              <div className="cardtitle col-sm-7 mx-2">{t("Onboarding.Select_school.select_school")}</div>
            </div>
            <div className="row m-2 justify-content-center">
              <div className="col-sm-10 ">
                <div className="form-group ">
                  <div>
                    <form onSubmit={onProceed}>
                      <div className="d-flex justify-content-start" style={{ color: "#767676", font: "normal normal 400 0.7rem/1.2rem Poppins" }}>{t("Onboarding.Select_school.school_chain")}</div>
                      <select
                        onChange={getSchools}
                        style={{ padding: "0.5rem", marginBottom: "1rem", marginInline: "auto", width: "100%", color: "black", borderRadius: "8px", height: "2.5rem", border: "1px solid #969696" }}
                      >
                        <option selected disabled>{t("Onboarding.Select_school.select")} </option>
                        {schoolChainID.map((d) => {
                          return (
                            <option key={d.id} value={d.id}>{d.name}</option>
                          )
                        })}
                      </select>
                      {schooldChain && <>
                        <div className="d-flex justify-content-start" style={{ color: "#767676", font: "normal normal 400 0.7rem/1.2rem Poppins" }}>{t("Onboarding.Select_school.school")}</div>
                        {/*<select onChange={handleSelectSchool} style={{ padding: "0.5rem 0.5rem", marginBottom: "1rem", marginInline: "auto", width: "100%", color: "black", borderRadius: "8px", height: "2.5rem" }}>
                        <option selected disabled>Select </option>
                        {allSchools.map((d) => {
                          return (
                            <option key={d.id} value={d.id}>{d.school_name}</option>
                          )
                        })}
                      </select>*/}
                        <input
                          list="schools"
                          placeholder='Search school'
                          onChange={(e) => handleSchoolSelection(e)}
                          value={searchValue}
                          style={{ padding: "0.5rem", marginBottom: "1rem", marginInline: "auto", width: "100%", color: "black", borderRadius: "8px", height: "2.5rem", border: "1px solid #969696" }}
                        />
                        <datalist id="schools">
                          {allSchools.map((d) => {
                            return (
                              <option key={d.id}>{d.school_name}</option>
                            )
                          })}
                        </datalist>
                      </>}
                      <div className='d-flex align-items-center justify-content-center' style={{ gap: '2rem' }}>
                        {spinnerForFeed ?
                          <div className="text-center mt-2 d-flex align-items-center justify-content-center">
                            <Spinner
                              animation="border"
                              style={{ color: "#1b1f5f" }}
                              size="lg"
                            />
                          </div> : <>
                            <button
                              className="w-100 mt-3"
                              style={{ ...enabledButton, backgroundColor: '#FFF', border: '1px solid #FC8643', color: '#FC8643' }}
                              onClick={() => {
                                setSelectedSchool(1)
                                props.handleSelectedSchoolData({
                                  //activated_boards: 'CBSE,ICSE,IB,SSC',
                                  //activated_grades: '1,2,3,4,5,6,7,8,9,10,11,12',
                                  //activated_languages: 'en,hi,mr,te,tu',
                                  activated_boards: window.boardList.toString(),
                                  activated_grades: window.activatedGradesList.toString(),
                                  activated_languages: window.languageList.toString(),
                                  id: '1'
                                })
                              }}
                            >
                              {t("Onboarding.Select_school.skip")}
                            </button>
                            <button
                              type="submit"
                              className="w-100 mt-3"
                              style={selectedSchool ? enabledButton : disabledButton}
                              disabled={selectedSchool ? false : true}
                            >
                             {t("Onboarding.Select_school.proceed")}
                            </button>
                          </>}
                      </div>
                    </form>

                    <div className='mt-3' style={{ font: "normal normal 600 0.7rem/1.2rem Poppins", color: "#2B2B2B" }}>
                    {t("Onboarding.Select_school.please_select_school")}  .
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(SelectSchoolNew)