// import Graph from "../common/graph";
import { ProgressBar } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Graph from "components/common/graph";
import ChapterWiseGraph from "components/common/chapterwisegraph";
import TeacherChapterWiseGraph from "components/common/teacherchapterwisegraph";
import { useTranslation } from "react-i18next";

function ChapterAnalysis(props) {

    const [className, setClassName] = useState(props.className)
    const [subjectName, setSubjectName] = useState(props.subjectName)
    const { t } = useTranslation()
    // debugger
    if (className !== props.className) {

        setClassName(props.className)
    }
    if (subjectName !== props.subjectName) {
        setSubjectName(props.subjectName)
    }

    const onClickHandler = (e) => {

        const hiddenElement = e.currentTarget.parentElement.getElementsByClassName('collapse')
        for (var i = 0; i < hiddenElement.length; i++) {
            hiddenElement[i].className.indexOf("collapse show") > -1 ? hiddenElement[i].classList.remove("show") : hiddenElement[i].classList.add("show");
        }
    }


    return (
        <>
            <div className="container-fluid">
                <div className="teacher_report_heading"><span>{t("teacher_dashboard.all_chapters")}</span> {t("super_admin_dashboard.performance")}</div>
                {
                    // props.arrayForSubjectSelection && props.arrayForSubjectSelection.length !== 0 ?
                    //     <TeacherChapterWiseGraph arrayForSubjectSelection={props.arrayForSubjectSelection} /> : props.performanceGraph && <Graph performanceGraph={props.performanceGraph} selectedBatch={props.selectedBatch} />

                    props.performanceGraph && <Graph performanceGraph={props.performanceGraph} selectedBatch={props.selectedBatch} />

                }


                <div className="insight_section">
                    <div className="teacher_report_heading"><span>{t("teacher_dashboard.all_chapters")}</span> {t("teacher_dashboard.insights")}</div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="insight_heading">{t("teacher_dashboard.chapter_wise")} {t("teacher_dashboard.insights")}</div>
                            <div className="insight_block_bordered_card">
                                <div className="upper">
                                    <div className="upper_heading">{t("teacher_dashboard.strongest_chap")} :</div>
                                    <div className="upper_content">
                                        {props?.insightData?.chapter_wise_insights?.strong_translated_chapters?.length === 0 ? <div>{t("teacher_dashboard.no_data")}</div> : ""}
                                        {
                                            props.insightData.chapter_wise_insights && props.insightData.chapter_wise_insights.strong_translated_chapters.map((val, index) => {
                                                if (props.insightData.chapter_wise_insights.strong_chapters[index] === 'No Data') {
                                                    return "No Data"
                                                } else {
                                                    return (<div>{index + 1}. {val}</div>)
                                                }
                                            })

                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="insight_heading">{t("teacher_dashboard.stu_wise")} {t("teacher_dashboard.insights")}</div>
                            <div className="insight_block_bordered_card" >
                                <div className="upper">
                                    <div className="upper_heading">{t("teacher_dashboard.chapter_wise")} {t("teacher_dashboard.ranklist")} :</div>
                                    <div className="upper_content" style={{ maxHeight: '400px', overflow: 'auto' }}>
                                        {props?.insightData?.student_wise_insights?.strong_in_chapters?.length === 0 ? <div>{t("teacher_dashboard.no_data")}</div> : ""}
                                        {
                                            props.insightData.student_wise_insights && props.insightData.student_wise_insights.strong_in_chapters
                                                .map((val, index) => {
                                                    if (val === 'No Data') {
                                                        return "No Data"
                                                    } else {
                                                        return (<div style={{display:'flex'}}><div style={{ flex: 0.7 }}>{index + 1}. {val.first_name === '' || val.first_name === ' ' || val.first_name === null ? val.user_id : val.first_name+ ' ' + val.last_name}</div> <span style={{ flex: 0.3 }}>{Math.floor(val.score)}%</span></div>)
                                                    }

                                                })

                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
} export default ChapterAnalysis