import GraphForUserSubject from "components/common/graph_for_user_subject";
import ChapterWiseGraph from "components/common/chapterwisegraph";
import AppLoader from "elements/AppLoader";
import React, { createRef, useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionContext,
  Card,
  Dropdown,
  ProgressBar,
  Tab,
  Tabs,
  useAccordionButton,
} from "react-bootstrap";
import { connect } from "react-redux";
import store from "store/store";
import { getFeedRailFromLocalSequentially } from "services/dataServices/userContentService";
import GraphForUserStudent from "components/common/graph_for_user_student";
import infoIcon from "../../assets/images/infoIcon.svg";
import accuracy from "../../assets/images/accuracy.png";
import efficiency from "../../assets/images/efficiency.png";
import progress from "../../assets/images/progress.png";
import section from "../../assets/images/section.png";
import totalScore from "../../assets/images/total_score.png";
import { useTranslation } from "react-i18next";
import { getUTFDecodedText } from "services/utility";
import { studentAvatar } from "services/utility";

const mapStateToProps = (state) => {
  const user = state.user;

  return state.user;
};

function StudentDashboard(props) {
  const referen = createRef();
  const { t } = useTranslation();
  const [miniLeaderboard, setMiniLeaderboard] = useState("first_section");
  const [releam, setReleam] = useState("0");
  const [userPerformanceGraph, setUserPerformance_graph] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [reportCardOverview, setReportCardOverview] = useState([]);
  const [conceptsToRelearn, setConceptsToRelearn] = useState([]);
  const [subjectAnalysisDetails, setSubjectAnalysisDetails] = useState([]);

  const [selectedBigTab, setSelectedBigTab] = useState("");
  const [selectedBigTabSubjectId, setSelectedBigTabSubjectId] = useState("");

  const [overallSubjectPerformance, setOverallSubjectPerformance] = useState(
    []
  );
  const [batchSubjectPerformance, setBatchSubjectPerformance] = useState([]);
  const [bigTabSubjectPerformance, setBigTabSubjectPerformance] = useState([]);

  const [chapterWisePerformance, setChapterWisePerformance] = useState([]);
  const [selectedSubjectData, setSelectedSubjectData] = useState([]);
  const [selectedSubjectBatchData, setSelectedBatchSubjectData] = useState([]);
  const [allSectionsPerformance, setAllSectionsPerformance] = useState([]);
  const [loader, setLoader] = useState(true);
  const [arrayForSubjectSelection, setArrayForSubjectSelection] = useState([]);
  const [selectedTopicChapter, setselectedTopicChapter] = useState([]);
  const [selectedTopicList, setSelectedTopicList] = useState([]);

  const [dropdownLoading, setDropdownLoading] = useState(false);

  const [youData, setYouData] = useState([]);

  const [miniLeaderboardListing, setMiniLeaderboardListing] = useState([]);

  const { user } = store.getState();

  useEffect(() => {
    startApp();
  }, []);

  useEffect(() => {
    if (selectedBigTab !== "") {
      setDropdownLoading(true);
      setTheUserData();
    }
  }, [selectedBigTabSubjectId]);

  const setTheUserData = async () => {
    const { user } = store.getState();
    const res = await fetch(
      `https://b7rd2d9uye.execute-api.ap-south-1.amazonaws.com/prod/chapter-topic-analysis?language=${
        user.lang_pref
      }&school_id=${props.school_id}&board_id=${
        userDetails.board_name
      }&grade_id=${encodeURIComponent(userDetails.grade_id)}&user_id=${
        props.id
      }&subject_id=${selectedBigTabSubjectId}`
    );
    const data = await res.json();
    if (data.statusCode === 200) {
      let subject_data = subjectAnalysisDetails.find(
        (e) => e.subject_name === selectedBigTab
      );

      let batch_subject_data = batchSubjectPerformance.find(
        (e) => e.subject_name === selectedBigTab
      );
      if (!subject_data) {
        subject_data = {
          chapters: [],
          overall_accuracy: 0,
          progress: 0,
          rank: 0,
          subject_id: "",
          subject_name: "",
          time_efficiency: 0,
          total_score: 0,
        };
      }
      setSelectedSubjectData(subject_data);
      setSelectedBatchSubjectData(batch_subject_data);
      setChapterWisePerformance([data.chapter_topic_details]);
      setDropdownLoading(false);
    }
  };
  const startApp = async () => {
    const res = await fetch(
      `https://b7rd2d9uye.execute-api.ap-south-1.amazonaws.com/prod/user-performance?language=${
        user.lang_pref
      }&school_id=${props.school_id}&board_id=${
        props.board_name
      }&grade_id=${encodeURIComponent(props.grade_name)}&user_id=${props.id}`
    );
    const data = await res.json();
    if (data.statusCode === 200) {
      setUserPerformance_graph(data.performance_graph);
      setAllSectionsPerformance(
        data.performance_graph[0].all_section_performance
      );

      setUserDetails(data.userDetails);
      setReportCardOverview(data.subjectAnalysisDetails);
      setConceptsToRelearn(data.concept_to_relearn);
      setSubjectAnalysisDetails(data.subjectAnalysisDetails);
      setBatchSubjectPerformance(data.batchSubjectAnalysisDetails);
      setOverallSubjectPerformance(data.overallSubjectAnalysisDetails);

      let feedRailData = await getFeedRailFromLocalSequentially();
      setMiniLeaderboardListing(data.mini_leaderboard);

      setLoader(false);
    }
  };

  const resetData = () => {
    //reset  everything for second selection
    setSelectedTopicList([]);
    setYouData([]);

    setArrayForSubjectSelection([]);
    setselectedTopicChapter([]);
  };

  const handleSubChange = (e, value) => {
    let original_value = [...arrayForSubjectSelection];
    let selected_chapter_listing = [...selectedTopicChapter];

    //reset  everything for second selection
    original_value = [];
    selected_chapter_listing = [];
    setSelectedTopicList([]);
    setYouData([]);

    let chapter_info = [];

    if (e.target.checked) {
      chapter_info = value.chapter_performance_graph;
      chapter_info.chapter_id = value.chapter_id;
      chapter_info.translated_chapter_name = value.translated_chapter_name;
      chapter_info.type = "chapter";

      let total_topics = value.topics.length;
      let completed_topics = 0;

      value.topics.map((val, inde) => {
        if (val.topic_performance_graph.topic_performance.progress === 100) {
          completed_topics = completed_topics + 1;
        }
      });

      let chapter_progress = 100;
      chapter_info.chapter_performance.progress = chapter_progress;
      original_value.push(chapter_info);

      selected_chapter_listing.push({ chapter_id: value.chapter_id });
      original_value = original_value.filter((value, index) => {
        return original_value[index].type !== "topic";
      });
      setSelectedTopicList([]);
    } else {
      if (original_value.filter((x) => x.type === "chapter").length === 1) {
        original_value = [];
        selected_chapter_listing = [];
        setSelectedTopicList([]);
        setYouData([]);
      } else {
        original_value.splice(
          original_value.findIndex(
            ({ chapter_id }) => chapter_id == value.chapter_id
          ),
          1
        );
        selected_chapter_listing.splice(
          selected_chapter_listing.findIndex(
            ({ chapter_id }) => chapter_id == value.chapter_id
          ),
          1
        );
      }
    }
    setArrayForSubjectSelection(original_value);
    setselectedTopicChapter(selected_chapter_listing);
  };

  const bigTabSelection = (val) => {
    //for resetting everything
    resetData();

    if (selectedBigTab === val.subject_name) {
      setSelectedBigTab("");
      setSelectedBigTabSubjectId();
    } else {
      setSelectedTopicList([]);
      setYouData([]);
      let performance = overallSubjectPerformance.find(
        (e) => e.subject_name === val.subject_name
      );

      if (performance) {
        setBigTabSubjectPerformance(performance);
      } else {
        let values = {
          overall_accuracy: "0",
          progress: "",
          time_efficiency: "",
          total_score: "0",
          subject_id: "",
          subject_name: val.subject_name,
        };
        setBigTabSubjectPerformance(values);
      }

      setSelectedBigTab(val.subject_name);
      setSelectedBigTabSubjectId(val.subject_id);
    }
  };
  const setYouDataCall = (data) => {
    if (youData.toString() !== data.toString()) {
      setYouData(data);
    }
  };
  const checkIfNan = (val) => {
    if (isNaN(val)) {
      return "";
    }
    return val;
  };
  const getNumberWithOrdinal = (n) => {
    var s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  console.log("miniLeaderboardListing_is", miniLeaderboardListing);

  if (loader) {
    return <AppLoader />;
  } else {
    return (
      <>
        <div
          id="CommonPageHolder"
          className={`${props.isFromPrinciple ? "autoheight" : ""}`}
          style={{ background: "#fff" }}
        >
          <div className="container-fluid" style={{ marginTop: "20px" }}>
            <div className="page_header">
              {t("dashboard.hello")}{" "}
              {userDetails.fullname !== "" && userDetails.fullname !== " "
                ? userDetails.fullname
                : ""}
            </div>
            <div className="report-leaderboard">
              <div className="row">
                <div className="col-md-7">
                  <div className="reportcard">
                    <h3>{t("dashboard.report_card")}</h3>
                    <div className="report_card_table">
                      <table className="custom_report_table table">
                        <thead>
                          <tr>
                            <th>{t("dashboard.entity")}</th>
                            <th>
                              {t("dashboard.rank")}
                              <div className="info_icon">
                                <img src={infoIcon} alt="" />
                                <div className="tooltiptext">
                                  {t("dashboard.rank_text")}
                                </div>
                              </div>
                            </th>
                            <th>
                              {t("dashboard.total_score")}
                              <div className="info_icon">
                                <img src={infoIcon} alt="" />
                                <div className="tooltiptext">
                                  {t("dashboard.total_score_text")}
                                </div>
                              </div>
                            </th>
                            <th>
                              {t("dashboard.overall_accuracy")}
                              <div className="info_icon">
                                <img src={infoIcon} alt="" />
                                <div className="tooltiptext">
                                  {t("dashboard.overall_accuracy_text")}.
                                </div>
                              </div>
                            </th>
                            <th>
                              {t("dashboard.time_efficiency")}
                              <div className="info_icon">
                                <img src={infoIcon} alt="" />
                                <div className="tooltiptext">
                                  {t("dashboard.time_efficiency_text")}
                                </div>
                              </div>
                            </th>
                            <th>
                              {t("dashboard.progress")}
                              <div className="info_icon">
                                <img src={infoIcon} alt="" />
                                <div className="tooltiptext">
                                  {t("dashboard.progress_text")}
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {subjectAnalysisDetails.map((val, index) => {
                            let subject = reportCardOverview.find(
                              (e) => e.subject_id === val.subject_id
                            );
                            if (subject) {
                              return (
                                <tr key={index}>
                                  <td>{subject.translated_subject_name}</td>
                                  <td>
                                    {subject.rank == 0 ? "NA" : subject.rank}
                                  </td>
                                  <td>{Math.round(subject.total_score)}%</td>
                                  <td>
                                    {Math.round(subject.overall_accuracy)}%
                                  </td>
                                  <td>
                                    {Math.round(subject.time_efficiency)}%
                                  </td>
                                  <td className="progresstd">
                                    {Math.round(subject.progress)}%
                                    <ProgressBar
                                      now={Math.round(subject.progress)}
                                    />
                                  </td>
                                </tr>
                              );
                            } else {
                              return (
                                <tr key={index}>
                                  <td>{val.translated_subject_name}</td>
                                  <td>{"NA"}</td>
                                  <td>{0}%</td>
                                  <td>{0}%</td>
                                  <td>{0}%</td>
                                  <td className="progresstd">
                                    {0}%
                                    <ProgressBar now={0} />
                                  </td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="leaderboard">
                    <h3>{t("dashboard.mini_leader_board")}</h3>
                    <Tabs
                      activeKey={miniLeaderboard}
                      onSelect={(k) => setMiniLeaderboard(k)}
                      className="custom_mini_board"
                    >
                      <Tab
                        eventKey="first_section"
                        title={t("dashboard.your_section")}
                        className="custom_mini_board_tab"
                      >
                        <div className="mini_leaderboard_listing">
                          {miniLeaderboardListing?.Batch_section_leaderboard
                            .length !== 0
                            ? miniLeaderboardListing?.Batch_section_leaderboard.map(
                                (val, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="mini_leaderboard_listing_item"
                                    >
                                      <div
                                        className={
                                          userDetails.user_id === val.user_id
                                            ? "active_user_color"
                                            : ""
                                        }
                                      >
                                        {val.total_score == 0
                                          ? "NA"
                                          : getNumberWithOrdinal(val.rank)}
                                      </div>
                                      <div>
                                        <img
                                          alt="profile"
                                          className="img-fluid img-circle img-fluid"
                                          src={studentAvatar(val.avatar)}
                                        />
                                      </div>
                                      <div
                                        className={
                                          userDetails.user_id === val.user_id
                                            ? "active_user_color"
                                            : ""
                                        }
                                      >
                                        {val.fullname === "" ||
                                        val.fullname === " "
                                          ? val.user_id
                                          : val.fullname
                                              .toString()
                                              .toLowerCase()}
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            : t("dashboard.no_data_found")}
                          {(() => {
                            if (
                              miniLeaderboardListing
                                ?.user_batch_section_leaderboard.length !== 0
                            ) {
                              let getUser =
                                miniLeaderboardListing.Batch_section_leaderboard.find(
                                  (e) =>
                                    e.user_id ===
                                    miniLeaderboardListing
                                      .user_batch_section_leaderboard[0].user_id
                                );
                              if (!getUser) {
                                return (
                                  <div
                                    key={6}
                                    className="mini_leaderboard_listing_item"
                                  >
                                    <div
                                      className={
                                        miniLeaderboardListing
                                          .user_batch_section_leaderboard[0]
                                          .user_id === userDetails.user_id
                                          ? "active_user_color"
                                          : ""
                                      }
                                    >
                                      {miniLeaderboardListing
                                        .user_batch_section_leaderboard[0]
                                        .total_score == 0
                                        ? "NA"
                                        : getNumberWithOrdinal(
                                            miniLeaderboardListing
                                              .user_batch_section_leaderboard[0]
                                              .rank
                                          )}
                                    </div>
                                    <div>
                                      <img
                                        alt="profile"
                                        className="img-fluid img-circle img-fluid"
                                        src={studentAvatar(userDetails.avatar)}
                                      />
                                    </div>
                                    <div className="active_user_color">
                                      {userDetails.fullname === "" ||
                                      userDetails.fullname === " "
                                        ? userDetails.user_id
                                        : userDetails.fullname
                                            .toString()
                                            .toLowerCase()}
                                    </div>
                                  </div>
                                );
                              }
                            }
                          })()}
                        </div>
                      </Tab>
                      {
                        <Tab
                          eventKey="allsection"
                          title={t("dashboard.your_grade")}
                          className="custom_mini_board_tab"
                        >
                          <div className="mini_leaderboard_listing">
                            {miniLeaderboardListing?.all_section_leaderboard.map(
                              (val, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="mini_leaderboard_listing_item"
                                  >
                                    <div
                                      className={
                                        userDetails.user_id === val.user_id
                                          ? "active_user_color"
                                          : ""
                                      }
                                    >
                                      {val.total_score == 0
                                        ? "NA"
                                        : getNumberWithOrdinal(val.rank)}
                                    </div>
                                    <div>
                                      <img
                                        alt="profile"
                                        className="img-fluid img-circle img-fluid"
                                        src={studentAvatar(val.avatar)}
                                      />
                                    </div>
                                    <div
                                      className={
                                        userDetails.user_id === val.user_id
                                          ? "active_user_color"
                                          : ""
                                      }
                                    >
                                      {val.fullname === "" ||
                                      val.fullname === " "
                                        ? val.user_id
                                        : val.fullname.toString().toLowerCase()}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                            {(() => {
                              if (
                                miniLeaderboardListing
                                  .user_all_section_leaderboard.length !== 0
                              ) {
                                let getUser =
                                  miniLeaderboardListing.all_section_leaderboard.find(
                                    (e) =>
                                      e.user_id ===
                                      miniLeaderboardListing
                                        .user_all_section_leaderboard[0].user_id
                                  );
                                if (!getUser) {
                                  return (
                                    <div
                                      key={6}
                                      className="mini_leaderboard_listing_item"
                                    >
                                      <div
                                        className={
                                          miniLeaderboardListing
                                            .user_all_section_leaderboard[0]
                                            .user_id === userDetails.user_id
                                            ? "active_user_color"
                                            : ""
                                        }
                                      >
                                        {miniLeaderboardListing
                                          .user_all_section_leaderboard[0]
                                          .total_score == 0
                                          ? "NA"
                                          : getNumberWithOrdinal(
                                              miniLeaderboardListing
                                                .user_all_section_leaderboard[0]
                                                .rank
                                            )}
                                      </div>
                                      <div>
                                        <img
                                          alt="profile"
                                          className="img-fluid img-circle img-fluid"
                                          src={studentAvatar(
                                            userDetails.avatar
                                          )}
                                        />
                                      </div>
                                      <div className="active_user_color">
                                        {userDetails.fullname === "" ||
                                        userDetails.fullname === " "
                                          ? userDetails.user_id
                                          : userDetails.fullname
                                              .toString()
                                              .toLowerCase()}
                                      </div>
                                    </div>
                                  );
                                }
                              }
                            })()}
                          </div>
                        </Tab>
                      }
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            <div className="big_tab_section">
              <h3>{t("dashboard.analysis_heading")}</h3>
              <div
                className={`big_tabs  ${
                  dropdownLoading ? "disabled" : ""
                } classListWrapper`}
              >
                {subjectAnalysisDetails.map((val, index) => {
                  let subject = true;

                  return (
                    <div
                      key={index}
                      className={`tab ${
                        selectedBigTab === val.subject_name ? "active" : ""
                      } ${!subject ? "disabled" : ""}`}
                      onClick={() => {
                        bigTabSelection(val);
                        setArrayForSubjectSelection([]);
                        setChapterWisePerformance([]);
                      }}
                    >
                      {val.translated_subject_name}
                    </div>
                  );
                })}
              </div>
              <Dropdown
                className={`custom_studen-class-selection ${
                  dropdownLoading ? "disabled-click" : ""
                } ${
                  chapterWisePerformance.length === 0 ? "disabled-click" : ""
                }`}
              >
                <span className="tooltiptext">
                  {t("dashboard.select_subject_tooltip")}
                </span>
                <Dropdown.Toggle id="dropdown-basic">
                  <span>{t("dashboard.select_chapter")}</span>
                  <div className="dash_drop_btn_list">
                    {dropdownLoading ? (
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="60px"
                        height="60px"
                        viewBox="0 0 80 30"
                        style={{ textAlign: "center", margin: "auto" }}
                        className="m-auto text-center"
                      >
                        <circle cx={10} cy={10} r={10} fill="#f16b24">
                          <animate
                            attributeName="cx"
                            from={10}
                            to={40}
                            dur="0.5s"
                            calcMode="spline"
                            keySplines="0.42 0 0.58 1"
                            keyTimes="0;1"
                            repeatCount="indefinite"
                          />
                        </circle>
                        <circle cx={10} cy={10} r={0} fill="#e4ac13">
                          <animate
                            attributeName="r"
                            from={0}
                            to={10}
                            dur="0.5s"
                            calcMode="spline"
                            keySplines="0.42 0 0.58 1"
                            keyTimes="0;1"
                            repeatCount="indefinite"
                          />
                        </circle>
                        <circle cx={40} cy={10} r={10} fill="#f16b24">
                          <animate
                            attributeName="cx"
                            from={40}
                            to={70}
                            dur="0.5s"
                            calcMode="spline"
                            keySplines="0.42 0 0.58 1"
                            keyTimes="0;1"
                            repeatCount="indefinite"
                          />
                        </circle>
                        <circle cx={70} cy={10} r={10} fill="#f16b24">
                          <animate
                            attributeName="r"
                            from={10}
                            to={0}
                            dur="0.5s"
                            calcMode="spline"
                            keySplines="0.42 0 0.58 1"
                            keyTimes="0;1"
                            repeatCount="indefinite"
                          />
                        </circle>
                      </svg>
                    ) : (
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.3932 0.60599L17.3932 5.92263L9.01212 14.4145L0.631023 5.92263L0.631023 0.605989L9.01212 9.13477L17.3932 0.60599Z"
                          fill="#D1D1D1"
                        />
                      </svg>
                    )}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Accordion defaultActiveKey="0">
                    {chapterWisePerformance.map((subject, index) => {
                      return (
                        <div key={index}>
                          {subject.map((chapter, chapter_index) => {
                            let is_valid =
                              chapter.chapter_performance_graph
                                .chapter_performance.total_score === 0
                                ? false
                                : true; // chapter.topics.find(e => e.topic_performance_graph.topic_performance.total_score > 0)
                            return (
                              <Card key={chapter.chapter_name}>
                                <Card.Header>
                                  <label
                                    style={{ fontWeight: "bold" }}
                                    className={`${
                                      !is_valid ? "lil_ligher" : ""
                                    }`}
                                  >
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        handleSubChange(e, chapter);
                                      }}
                                      checked={
                                        selectedTopicChapter.find(
                                          (e) =>
                                            e.chapter_id === chapter.chapter_id
                                        )
                                          ? true
                                          : false
                                      }
                                      disabled={!is_valid}
                                    />
                                    {chapter.translated_chapter_name}
                                  </label>
                                </Card.Header>
                              </Card>
                            );
                          })}
                        </div>
                      );
                    })}
                  </Accordion>
                </Dropdown.Menu>
              </Dropdown>

              {(() => {
                if (
                  arrayForSubjectSelection.length === 0 &&
                  selectedBigTab !== ""
                ) {
                  return (
                    <div className="showing_results">
                      {user.lang_pref === "mr" ? (
                        <>
                          {t(`subjects.${selectedBigTab}`)} विषयासाठी परिणाम
                          दाखवत आहे
                        </>
                      ) : (
                        <>
                          {t("dashboard.showing_result_for")}{" "}
                          {t(`subjects.${selectedBigTab}`)}
                        </>
                      )}
                    </div>
                  );
                } else if (
                  arrayForSubjectSelection.length === 0 &&
                  selectedBigTab === ""
                ) {
                  return (
                    <div className="showing_results">
                      {t("dashboard.overall_performance")}
                    </div>
                  );
                } else if (arrayForSubjectSelection.length > 0) {
                  let isTopic = arrayForSubjectSelection.find(
                    (e) => e.type === "topic"
                  );
                  let topic_length = arrayForSubjectSelection.filter(
                    (e) => e.type === "topic"
                  ).length;
                  let chapter = arrayForSubjectSelection.find(
                    (e) => e.type === "chapter"
                  );
                  let first_selected_topic = arrayForSubjectSelection.find(
                    (e) => e.type === "topic"
                  );
                  if (isTopic) {
                    return (
                      <div className="showing_results">
                        {user.lang_pref === "mr" ? (
                          <>
                            {t(`subjects.${selectedBigTab}`)} विषयासाठी परिणाम
                            दाखवत आहे {` > `} {chapter.translated_chapter_name}{" "}
                            {` > `} {first_selected_topic.topic_name}{" "}
                            {topic_length > 1
                              ? " + " + (topic_length - 1) + " more"
                              : ""}
                          </>
                        ) : (
                          <>
                            {t("dashboard.showing_result_for")}{" "}
                            {t(`subjects.${selectedBigTab}`)} {` > `}{" "}
                            {chapter.translated_chapter_name} {` > `}{" "}
                            {first_selected_topic.topic_name}{" "}
                            {topic_length > 1
                              ? " + " + (topic_length - 1) + " more"
                              : ""}
                          </>
                        )}
                      </div>
                    );
                  } else {
                    let isTreasure = arrayForSubjectSelection.find(
                      (e) => e.type === "Treasure"
                    );
                    if (isTreasure) {
                      return (
                        <div className="showing_results">
                          {user.lang_pref === "mr" ? (
                            <>
                              {t(`subjects.${selectedBigTab}`)} विषयासाठी परिणाम
                              दाखवत आहे {` > `}{" "}
                              {
                                arrayForSubjectSelection[0]
                                  .translated_chapter_name
                              }{" "}
                              {` > Tresure`}
                            </>
                          ) : (
                            <>
                              {t("dashboard.showing_result_for")}{" "}
                              {t(`subjects.${selectedBigTab}`)} {` > `}{" "}
                              {
                                arrayForSubjectSelection[0]
                                  .translated_chapter_name
                              }{" "}
                              {` > Tresure`}
                            </>
                          )}
                          {t("dashboard.showing_result_for")}{" "}
                          {t(`subjects.${selectedBigTab}`)} {` > `}{" "}
                          {arrayForSubjectSelection[0].translated_chapter_name}{" "}
                          {` > Tresure`}
                        </div>
                      );
                    } else {
                      return (
                        <div className="showing_results">
                          {user.lang_pref === "mr" ? (
                            <>
                              {t(`subjects.${selectedBigTab}`)} विषयासाठी परिणाम
                              दाखवत आहे {` > `}{" "}
                              {chapter.translated_chapter_name} {` > `}{" "}
                              {first_selected_topic?.topic_name}{" "}
                              {topic_length > 1
                                ? " + " + (topic_length - 1) + " more"
                                : ""}
                            </>
                          ) : (
                            <>
                              {t("dashboard.showing_result_for")}{" "}
                              {t(`subjects.${selectedBigTab}`)} {` > `}{" "}
                              {chapter.translated_chapter_name} {` > `}{" "}
                              {first_selected_topic?.topic_name}{" "}
                              {topic_length > 1
                                ? " + " + (topic_length - 1) + " more"
                                : ""}
                            </>
                          )}
                        </div>
                      );
                    }
                  }
                }
              })()}
              <div className="row">
                <div className="col-md-4">
                  {selectedBigTab === "" ? (
                    <div className="score_section">
                      <div className="icon_section">
                        <div className="icon">
                          <img alt="profile" src={totalScore} />
                          {getUTFDecodedText(
                            t("dashboard.total_score_with_br")
                          )}
                        </div>
                        <div className="dots">:</div>
                        <div className="percentage">
                          {Math.round(
                            userPerformanceGraph[0]?.user_performance
                              .total_score
                          )}
                          %
                        </div>
                      </div>
                      <div className="icon_section">
                        <div className="icon">
                          <img alt="profile" src={accuracy} />
                          {getUTFDecodedText(
                            t("dashboard.overall_accuracy_with_br")
                          )}
                        </div>
                        <div className="dots">:</div>
                        <div className="percentage">
                          {Math.round(
                            userPerformanceGraph[0]?.user_performance
                              .overall_accuracy
                          )}
                          %
                        </div>
                      </div>
                      <div className="icon_section">
                        <div className="icon">
                          <img alt="profile" src={efficiency} />
                          {getUTFDecodedText(
                            t("dashboard.time_efficiency_with_br")
                          )}
                        </div>
                        <div className="dots">:</div>
                        <div className="percentage">
                          {Math.round(
                            userPerformanceGraph[0]?.user_performance
                              .time_efficiency
                          )}
                          %
                        </div>
                      </div>
                      <div className="icon_section">
                        <div className="icon">
                          <img alt="profile" src={progress} />
                          {getUTFDecodedText(t("dashboard.progress"))}
                        </div>
                        <div className="dots">:</div>
                        <div className="percentage">
                          {Math.round(
                            userPerformanceGraph[0]?.user_performance.progress
                          )}
                          %
                        </div>
                      </div>
                      <div className="icon_section">
                        <div className="icon">
                          <img alt="profile" src={section} />
                          {getUTFDecodedText(t("dashboard.rank"))}
                        </div>
                        <div className="dots">:</div>
                        <div className="percentage">
                          {userPerformanceGraph[0]?.user_performance.rank}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="score_section">
                      <div className="icon_section">
                        <div className="icon">
                          <img alt="profile" src={totalScore} />
                          {getUTFDecodedText(
                            t("dashboard.total_score_with_br")
                          )}
                        </div>
                        <div className="dots">:</div>
                        <div className="percentage">
                          {youData[0] || youData[1] == 0
                            ? youData[0]
                            : checkIfNan(
                                Math.round(selectedSubjectData.total_score)
                              ) ?? 0}
                          %
                        </div>
                      </div>
                      <div className="icon_section">
                        <div className="icon">
                          <img alt="profile" src={accuracy} />
                          {getUTFDecodedText(
                            t("dashboard.overall_accuracy_with_br")
                          )}
                        </div>
                        <div className="dots">:</div>
                        <div className="percentage">
                          {youData[1] || youData[1] == 0
                            ? youData[1]
                            : checkIfNan(
                                Math.round(selectedSubjectData.overall_accuracy)
                              ) ?? 0}
                          %
                        </div>
                      </div>
                      <div className="icon_section">
                        <div className="icon">
                          <img alt="profile" src={efficiency} />
                          {getUTFDecodedText(
                            t("dashboard.time_efficiency_with_br")
                          )}
                        </div>
                        <div className="dots">:</div>
                        <div className="percentage">
                          {youData[2] || youData[2] == 0
                            ? youData[2]
                            : checkIfNan(
                                Math.round(selectedSubjectData.time_efficiency)
                              ) ?? 0}
                          %
                        </div>
                      </div>
                      <div className="icon_section">
                        <div className="icon">
                          <img alt="profile" src={progress} />
                          {getUTFDecodedText(t("dashboard.progress"))}
                        </div>
                        <div className="dots">:</div>
                        <div className="percentage">
                          {youData[3] || youData[3] == 0
                            ? youData[3]
                            : checkIfNan(
                                Math.round(selectedSubjectData.progress)
                              ) ?? 0}
                          %
                        </div>
                      </div>
                      <div className="icon_section">
                        <div className="icon">
                          <img alt="profile" src={section} />
                          {getUTFDecodedText(t("dashboard.rank"))}
                        </div>
                        <div className="dots">:</div>
                        <div className="percentage">
                          {youData[4] || youData[4] == 0
                            ? youData[4]
                            : checkIfNan(
                                Math.round(selectedSubjectData.rank)
                              ) ?? "NA"}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-8">
                  {arrayForSubjectSelection.length !== 0 ? (
                    <ChapterWiseGraph
                      arrayForSubjectSelection={arrayForSubjectSelection}
                      setYouDataCall={setYouDataCall}
                      userDetails={userDetails}
                      section="user_dash"
                    />
                  ) : arrayForSubjectSelection.length > 0 ? (
                    ""
                  ) : selectedBigTab === "" ? (
                    userPerformanceGraph && (
                      <GraphForUserStudent
                        userPerformanceGraph={userPerformanceGraph}
                        userDetails={userDetails}
                        subjectName=""
                      />
                    )
                  ) : (
                    selectedSubjectData && (
                      <GraphForUserSubject
                        selectedSubjectData={selectedSubjectData}
                        allSectionsPerformance={bigTabSubjectPerformance}
                        selectedSubjectBatchData={selectedSubjectBatchData}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="releam_block">
              <div className="row">
                <div className="col-md-5">
                  <div className="leaderboard">
                    {conceptsToRelearn.length > 0 && (
                      <div
                        className="d-flex"
                        style={{ justifyContent: "left" }}
                      >
                        <div>
                          <h3>{t("dashboard.concepts_to_relearn")} </h3>
                        </div>

                        <div
                          className="d-flex"
                          style={{
                            justifyContent: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          <div className="info_icon">
                            <img src={infoIcon} alt="" />
                            <div className="tooltiptext">
                              {t("dashboard.concepts_to_relearn_tooltip")}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <Tabs
                      activeKey={releam}
                      onSelect={(k) => {
                        setReleam(k);
                      }}
                      className="custom_mini_board"
                    >
                      {conceptsToRelearn.map((val, index) => {
                        return (
                          <Tab
                            key={index}
                            eventKey={index}
                            title={val.translated_subject_name}
                            className="custom_releam_tab"
                          >
                            <div>
                              {val.score.map((sval, sindex) => {
                                return (
                                  <div key={sindex}>
                                    {sindex + 1}. {sval.translated_chapter_name}
                                  </div>
                                );
                              })}
                            </div>
                          </Tab>
                        );
                      })}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "10px" }} ref={referen}></div>
      </>
    );
  }
}
export default connect(mapStateToProps)(StudentDashboard);
