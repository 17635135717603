import React, { useMemo } from "react";
import { convertStringToLatex, initMathJax } from "services/utility";

const Options = React.memo(({ options, answer, showAnswer }) => {
  useMemo(() => {
    initMathJax();
  }, []);

  const renderOptions = useMemo(()=>{
  return options.map((el) => {
    const isCorrect = el.option_name === answer?.option_name;
    const optionStyles = {
      display: "flex",
      backgroundColor: isCorrect && showAnswer ? "#C8FCBF" : "transparent",
      color: isCorrect && showAnswer ? "#124609" : "#999898",
    };

   


    const createMarkupForOptions = (option_value) => {
      try {
        if (
          typeof option_value !== "number" &&
          typeof option_value !== "undefined"
        ) {
        let optionValue = convertStringToLatex(option_value);
        return { __html: optionValue };
        }
        return { __html: option_value };
      } catch (err) {
        console.log(err);
      }
    };
    return (
      <div
        key={el.option_name}
        className={`singleOption ${isCorrect && showAnswer ? "correct" : ""}`}
        style={optionStyles}
      >
        <div style={{ color: "#2B2B2B" }}>{el.option_name}.</div>
        {el.option_type === "image" ? (
          <img
            src={el.option_image}
            alt=""
            style={{ maxHeight: "250px", objectFit: "contain", }}
          />
        ) : (
          <div
            className="qest-text"
            dangerouslySetInnerHTML={createMarkupForOptions(el.option_value)}
          />
        )}
      </div>
    );
  });
}, [options, answer, showAnswer]);
return <> {renderOptions}</>
})

export default Options;
