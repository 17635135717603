import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import StudentDashboard from "../StudentDashboard";
import PrincipleDashboard from "pages/PrincipalDashboard/newDashboard";
import TeacherDashboardNew from "pages/TeacherDashboard/indexnew";
import SuperAdminDashboard from "pages/SuperAdmin";

const mapStateToProps = (state) => {
  return {
    userType: state.user.user_type,
    userMobile: state.user.user_mobile,
    subjectId: state.user.subjectId,
  };
};

export class DashboardHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userType: "",
      subjectId: "",
    };
  }

  updateFlagHandler = () => {
    this.setState({
      userType: "teacher",
      subjectId: undefined,
    });
  };

  async componentDidMount() {

    let userType = this.props.userType;
    let subjectId = this.props.subjectId;
    this.setState({
      userType: userType,
      subjectId: subjectId,
    });
  }
  render() {
   
    const { userType, subjectId } = this.state;

    if (userType === "teacher") {
      return (<TeacherDashboardNew />)

    } else if (userType === "teacher" && subjectId == undefined) {
      return (
        <div>
        </div>
      );
    } else if (userType === "principal") {
      return (
        <div>
          <PrincipleDashboard />
        </div>
      );
    } else if (userType === "superadmin") {
      return (
        <div>
          <SuperAdminDashboard />
        </div>
      );
    } else if (userType === "student") {
      return (
        <div>
          <StudentDashboard />
        </div>
      );
    } else {
      return <></>;
    }
  }
}

export default withRouter(connect(mapStateToProps, null)(DashboardHome));
