import React, { useContext, useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import OverallInsighGraph from "./overall_insight/overall_insight_graph";
import SchoolRankClass from "./overall_insight/school_rank_class";
import StudentsRankClass from "./overall_insight/students_rank_class";
import StudentCount from "./overall_insight/student_count";

function OverallInsight(props) {
    const [overallInsigetGraph, setOverallInightGraph] = useState([])

    const {t} = useTranslation()

    const nth = (n) => {
        var s = ["th", "st", "nd", "rd"],
            v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
    }
    useEffect(() => {
        let classList = []
        let label = []
        let active_user = []
        let progress = []
        let total_score = []
        if (props.overallInsightData.graph.length !== 0) {
            let progress_count = 0
            let active_user_count = 0
            let total_score_count = 0
            let count = 0
            props.overallInsightData.graph.map((val, index) => {
                progress_count += val.progress
                active_user_count += val.active_users
                total_score_count += val.total_score

                let v = nth(val.grade_name)
                label.push(v)

                classList.push({ title: val.grade_name, id: val.grade_id })
                active_user.push(val.active_users)
                progress.push(val.progress)
                total_score.push(val.total_score)
                count++;
            })
            label.push(t("super_admin_dashboard.all_classes"))
            active_user.push(Math.round((active_user_count / count)))
            progress.push(Math.round((progress_count / count)))
            total_score.push(Math.round((total_score_count / count)))

        }
        setOverallInightGraph({ label, active_user, progress, total_score })
    }, [props.overallInsightData])


    return (
        <>
            <div className="overall_insight">
                <div className="page_heading">{t("super_admin_dashboard.showing_overall_insights")}</div>

                <div className="row">
                    <div className="col-md-6">
                        <StudentCount stats={props.overallInsightData.stats} />
                        <OverallInsighGraph overallInsigetGraph={overallInsigetGraph} />
                    </div>
                    <div className="col-md-6">
                        {props.selectedSchools.length === 0 ? 
                        <SchoolRankClass classList={props.overallInsightData.graph} schoolId={props.schoolId} schoolChainId={props.schoolChainId} /> 
                        : 
                        <StudentsRankClass classList={props.overallInsightData.graph} schoolId={props.selectedSchools} />}
                    </div>
                </div>
            </div>
        </>
    )


}
export default OverallInsight