import React from "react";
import { useState, useEffect } from "react";
import store from "store/store";
import config from "../../../../config";
import axios from "axios";
import UIkit from "uikit";
import { useHistory } from "react-router-dom";
import CustomQuestionCard from "./CustomQuestionCard";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import "./QuizTeleporting.scss";
import filter from "img/filter.png";
import plusIcon from "img/plus-icon.svg";
import searchIcon from "img/search.svg";
import { Spinner } from "reactstrap";
import nocontent1 from "../../../../assets/images/teacher_assessment/nocontent2.png";
import nocontent2 from "../../../../assets/images/teacher_assessment/nocontent4.png";
import nocontent3 from "../../../../assets/images/teacher_assessment/nocontent5.png";
import "../../../../QuestionwiseTable.scss";
import { initMathJax } from "services/utility";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive"

const QuizTeleporting = (props) => {
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [searchItem, setSearchItem] = useState([]);
  const [alreadySelectedQuestions, setAlreadySelectedQuestions] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [questionsForQuestionCard, setQuestionsForQuestionCard] = useState([]);
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [totalQues, setTotalQues] = useState(0);
  const [orgtotalQues, setorgTotalQues] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [searchSpace, setSearchSpace] = useState([]);
  const [search_val, setSearch_val] = useState("");
  const [isSpinner, setSpinner] = useState(true);
  const [showQuestionsSpinner, setShowQuestionsSpinner] = useState(false);
  const [submitSpinner, setsubmitSpinner] = useState(false);
  const [questionsCount, setQuestionsCount] = useState(0);
  const [bloom, setbloom] = useState([]);
  const [difficultyLevel, setdifficultyLevel] = useState([]);
  const [questype, setquestype] = useState([]);
  const [method, setmethod] = useState([]);
  const [showhamburger, setShowhamburger] = useState(true);
  const [showavai, setShowavai] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });

  const { t } = useTranslation();
  const {
    board,
    grade,
    subject,
    assessmentID,
    board_id,
    grade_id,
    subject_id,
    q_question_marks,
    q_question_duration,
  } = props.location.state;

  useEffect(() => {
    initMathJax();
  }, [questionsForQuestionCard, allQuestions]);

  useEffect(()=>{
  if(isSmallScreen){
    setShowavai(true);
    setShowhamburger(false);
  }
  },[isSmallScreen]);

  function getDifference(array1, array2) {
    return array1.filter((object) => {
      return !array2.some((object2) => {
        return object._id === object2.base_question_id;
      });
    });
  }

  function caps(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const getSearchData = async () => {
    let reqObj = {
      subject_id: "5efc4c84f641cce04026f0c5",
      grade_id: "5e8c400d722d808593198699",
    };
    let url = config.assessmentUrl + "/get-assessment-list";
    let result = await axios.post(url, reqObj);

    setSearchSpace(result.data.data);

    setSearchItem(result.data.data);
    setSpinner(false);
    setAlreadySelectedQuestions(props.location.state.questionsListArr);
    // ------------------------------------------------------------------------for intial filtering on basis of grade,subject-------
    let gradeVal = document.getElementById("grade_val").value;
    let subjectVal = document.getElementById("subject_val").value;

    console.log("");
    let query = "";
    setSearch_val(query);

    let searchSubjectGrade = result.data.data
      .filter((g) => g.grade === gradeVal)
      .filter((s) => s.subject === subjectVal);
    let searchQuestions = searchSubjectGrade.filter((f) =>
      f.title.toLowerCase().includes(query.toLowerCase())
    );

    setSearchItem(searchQuestions);

    // ------------------------------------------------------------------------ends---------------------------------------------------
  };

  useEffect(() => {
    getSearchData();
  }, []);

  let easy = 0;
  let medium = 0;
  let hard = 0;

  let addQuestion = (question) => {
    setSelectedQuestions([...selectedQuestions, question]);
  };
  let removeQuestion = (question) => {
    const qb2 = [...selectedQuestions];
    const filtered = qb2.filter((f) => f != question);
    setSelectedQuestions(filtered);
  };

  const applyFilters = () => {
    let sel_level = document.getElementById("level_val").value;
    let sel_method = document.getElementById("method_val").value;
    let sel_type = document.getElementById("type_val").value;
    let sel_bloom = document.getElementById("bloom_val").value;

    let filtered = allQuestions;
    if (sel_level) {
      filtered = filtered.filter((g) => g.difficultyLevel === sel_level);

      setTotalQues(filtered.length);
    }
    if (sel_method) {
      filtered = filtered.filter((g) => g.methods === sel_method);

      setTotalQues(filtered.length);
    }
    if (sel_type) {
      filtered = filtered.filter((g) => g.type === sel_type);

      setTotalQues(filtered.length);
    }
    if (sel_bloom) {
      filtered = filtered.filter((g) => g.bloomLevel === sel_bloom);

      setTotalQues(filtered.length);
    }
    setQuestionsForQuestionCard(filtered);
  };

  const { user } = store.getState();

  const addBulkQuestions = () => {
    if (selectedQuestions.length > 0) {
      const { user } = store.getState();
      let teacherId = user.id;
      let schoolId = user.school_id;

      let url = config.assessmentUrl + "/save-assessment-question";
      let failureCount = 0;
      let loopCount = 0;

      selectedQuestions.forEach(async (queObj) => {
        queObj.board_id = board_id;
        queObj.created_at = new Date();
        queObj.board = board;
        queObj.grade = grade;
        queObj.subject_id = subject_id;
        queObj.updated_at = new Date();
        queObj.grade_id = grade_id;
        queObj.base_question_id = queObj._id;
        queObj.subject = subject;
        queObj.school_id = schoolId;
        queObj.teacher_id = teacherId;
        queObj.assessment_id = assessmentID;
        queObj.q_question_duration = q_question_duration;
        queObj.q_question_marks = q_question_marks;
        delete queObj._id;
        delete queObj.sheetId;
        delete queObj.ref_id;
        let result = await axios.post(url, queObj);
        if (result.data.status !== true) {
          failureCount++;
        }

        loopCount = loopCount + 1;

        if (loopCount == selectedQuestions.length) {
          setSelectedQuestions([]);
          if (failureCount < 1) {
            setShowModal(true);
          } else {
            UIkit.modal.alert(t("assessment.something_went_wrong"));
          }
        }
      });
    }
  };
  const toggle_1 = () => {
    const ele = document.getElementById("topic_search");
    ele.classList.remove("left-side");
    ele.classList.add("left-side-mob");
  };
  const toggle_2 = () => {
    const ele = document.getElementById("topic_search");
    ele.classList.remove("left-side-mob");
    ele.classList.add("left-side");
  };

  const searching = (e) => {
    let gradeVal = document.getElementById("grade_val").value;
    let subjectVal = document.getElementById("subject_val").value;
    let query = e;
    setSearch_val(query);

    let searchSubjectGrade = searchSpace
      .filter((g) => g.grade === gradeVal)
      .filter((s) => s.subject === subjectVal);
    let searchQuestions = searchSubjectGrade.filter((f) =>
      f.title.toLowerCase().includes(query.toLowerCase())
    );
    console.log("searchQuestions", searchQuestions);
    setSearchItem(searchQuestions);
  };

  const filteringGrade = (e) => {
    let gradeVal = document.getElementById("grade_val").value;
    let subjectVal = document.getElementById("subject_val").value;

    let searchSubjectGrade = searchSpace
      .filter((g) => g.grade === gradeVal)
      .filter((s) => s.subject === subjectVal);
    let searchQuestions = searchSubjectGrade.filter((f) =>
      f.title.toLowerCase().includes(search_val.toLowerCase())
    );

    setSearchItem(searchQuestions);
    console.log("this is during filtering", searchItem);
  };

  const GoBack = () => {
    history.goBack();
  };

  const addAllQuestions = (filterQuestion) => {
    if (filterQuestion.length === 0) {
      filterQuestion = [];
      setSelectedQuestions(filterQuestion);
    }
    console.log("filterQuestion", filterQuestion);
    setSelectedQuestions(filterQuestion);
  };

  const showQuestions = async (obj) => {
    setShowQuestionsSpinner(true);
    console.log("result_show_questions pre", obj._id);
    let reqObj = {
      assessment_id: obj._id,
    };
    let url = config.assessmentUrl + "/get-assessment-questions";
    let result = await axios.post(url, reqObj);

    setTitle(obj.title);
    setQuestionsCount(obj.questions_count);
    if (result.length !== 0) {
      setShowQuestionsSpinner(false);
    }
    //-----------------------------setting and sorting options for filters------------------------------------
    // const method = new Set();
    // const blooms = new Set();
    // const difficultyLevel = new Set();
    // const questype = new Set();
    // result.data.assessment_question.map((item, i) => {
    //   method.add(item.methods)
    //   blooms.add(item.bloomLevel)
    //   difficultyLevel.add(item.difficultyLevel)
    //   questype.add(item.type)
    // })
    const method = new Set();
    const blooms = new Set();
    const difficultyLevel = new Set();
    const questype = new Set();

    // Check if result and its properties exist before mapping
    if (result && result.data && result.data.assessment_question) {
      result.data.assessment_question.map((item, i) => {
        method.add(item.methods);
        blooms.add(item.bloomLevel);
        difficultyLevel.add(item.difficultyLevel);
        questype.add(item.type);
      });
    } else {
      console.error(
        "Data structure is not as expected or data is undefined/null"
      );
    }

    // let arrD = ["easy", "medium", "hard"];
    // let arrM = ["conceptual", "numerical"];
    // let arrT = ["mcq", "mcms", "subjective", "fill in the blanks"];
    // let arrB = [
    //   "recall",
    //   "understand",
    //   "apply",
    //   "analyze",
    //   "evaluate",
    //   "create",
    // ];

    let arrD = ["easy", "medium", "hard"].map((item) => t(`difficulty.${item}`));
    let arrM = ["conceptual", "numerical"].map((item) => t(`mode.${item}`));
    let arrT = ["mcq", "mcms", "subjective", "fill in the blanks"].map((item) => t(`type.${item}`));
    let arrB = ["recall", "understand", "apply", "analyze", "evaluate", "create"].map((item) => t(`bloom.${item}`));

    let arr = Array.from(blooms);
    arr.sort((a, b) => {
      const priorityA = arrB.indexOf(a);
      const priorityB = arrB.indexOf(b);
      return priorityA - priorityB;
    });

    setbloom(arr);
    arr = Array.from(method);
    arr.sort((a, b) => {
      const priorityA = arrM.indexOf(a);
      const priorityB = arrM.indexOf(b);
      return priorityA - priorityB;
    });
    setmethod(arr);
    arr = Array.from(questype);
    arr.sort((a, b) => {
      const priorityA = arrT.indexOf(a);
      const priorityB = arrT.indexOf(b);
      return priorityA - priorityB;
    });
    setquestype(arr);
    arr = Array.from(difficultyLevel);
    arr.sort((a, b) => {
      const priorityA = arrD.indexOf(a);
      const priorityB = arrD.indexOf(b);
      return priorityA - priorityB;
    });
    setdifficultyLevel(arr);

    //--------------------------------------------------sorting options end----------------------------------

    setTotalQues(
      getDifference(result.data.assessment_question, alreadySelectedQuestions)
        ?.length
    );
    setorgTotalQues(
      getDifference(result.data.assessment_question, alreadySelectedQuestions)
        ?.length
    );
    setAllQuestions(
      getDifference(result.data.assessment_question, alreadySelectedQuestions)
    );
    setQuestionsForQuestionCard(
      getDifference(result.data.assessment_question, alreadySelectedQuestions)
    );
    setShowQuestionsSpinner(false);
  };

  const resetFilter = () => {
    setQuestionsForQuestionCard(allQuestions);
    setTotalQues(allQuestions.length);

    document
      .getElementById("level_val")
      .getElementsByTagName("option")[0].selected = "selected";
    document
      .getElementById("method_val")
      .getElementsByTagName("option")[0].selected = "selected";
    document
      .getElementById("type_val")
      .getElementsByTagName("option")[0].selected = "selected";
    document
      .getElementById("bloom_val")
      .getElementsByTagName("option")[0].selected = "selected";
  };

  const openSuccessModal = () => {
    return (
      <>
        <Modal
          isOpen={showModal}
          className="modal-dialog modal-dialog-centered"
        >
          <ModalBody className="modalLogout">
            <div className="modalImg">
              <img src={filter} />
            </div>
            <div className="text-center">
              <h3> {t("assessment.question_added_success")}</h3>
            </div>
          </ModalBody>
          <ModalFooter className="logoutFooter">
            <div className="uk-text-right" style={{ padding: "0px 10px" }}>
              <button
                className="btn btn-primary btn-rounded mx-1"
                onClick={() => {
                  GoBack();
                  setsubmitSpinner(false);
                }}
              >
                Ok
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  };

  const newsortedGradeList = [];
  props.location.state.gradeListArr.map((grade) => {
    newsortedGradeList.push(grade.value);
    return newsortedGradeList.sort((a, b) => {
      return a - b;
    });
  });

  const sortedGradeList =
    user.board_name.toUpperCase() !== "IB"
      ? newsortedGradeList.filter((word) => !word.includes("MYP"))
      : newsortedGradeList.filter((word) => word.includes("MYP"));

  return (
    <>
      {" "}
      <div className="back-quiz-mob-search">
        <button onClick={GoBack} className="quize_edit">
          <icon class="icon-back-arrow"></icon>
          {t("assessment.back")}
        </button>
        <div
          className="quiz-mob-search"
          style={{ marginRight: "10px" }}
          onClick={() => toggle_1()}
        >
          <img src={searchIcon} />
        </div>
      </div>
      <div className="add_from_qb">
        <div id="topic_search" className={isSmallScreen ? "left-side-mob" : "left-side"}>
          <div className="left-title">
            <button onClick={GoBack} className="quize_edit">
              <icon class="icon-back-arrow"></icon>
              {t("assessment.back")}
            </button>{" "}
            <h1>{t("assessment.testing_search")} </h1>
            <div className="search-box">
              <input
                onChange={(e) => searching(e.target.value)}
                className="test-search"
                placeholder="search"
                id="inputField"
              />
              <span>
                <img src={searchIcon} alt="" />
              </span>
            </div>
          </div>

          <div style={{ padding: "5px 13px", maxHeight: "15%" }}>
            <span>
              <img src={filter} alt="" width="30" height="auto" />{" "}
              {t("assessment.filter_by")}:
            </span>
            <select
              className="singleButtonContainer"
              id="grade_val"
              onChange={filteringGrade}
            >
              {sortedGradeList.map((grade) => (
                <>
                  {" "}
                  {props.location.state.grade == grade ? (
                    <option value={grade} selected>
                      {grade}
                    </option>
                  ) : (
                    <option value={grade}>{grade}</option>
                  )}
                </>
              ))}
            </select>
            {/* <select className="singleButtonContainer" id='subject_val' onChange={filteringGrade}>

              {props.location.state.subjectListArr.map((sub) => (
                <>{sub.subject_name == props.location.state.subject ?
                  <option value={sub.subject_name} selected>{sub.subject_name}</option> :
                  <option value={sub.subject_name}>{sub.subject_name}</option>}
                </>
              ))}


            </select> */}
            <select
              className="singleButtonContainer"
              id="subject_val"
              onChange={filteringGrade}
            >
              {props.location.state.subjectListArr.map((sub) => (
                <option
                  key={sub.subject_name}
                  value={sub.subject_name}
                  selected={sub.subject_name === props.location.state.subject}
                >
                  {t(`subjects.${sub.subject_name.toLowerCase()}`)}
                </option>
              ))}
            </select>
          </div>
          <div style={{ "max-height": "55%", overflow: "scroll" }}>
            <ul className="search-result" id="searchRes">
              {isSpinner === true ? (
                <div>
                  <div className="container create_quize_main_loader_bg   my-2 d-f-c">
                    <Spinner></Spinner>
                  </div>
                </div>
              ) : searchItem.length > 0 && isSpinner === false ? (
                searchItem.map((data, index) => (
                  <li
                    className="search-result-box"
                    key={index}
                    onClick={() => {
                      toggle_2();
                      showQuestions(data);
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-start">
                      <p>
                        {data.title}{" "}
                        <span>
                          {data.questions_count} {t("assessment.questions")}
                        </span>
                      </p>
                      <span className="badge badge-primary">
                        {data.grade} {t("assessment.grade")}
                      </span>
                    </div>
                  </li>
                ))
              ) : (
                <div
                  style={{
                    width: "100%",
                    marginTop: "60px",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{
                      marginTop: "25px",
                      width: "250px",
                      height: "280px",
                    }}
                    src={nocontent2}
                  />
                  <h3
                    style={{
                      marginTop: "25px",
                      fontSize: "24px",
                      fontWeight: "700",
                    }}
                  >
                    {t("assessment.no_result_found")}
                  </h3>
                  <p
                    style={{
                      fontSize: "15px",
                      marginLeft: "50px",
                      marginRight: "50px",
                    }}
                  >
                    {t("assessment.we_are_sorry")}
                  </p>
                </div>
              )}
            </ul>
          </div>

          {openSuccessModal()}
        </div>

        {showQuestionsSpinner ? (
          <div className="container create_quize_main_loader_bg my-2 d-f-c">
            <Spinner></Spinner>
          </div>
        ) : (
          <>
            {orgtotalQues === 0 ? (
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  textAlign: "center",
                  marginTop: "0",
                }}
              >
                <img
                  style={{ marginTop: "200px", height: "400px" }}
                  src={nocontent1}
                  alt=""
                />
                <p style={{ marginTop: "25px", fontSize: "17.72px" }}>
                  {t("assessment.select_to_preview")}
                </p>
              </div>
            ) : (
              <div className="right-side">
                <div
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#fff",
                    zIndex: 1,
                  }}
                >
                  <div className="question-header">
                    <div
                      className="d-flex"
                      style={{
                        flexDirection: "column",
                        color: "#767676",
                        fontSize: "16px",
                        display: "block",
                        margin: "5px",
                      }}
                    >
                      <h1>{title}</h1>
                      {orgtotalQues == 1 || orgtotalQues == 0 ? (
                        <div>
                          <span style={{ fontSize: "14px" }}>
                            {orgtotalQues} {t("assessment.question_available")}{" "}
                            &nbsp;
                          </span>{" "}
                          {questionsCount - orgtotalQues == 0 ? (
                            <></>
                          ) : (
                            <span
                              style={{
                                backgroundColor: "#FFEEE5",
                                borderRadius: "4px",
                                paddingLeft: "8px",
                                paddingTop: "2px",
                                paddingBottom: "1px",
                                paddingRight: "8px",
                                color: "#FC8643",
                                fontSize: "12.44px",
                                lineHeight: "18.66px",
                              }}
                            >
                              {questionsCount - orgtotalQues}{" "}
                              {t("assessment.already_added")}
                            </span>
                          )}
                        </div>
                      ) : (
                        <div>
                          <span style={{ fontSize: "14px" }}>
                            {orgtotalQues} {t("assessment.questions_available")}{" "}
                            {showavai ? <br /> : null}
                            &nbsp;
                          </span>
                          {questionsCount - orgtotalQues == 0 ? (
                            <></>
                          ) : (
                            <span
                              style={{
                                backgroundColor: "#FFEEE5",
                                borderRadius: "4px",
                                paddingLeft: "6px",
                                paddingRight: "6px",
                                paddingTop: "2px",
                                paddingBottom: "1px",
                                color: "#FC8643",
                                fontSize: "12.44px",
                                lineHeight: "18.66px",
                              }}
                            >
                              {questionsCount - orgtotalQues}{" "}
                              {t("assessment.already_added")}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                    <button
                      style={{ marginTop: "10px" }}
                      onClick={() => addAllQuestions(questionsForQuestionCard)}
                    >
                      <img src={plusIcon} /> {t("assessment.add_all_questions")}{" "}
                    </button>
                  </div>

                  {!showQuestionsSpinner && (
                    <div className="ques-filter">
                      <div
                        className="filterText"
                        onClick={() => {
                          setShowhamburger(!showhamburger);
                        }}
                      >
                        <p>
                          <img src={filter} alt="" width="30" height="auto" />{" "}
                          {t("assessment.filter_by")}:
                        </p>
                      </div>
                      <div className={`${showhamburger ? "show" : "hide"}`}>
                        <select
                          className="singleButtonContainer"
                          id="level_val"
                          onChange={() => applyFilters()}
                        >
                          <option disabled selected value="">
                            {t("assessment.difficulty_level")}
                          </option>
                          {difficultyLevel.map((val) => {
                            return <option value={val}>{caps(val)}</option>;
                          })}
                        </select>
                        <select
                          className="singleButtonContainer"
                          id="method_val"
                          onChange={() => applyFilters()}
                        >
                          <option value="" disabled selected>
                            {t("assessment.method")}
                          </option>
                          {method.map((value) => (
                            <option value={value}>{caps(value)}</option>
                          ))}
                        </select>
                        <select
                          className="singleButtonContainer"
                          id="type_val"
                          onChange={() => applyFilters()}
                        >
                          <option disabled selected value="">
                            {t("assessment.type")}
                          </option>
                          {questype.map((val, i) => {
                            return <option value={val}>{caps(val)}</option>;
                          })}
                        </select>
                        <select
                          className="singleButtonContainer"
                          id="bloom_val"
                          onChange={() => applyFilters()}
                        >
                          <option value="" disabled selected>
                            {t("assessment.bloom")}
                          </option>
                          {bloom.map((val, i) => {
                            return <option value={val}>{caps(val)}</option>;
                          })}
                        </select>
                        <button
                          className="reset-btn"
                          onClick={() => resetFilter()}
                        >
                          {t("assessment.reset_filter")}
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {!showModal && (
                  <div
                    className="question-footer"
                    style={{ zIndex: "10001", opacity: "0.9" }}
                  >
                    <div>
                      {selectedQuestions.length == 0 ? (
                        <h3>{t("assessment.no_question_added")} </h3>
                      ) : selectedQuestions.length == 1 ? (
                        <h3>1 {t("assessment.question_added")} </h3>
                      ) : (
                        <h3>
                          {selectedQuestions.length}{" "}
                          {t("assessment.question_added")}{" "}
                        </h3>
                      )}

                      {selectedQuestions.map((que) => {
                        if (que.difficultyLevel === "easy") {
                          easy++;
                        } else if (que.difficultyLevel === "medium") {
                          medium++;
                        } else {
                          hard++;
                        }
                      })}
                      {selectedQuestions.length > 0 ? (
                        <p>
                          <span>
                            {" "}
                            {t("assessment.easy")}
                            {Math.round(
                              (100 * easy) / selectedQuestions.length
                            )}
                            % <strong> | </strong>
                          </span>
                          <span>
                            {" "}
                            {t("assessment.medium")}{" "}
                            {Math.round(
                              (100 * medium) / selectedQuestions.length
                            )}
                            % <strong> | </strong>
                          </span>
                          <span>
                            {" "}
                            {t("assessment.hard")}
                            {Math.round(
                              (100 * hard) / selectedQuestions.length
                            )}
                            %{" "}
                          </span>
                        </p>
                      ) : (
                        <p></p>
                      )}
                    </div>

                    {submitSpinner == false ? (
                      <button
                        className="import-btn"
                        onClick={() => {
                          if (selectedQuestions.length > 0) {
                            setsubmitSpinner(true);
                            addBulkQuestions();
                          }
                        }}
                      >
                        {t("assessment.submit")}
                      </button>
                    ) : (
                      <button className="import-btn">
                        <Spinner variant="light" animation="border" size="sm" />
                      </button>
                    )}
                  </div>
                )}

                {setQuestionsForQuestionCard && (
                  <ol
                    style={{
                      marginBottom: "150px",
                      padding: "0",
                      position: "relative",
                      zIndex: 0,
                    }}
                  >
                    {totalQues == 0 ? (
                      <div
                        style={{
                          width: "100%",
                          marginTop: "60px",
                          textAlign: "center",
                        }}
                      >
                        <img
                          style={{ marginTop: "25px", height: "200px" }}
                          src={nocontent3}
                          alt=""
                        />
                        <h3
                          style={{
                            marginTop: "25px",
                            fontSize: "24px",
                            fontWeight: "700",
                          }}
                        >
                          {t("assessment.no_questions_found")}
                        </h3>
                        <p
                          style={{
                            fontSize: "15px",
                            marginLeft: "50px",
                            marginRight: "50px",
                          }}
                        >
                          {" "}
                          {t("assessment.reset_to_preview")}
                        </p>
                      </div>
                    ) : (
                      questionsForQuestionCard.map((ques, index) => (
                        <li key={index}>
                          <CustomQuestionCard
                            index={index}
                            ques={ques}
                            selectedQuestions={selectedQuestions}
                            addQuestion={addQuestion}
                            removeQuestion={removeQuestion}
                          />
                        </li>
                      ))
                    )}
                  </ol>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default QuizTeleporting;
