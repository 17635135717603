import React, { useState } from "react"
import { useEffect } from "react";
import downArrow from '../../../../../../assets/images/down_arrow_b2b.png'
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
function SectionFilter(props) {
    // debugger
    const [classNameList, setClassNameList] = useState(props.classNameList);
    const [sectionNameList, setSectionNameList] = useState(props.sectionNameList);
    const [isClassSelected,setIsClassSelected]=useState(false);
    const [className, setClassName] = useState('');
    const [sectionName, setSectionName] = useState('');
    const [sectionList,setSectionList]=useState([]);
    const {addToast}=useToasts();
    const {t} = useTranslation();
    const appyFilter = () => {
        // debugger
        if (!className) {
            return;
        }
        if (!sectionName) {
            return;
        }
        props.onApplyFilterClick("section", className, '', sectionName);
    }

    useEffect(() => {
        if (props.filterInUse !== 'section_filter') {
            setClassName('');
            setSectionName('')
            setIsClassSelected(false);
        }
    }, [props.filterInUse])

    let styling={
        post:{
            backgroundColor: isClassSelected ? "orange":""
        }
    }

    const handleClassCheck=()=>{
        if(!isClassSelected){
            addToast("Please select class first", {
                appearance: "warning",
                autoDismissTimeout: 2000,
              });
        }
    }

    const handleGradechange=(e)=>{
        if(e === ""){
            setIsClassSelected(false);
        }
        else {
            if(e === className){
                setIsClassSelected(false);
                setSectionName("");
            }
            else{
                setClassName(e);
        
                let list=classNameList.map((d,i)=>{
                    if(e===d.grade_id){
                        setSectionList(d.batches);
                    }
                    return d;
                })
                setSectionName("");
                setIsClassSelected(true)
            }
            
        }
        
        
        
    }
    return (
        <>
            <div className="col-md-3">
                <div className="form_elm">
                    <div className="form-group select_btn_wrap">
                        <label htmlFor="grade" className="select_btn" style={{backgroundColor:"orange"}}>
                            <img src={downArrow} alt="down_arrow" />
                        </label>
                        <select
                            className="form-control"
                            name=""
                            value={className}
                            onChange={(e) => {  handleGradechange(e.target.value); props.setFilterInUse('section_filter') }}>
                            <option value="" disabled>{t("dashboard.select_class")}</option>
                            {
                                classNameList.map((e, i) => {
                                    return (<option value={e.grade_id} key={i}>{t("super_admin_dashboard.class")} {e.grade_name}</option>)
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="form_elm">
                    <div className="form-group select_btn_wrap" onClick={handleClassCheck}>
                        <label htmlFor="grade" className="select_btn" style={styling.post}>
                            <img src={downArrow} alt="down_arrow" />
                        </label>
                        <select
                            className="form-control"
                            name=""
                            value={sectionName}
                            disabled={!isClassSelected}
                            onChange={(e) => { setSectionName(e.target.value); console.log("target section name",e.target.value); props.setFilterInUse('section_filter') }}>
                            <option value="" disabled>{t("dashboard.select_section")}</option>
                            {
                                sectionList.map((e, i) => {
                                    return (<option value={e.batch_name} key={i}>{e.batch_name}</option>)
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="apply_filter_button" onClick={() => { appyFilter() }}>{t("dashboard.apply_filter")}</div>
            </div>
        </>
    )
}
export default SectionFilter