
import SchoolFilter from "./school_filter";
import ClassWiseFilter from "./class_wise_filter";

import React, { useState } from "react"


function Filters(props) {
    const [isFilterOpen, setIsFilterOpen] = useState(false)

    const [filterInUse, setFilterInUse] = useState('')
    console.log("this is filter log", props);
    return (
        <>
            <div className="filter_section">
                <div className="wrapper_for_page_padding">
                    <div className="row">
                        <div className="col-md-4">
                            <SchoolFilter
                                schoolList={props.schoolList}
                                setdisplayedClass={props.setdisplayedClass}
                                setSelectedSchools={props.setSelectedSchools}
                                selectedSchools={props.selectedSchools}
                                setDashboardType = {props.setDashboardType}
                                />
                        </div>
                        <div className="col-md-4">
                            <ClassWiseFilter
                                boardWiseClasses={props.boardWiseClasses}
                                classList={props.classList}
                                displayedClass={props.displayedClass}
                                setdisplayedClass={props.setdisplayedClass}
                                setSelectedGradeId={props.setSelectedGradeId}
                                setSelectedSubjectId={props.setSelectedSubjectId}
                                setSelectedSubject={props.setSelectedSubject}
                                setSelectedClassSubjects={props.setSelectedClassSubjects}
                                setSelectedBoard={props.setSelectedBoard}
                                setSelectedGrade={props.setSelectedGrade}
                                selectedSchools={props.selectedSchools}
                                setDashboardType = {props.setDashboardType}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
} export default Filters