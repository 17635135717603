import ChapterListing from "components/common/chapter_listing";
import Loader from "components/common/loader";
import StudentListing from "components/common/student_listing";
import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

import DefaultDashboardComponent from "pages/PrincipalDashboard/newDashboard/component/principle/default_dashboard_component";
import ChapterReportingDashboard from "pages/PrincipalDashboard/newDashboard/component/principle/chapter_reporting_dashboard";
import ProfileDashboardComponent from "pages/PrincipalDashboard/newDashboard/component/principle/profile_dashboard_component";
import ReportingDashboardComponent from "pages/PrincipalDashboard/newDashboard/component/principle/reporting_dashboard_component";
import TopicrReportingDashboard from "pages/PrincipalDashboard/newDashboard/component/principle/topic_reporting_dashboard";
import {useTranslation} from "react-i18next";
import store from "store/store";
import ChapterAnalysis from "pages/PrincipalDashboard/newDashboard/component/principle/chapter_analysis";
import CustomCount from "components/common/CustomCount";

function SuperAdminComponent(props) {

    const [className, setClassName] = useState(props.className)
    const [subjectName, setSubjectName] = useState(props.subjectName)
    const [TeacherAnalysisTabs, setTeacherAnalysisTabs] = useState("1")
    const [studentList, setStudentList] = useState([])

    const [userId, setUserId] = useState('')
    const [chapterId, setChapterId] = useState('')
    const [topicId, setTopicId] = useState('')
    const [dashboardType, setDashboardType] = useState("")

    //user 
    const [userPerformanceGraph, setUserPerformance_graph] = useState([])
    //const [userChapterTopicPerformance, setUserChapterTopicPerformance] = useState([])
    const [userDetails, setUserDetails] = useState([])
    const [userOverview, setUserOverview] = useState([])

    const [performanceGraph, setPerformanceGraph] = useState([])
    const [princilpleAllSubject, setPrincilpleAllSubject]=useState([])
    const [princilpleAllBatch, setPrincilpleAllBatch]=useState([])


    const [chapterTopicPerformance, setChapterTopicPerformance] = useState([])
    const [chapterWisePerformance, setChapterWisePerformance] = useState([])
    const [studentCount,setStudentCount] = useState(0)

    const [showLoader, setShowLoader] = useState(true)
    const [insightData, setInsightData] = useState([])

    const {t} = useTranslation()

    if (className !== props.className) {
        
        setUserId('')
        setDashboardType('')
        setClassName(props.className)
    }
    if (subjectName !== props.subjectName) {
        setUserId('')
        setDashboardType('')
        setSubjectName(props.subjectName)
    }

    const getDashboardData = async () => {
        setShowLoader(true)
        setPerformanceGraph([])
        setStudentList([])
        setChapterTopicPerformance([])        
        var { user } = store.getState(); 
        const res = await fetch(
            'https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/grade-subjectwise-analysis?school_id=' + props.schoolId +"&language="+user.lang_pref + '&board_id=' + props.boardName + '&grade_id=' + encodeURIComponent(props.classId) + '&subject_id=' + props.subjectId + '&student_count=' + 100+ '&page=' + 1
        );
        const data = await res.json()
        if (data.statusCode === 200) {
            console.log("dashboard data",data)
            setPerformanceGraph(data.performance_graph)
            setStudentCount(data)
            setPrincilpleAllSubject(data.subjectwise_data)
            setPrincilpleAllBatch(data.Batchwise_data)
            setStudentList(data.student_list)
            setChapterTopicPerformance(data.chapter_topic_performance)
            loadChapterTopicListing()
        }
    }


    useEffect(() => {
        setShowLoader(true)
        getDashboardData()
        setTeacherAnalysisTabs("1")
    }, [subjectName, className])


   
    

    const setDashboardData = (type, user_id) => {

        setDashboardType(type)
        switch (type) {
            case "":
                setUserId(user_id)
                break;
            case "report":
                setReportDashboard(user_id);
                setUserId(user_id)
                break;
            case "profile":
                setProfileDashboard();
                setReportDashboard(user_id)
                setUserId(user_id)
                break;
            case "chapter_report":
                setChapterReportingDashboard(user_id);
                setChapterId(user_id)
                break;
        }
    }

    const setChapterReportingDashboard = async (chapter_id) => {
        setShowLoader(true)

        const {user} = store.getState()
        setInsightData([])
        const res = await fetch(
            `https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/insights?language=${user.lang_pref}&school_id=${props.schoolId}&board_id=${props.boardName}&grade_id=${encodeURIComponent(props.classId)}&subject_id=${props.subjectId}&chapter_id=${chapter_id}`
        )
        const data = await res.json()
        if (data.statusCode === 200) {
            setInsightData(data.insights)
            setShowLoader(false)
        }

    }

    console.log("insightData",insightData)
    const setDashboardDataForTopic = (type, chapter_id, topic_id) => {

        setChapterId(chapter_id)
        setTopicId(topic_id)
        setDashboardType(type)
    }

    
    const setReportDashboard = async (user_id) => {
        setShowLoader(true)
        const {user} = store.getState()
        const res = await fetch(
            'https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/users-report-analysis?school_id=' + props.schoolId + "&language="+ user.lang_pref +'&board_id=' + props.boardName + '&grade_id=' + encodeURIComponent(props.classId) + '&subject_id=' + props.subjectId + '&user_id=' + user_id
        );
        const data = await res.json()
        if (data.statusCode === 200) {
            setUserPerformance_graph(data.performance_graph)
            //setUserChapterTopicPerformance(data.chapter_topic_performance)
            setUserDetails(data.userDetails)
            setUserOverview(data.overview)
            setShowLoader(false)
        }


        //https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/users-report-analysis?school_id=1575&board_id=5e8c428336bf63c0c1f65726&grade_id=5e8c41468103e188b8003c57&subject_id=5e8c4b1cf27af26377eae43c&user_id=3869441
    }
    const setProfileDashboard = () => {

    }

    const onTabSelect = async (k) => {
    
        const {user} = store.getState()
        if (parseInt(k) === 2) {
          setInsightData([])
          setDashboardType("chapter_analysis")
          const res = await fetch(
            `https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/insights?language=${user.lang_pref}&school_id=${props.schoolId}&board_id=${props.boardName}&grade_id=${encodeURIComponent(props.classId)}&subject_id=${props.subjectId}&batch=${props.selectedGrade}`
          )
          const data = await res.json()
          if (data.statusCode === 200) {
            setInsightData(data.insights)
          }
        } else {
          setDashboardType("")
          setInsightData([])
        }
        setTeacherAnalysisTabs(k)
        setChapterId("")
        setTopicId("")
        setUserId("")
      }



    const loadChapterTopicListing = async () => {
        setShowLoader(true)
        
        var { user } = store.getState();
        const res = await fetch(
            `https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/teacher-chapter-topic-analysis?language=${user.lang_pref}&school_id=${props.schoolId}&board_id=${props.boardName}&grade_id=${encodeURIComponent(props.classId)}&subject_id=${props.subjectId}`
        );
        const data = await res.json()
        if (data.statusCode === 200) {
            setChapterWisePerformance([data.chapter_topic_details])
            setShowLoader(false)
        }
    }


    const handleSubjectSelection = (subId, subName) => {
        props.onApplyFilterClick(subId, subName)
        setDashboardData('', '')

    }



    return (
        <>
            <div className="principle_component">
                <div className="principle_component_heading">   {t("dashboard.showing_result_for_class")} - {className} {subjectName === "all" ? t("dashboard.all_subjects") : t(`subjects.${subjectName}`) }</div>
                <div className="classListWrapper">
                    {
                       <>
                       <div className={`classListItem ${props.subjectName === "all" ? 'active' : ''}`} onClick={() => { handleSubjectSelection("all", "all") }}>{t("super_admin_dashboard.all_subject")}</div>
                       {props.subjectList.map((val, i) => {
                           return (<div className={`classListItem ${props.subjectName === val.subject ? 'active' : ''}`} key={i} onClick={() => { handleSubjectSelection(val.subjectId, val.subject) }}>{t(`subjects.${val.subject}`)}</div>)
                       })}
                       </>
                    }
                </div>
                <div className='container3'>
                        <CustomCount
                            headerText={studentCount?.total_users}
                            subHeader={t("super_admin_dashboard.total_students")}
                            backgroundColor='#40A3F3'
                            id='Total_student'
                            studentCount={studentCount}
                        />
                        <CustomCount
                            headerText={studentCount?.active_users_count}
                            subHeader={t("super_admin_dashboard.active_students")}
                            backgroundColor='#1DD165'
                            id='active_student'
                            studentCount={studentCount}
                        />
                        <CustomCount
                            headerText={studentCount?.inactive_users_count}
                            subHeader={t("super_admin_dashboard.inactive_students")}
                            backgroundColor='#F06D6C'
                            id='inactive_student'
                            studentCount={studentCount}
                        />
                    </div>
                <div className="principle_component_wrapper" style={{ paddingTop: "20px" }}>
                    <div className="row">
                        <div className="col-md-12 col-lg-4 listing_tab">
                            <Tabs
                                activeKey={TeacherAnalysisTabs}
                                onSelect={(k) => onTabSelect(k)}
                                className="custom_student_progress_graph">
                                <Tab eventKey="1" title={t("super_admin_dashboard.student_wise_analysis")}
                                className="custom_tab_content teacher_student_listing" 
                                >
                                    <StudentListing classId={props.classId} subjectId={props.subjectId} setDashboardData={setDashboardData} studentList={studentList} studentListLoading={props.studentListLoading} dashboardType={dashboardType} userId={userId} />
                                </Tab>
                               {props.subjectName !=="all"  && <Tab eventKey="2" title={t("dashboard.chapter_wise_analysis")} className="custom_tab_content teacher_student_listing">
                                    <ChapterListing onTabSelect= {onTabSelect} chapterTopicPerformance={chapterTopicPerformance} setDashboardData={setDashboardData} setDashboardDataForTopic={setDashboardDataForTopic} chapterId={chapterId} topicId={topicId} />
                                </Tab>
                                }
                            </Tabs>
                        </div>
                        <div className="col-md-12 col-lg-8">

                            {dashboardType === "" ? <DefaultDashboardComponent className={className} subjectName={subjectName} performanceGraph={performanceGraph} princilpleAllSubject={princilpleAllSubject}  princilpleAllBatch={princilpleAllBatch} chapterTopicPerformance={chapterTopicPerformance} selectedBatch={props.selectedGrade} gradeName={props.className} /> : ""}

                             {dashboardType === "report" ? <ReportingDashboardComponent school_id={props.schoolId} className={className} subjectName={subjectName} userPerformanceGraph={userPerformanceGraph} userDetails={userDetails} showLoader={showLoader} selectedBatch={props.selectedGrade} selectedSubjectId={props.subjectId} selectedGradeId={props.classId} /> : ""}

                            {dashboardType === "profile" ? <ProfileDashboardComponent school_id={props.schoolId} className={className} subjectName={subjectName} studentList={studentList} userId={userId} showLoader={showLoader} userPerformanceGraph={userPerformanceGraph} userOverview={userOverview} selectedBatch={props.selectedGrade} userDetails={userDetails} selectedGradeId={props.classId} selectedSubjectId={props.subjectId}/> : ""}


                            {dashboardType === "chapter_analysis" ? (
                    <ChapterAnalysis
                      className={props.className}
                      subjectName={props.subjectName}
                      performanceGraph={performanceGraph}
                      chapterTopicPerformance={chapterTopicPerformance}
                      insightData={insightData}
                      selectedBatch={props.selectedGrade}
                    />
                  ) : (
                    ""
                  )}
                            {dashboardType === "chapter_report" ? <ChapterReportingDashboard className={className} chapterTopicPerformance={chapterTopicPerformance} chapterId={chapterId} chapterWisePerformance={chapterWisePerformance} insightData={insightData} selectedBatch={props.selectedGrade} /> : ""}

                            {dashboardType === "topic_report" ? <TopicrReportingDashboard chapterTopicPerformance={chapterTopicPerformance} chapterId={chapterId} topicId={topicId} chapterWisePerformance={chapterWisePerformance} selectedBatch={props.selectedGrade} /> : ""} 
                        </div>
                    </div>

                </div>
                {showLoader ? <Loader showLoader={showLoader} /> : ""}
            </div>
        </>
    )
}
export default SuperAdminComponent