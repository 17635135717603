import axios from "axios";
import Config from "../config";

const loginV2 = (email, password) => {
  const username = email;
  return axios({
    method: "post",
    //url: (Config.url || '') + "/api/login",
    url: (Config.url || "") + "/api/login-v2",
    // url: (Config.url || "") + "/api/login-v3",
    data: { email, password, username },
  });
};

export default loginV2;
