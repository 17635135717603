import config from "config";
import AppLoader from "elements/AppLoader";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import OverallInsight from "./components/overall_insight";
import Filters from "./components/filters";
import SuperAdminComponent from "./components/new_components/SuperAdminComponent";

const mapStateToProps = (state) => {
  const user = state.user;
  return {
    isPace: false,
    role: "superadmin",
    schoolChainId: user?.school?.schoolChainId,
    preSelectedSchools: [],
  };
};

function SuperAdminDashboard(props) {
  const [selectedGradeId, setSelectedGradeId] = useState("")
  const [selectedGrade, setSelectedGrade] = useState("")
  const [loader, setLoader] = useState(true)
  const [schoolChainId, setSchoolChainId] = useState('')
  const [schoolList, setSchoolList] = useState([])
  const [classList, setClassList] = useState([])
  const [overallInsightData, setOverallInsightData] = useState([])
  const [schoolListIds, setSchoolListIds] = useState([])
  const [selectedSchools, setSelectedSchools] = useState([])
  const [boardWiseClasses, setBoardWiseClasses] = useState([])
  const [selectedClassSubjects, setSelectedClassSubjects] = useState()
  const [selectedSubject, setSelectedSubject] = useState("")
  const [selectedSubjectId, setSelectedSubjectId] = useState("")
  const [displayedClass, setdisplayedClass] = useState("All Classes")
  const [dashboardType, setDashboardType] = useState("")
  const [selectedBoard, setSelectedBoard] = useState([])

  const {t} = useTranslation()

  useEffect(() => {
    setSchoolChainId(props.schoolChainId)

  }, [])
  useEffect(() => {
    if (schoolListIds.length > 0) {
      getOverallInsightData()
      getClasses()
    }
  }, [schoolListIds])



  useEffect(() => {
    if (selectedSchools.length > 0) {
      getOverallInsightData()
      getClasses()
    }
    else{
      schoolChainId !== '' && getSchools()
    }

  }, [selectedSchools, schoolChainId])


  
  const getSchools = async () => {

    if (schoolChainId !== '') {
      const res = await fetch(config.dashboardUrl + "/schools/list?schoolChainId=" + schoolChainId);
      const data = await res.json()
      setSchoolList(data)
      let list = []
      data.map((e, i) => {
        list.push(e.id)
      })
      setSchoolListIds(list)

    }

  }

  const getClasses = async () => {

    let schools_to_get_data = schoolListIds.join()

    if (selectedSchools.length > 0) {
      schools_to_get_data = selectedSchools.join()
    }
    const res = await fetch(`https://duknujr2a2.execute-api.ap-south-1.amazonaws.com/prod/super-admin-filterwise-analysis?school_id=${schools_to_get_data}`)
    const data = await res.json()

    setBoardWiseClasses(data.grade_subject_filter)
    let arr = []
    {
      data.grade_subject_filter.map((board, index) => {

        board.grades.map((grade, grade_index) => {
          if (grade.subjects.length > 0) {
            arr.push(`${board.board_name} ${grade.grade_name}`)
          }

        })
      })
    }
    setClassList(arr);

  }


  const onApplyFilterClick = (subId, subName) => {
    setSelectedSubjectId(subId)
    setSelectedSubject(subName)
    setDashboardType("SuperAdmin")
}

 

  const getOverallInsightData = async () => {
    let schools_to_get_data = schoolListIds.join()

    if (selectedSchools.length > 0) {
      schools_to_get_data = selectedSchools.join()
    }
    // debugger
    let url = `https://duknujr2a2.execute-api.ap-south-1.amazonaws.com/prod/super-admin-analysis?school_chain_id=${schoolChainId}&school_id=${schools_to_get_data}`
    const res = await fetch(url);
    const data = await res.json()
    if (data.statusCode === 200) {
      setOverallInsightData(data)
      setDashboardType("OverallInsight")
    }
    setLoader(false)
  }

  

  return loader ? (
    <AppLoader />
  ) : (
    <div id="CommonPageHolder" className="super_admin" style={{ background: "#fff" }}>
      <div className="container-fluid" style={{ marginTop: "20px" }}>
        <div className="school_name_subheader">{t("super_admin_dashboard.super_admin_dashboard_text")}</div>

        {<Filters
          schoolList={schoolList}
          setdisplayedClass={setdisplayedClass}
          setSelectedSchools={setSelectedSchools}
          selectedSchools={selectedSchools}
          boardWiseClasses={boardWiseClasses}
          classList={classList}
          displayedClass={displayedClass}
          setSelectedGradeId={setSelectedGradeId}
          setSelectedSubjectId={setSelectedSubjectId}
          setSelectedSubject={setSelectedSubject}
          setSelectedClassSubjects={setSelectedClassSubjects}
          setSelectedBoard={setSelectedBoard}
          setSelectedGrade={setSelectedGrade}
          setDashboardType = {setDashboardType}
          />
        }

        {dashboardType === "OverallInsight" || displayedClass === "All Classes" ? <OverallInsight setDashboardType = {setDashboardType} overallInsightData={overallInsightData} schoolChainId={schoolChainId} selectedSchools={selectedSchools} /> : ("")}
        {dashboardType === "SuperAdmin" && displayedClass !== "All Classes" ? <SuperAdminComponent  onApplyFilterClick = {onApplyFilterClick} setSelectedSubjectId={setSelectedSubjectId} setSelectedSubject={setSelectedSubject}  setDashboardType = {setDashboardType}  schoolId={selectedSchools} boardName={selectedBoard[0].board_name} classId={selectedGradeId} className={selectedGrade.grade_name} subjectId={selectedSubjectId} subjectName={selectedSubject} setSelectedGrade={setSelectedGrade} subjectList={selectedClassSubjects} /> : ("")}

      </div>
    </div>


  )

}
export default connect(mapStateToProps)(SuperAdminDashboard);