import Loader from "components/common/loader";
import React, { useEffect, useState } from "react"
import Filters from "./component/filters/filters";
import PrincipleComponent from "./component/principle/principle_component";
// import Loader from "../../component/common/loader";
// import Filters from "../../component/filters/filters"
// import PrincipleComponent from "../../component/principle/principle_component";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LandingComponent from "./component/principle/landing_component";
import UserProfileComponentForPriniciple from "./component/principle/user_profile_component";
import TeacherProfileComponentForPriniciple from "./component/principle/TeacherProfileComponentForPriniciple";

const mapStateToProps = (state) => {
    const user = state.user;

    return state.user
    // return {
    //     name: state.auth.user ? state.auth.user.fullName : 'Avinash',
    //     studentId: user.id || 2745794,
    //     gradeId: user.gradeId || "5e8c41468103e188b8003c57",
    //     boardId: user.boardId || "5e8c428336bf63c0c1f65726",
    //     boardName: user.board_name || "",
    //     grade: user.grade_name || 8,
    //     filter: user.filter || "day",
    //     param: user.param || "all",
    //     loggedInUser: state.user,
    // };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // login: (payload) => {
        //     dispatch(login(payload))
        // },
        // setData: (payload) => {
        //     dispatch(setData(payload))
        // },
        // clearData: () => {
        //     dispatch(clearData())
        // },
        // logout: () => {
        //     dispatch(logout())
        // }
    };
};

function PrincipleDashboard(props) {

    const [classNameList, setClassNameList] = useState([]);
    const [batchNameList, setBatchNameList] = useState([])
    const [batchList,setBatchList]=useState([]);
    const [subjectNameList, setsubjectNameList] = useState([]);
    const [sectionNameList, setsectionNameList] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState('')

    const [subjectList, setSubjectList] = useState([])

    const [filterName, setFilterName] = useState('');
    const [className, setClassName] = useState('');
    const [classId, setClassId] = useState('');
    const [subjectName, setSubjectName] = useState('');
    const [subjectId, setSubjectId] = useState('');
    const [sectionName, setSectionName] = useState('');

    const [studentSearchDetails, setStudentSearchDetails] = useState('');
    const [teacherSearchDetails, setTeacherSearchDetails] = useState('');
    const [teacherName, setTeacherName] = useState('');


    const [showLoader, setShowLoader] = useState(false)
    // if changed class name then reset subjectname and section name
    const [pageNumber, setPageNumber] = useState(1)
    const [studentListLoading, setStudentListLoading] = useState(false)

    const [selectedSubjectStudCount, setSelectedSubjectStudCount] = useState(0)

    const {t}=useTranslation();

    useEffect(() => {
        startApp()
    }, [])


    const startApp = async () => {
        setShowLoader(true)
        const res = await fetch(
            `https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/filterwise-analysis?school_id=${props.school_id}&board_id=${props.board_name}&user_id=0`
        );
        const data = await res.json()
        if (data.statusCode === 200) {
            setShowLoader(false)

            setClassNameList(data.grade_subject_filter)

            setBatchNameList(data.batches)
            //setSelectedGrade(data.batches[0].batch_name)
            //setSelectedGrade("DSB");
        }
    }


    const onApplyFilterClick = (filter_type, class_name, subject_name, section_name) => {
        // setFilterName('')
        //filter types
        /**
         * types of filters
         * student
         * teacher
         * section
         * subject
         */
        console.log("subject name in onApplyFilterClick",subject_name)
        console.log("section name-----",section_name);
        setFilterName('')

        // debugger

        switch (filter_type) {
            case "student":
                setStudentSearchDetails(class_name)
                studentFilter(class_name)
                break;
            case "teacher":
                setTeacherSearchDetails(class_name)
                teacherFilter(class_name)
                break;
            case "subject":
                // debugger
                setClassName(class_name)
                setSubjectName(subject_name)

                if (section_name !== '') {
                    setSectionName(section_name)
                } else {
                  //  let firstSecName = classNameList.find(e => e.grade_id === class_name).batches[0].batch_name
                  let firstSecName ="all"
                    setSectionName(firstSecName)
                }

                subjectFilter(class_name, subject_name)
                break;
            case "section":
                // debugger
                setClassName(class_name)
                if(subject_name === 'all'){
                    setSubjectName(subject_name)
                }
                let sub_id = ''
                if (subject_name !== '' && subject_name !== "all" ) {
                    sub_id = subject_name
                    setSubjectName(sub_id)
                }  else {
                  //  sub_id = classNameList.find(e => e.grade_id === class_name).subjects[0].subjectId
                    sub_id="all"
                    setSubjectName(sub_id)
                    setSubjectList(classNameList.find(e => e.grade_id === class_name).subjects)
                }  
                setSectionName(section_name)
                sectionFilter(class_name, sub_id)
                break;
                default:
                    return;
        }
        setTimeout(() => {
            setFilterName(filter_type)
        }, 1000)
    }

    console.log("studentSearchDetails_is",studentSearchDetails)

    const studentFilter = (student_name) => {
        // console.log(student_name)
    }

    const teacherFilter = (teacher_name) => {
        // console.log(teacher_name)
    }

    const sectionFilter = (class_id, subject_id) => {
        let subject_name=''
        let selected_subject_data=''
        console.log('subject_id',subject_id)
        setClassId(class_id);

        if(subject_id === 'all'){
            subject_id = "all"
            setSubjectId(subject_id);
            subject_name="all"
            setSubjectName(subject_name);
            let allStudentCount=[]
            selected_subject_data = classNameList.find(g => g.grade_id === class_id);
            console.log("selected_subject_data",selected_subject_data)
            setClassName(selected_subject_data.grade_name);
            setsubjectNameList(selected_subject_data.subjects);
            selected_subject_data.subjects.map((sub)=> {return allStudentCount.push(sub.StudentCount)})
            console.log("allStudentCount",allStudentCount)

           function countAllStudents(allStudentCount){
            let sum=0
            allStudentCount.forEach((item)=> sum+=item)
            return sum
           }
           setSelectedSubjectStudCount(countAllStudents(allStudentCount) ? countAllStudents(allStudentCount) : 20)
        } else {
        setSubjectId(subject_id);
         selected_subject_data = classNameList.find(g => g.grade_id === class_id);
        setClassName(selected_subject_data.grade_name);
        setsubjectNameList(selected_subject_data.subjects);
        console.log("running it")
        subject_name = selected_subject_data.subjects.find(g => g.subjectId == subject_id).subject;
        let teacher_class = selected_subject_data.subjects.find(g => g.subjectId == subject_id)
        console.log("teacher_class",teacher_class)
        setSelectedSubjectStudCount(teacher_class.StudentCount ? teacher_class.StudentCount : 20)
        
        setSubjectName(subject_name);
    }
    }
    const subjectFilter = async (class_id, subject_id) => {

        setClassId(class_id);
        setSubjectId(subject_id);

        let selected_subject_data = classNameList.find(g => g.grade_id === class_id);
        setBatchList(selected_subject_data.batches);
        setClassName(selected_subject_data.grade_name);
        setsubjectNameList(selected_subject_data.subjects);
        let subject_name = selected_subject_data.subjects.find(g => g.subjectId == subject_id).subject;
        setSubjectName(subject_name);
        let teacher_class = selected_subject_data.subjects.find(g => g.subjectId == subject_id)
        setSelectedSubjectStudCount(teacher_class.StudentCount ? teacher_class.StudentCount : 20)

    }
    const handleScroll = (e) => {

        const bottom = parseInt(e.target.scrollHeight - e.target.scrollTop) === parseInt(e.target.clientHeight);
        // console.log((e.target.scrollHeight - e.target.scrollTop), (e.target.clientHeight))

        if (bottom) {
            if (!studentListLoading) {
                setPageNumber((pageNumber + 1))
            }

        }
    }
    
    return (
        <>
            <div id="CommonPageHolder" style={{ background: "#fff" }}>
                <div className="container-fluid" style={{ marginTop: "20px" }}>
                    <div className="school_name_header">{props.school?.name}</div>
                    <div className="school_name_subheader"> {t("dashboard.school_administration_dashboard")}</div>

                    {classNameList.length > 0 && <Filters classNameList={classNameList} subjectNameList={subjectNameList} sectionNameList={sectionNameList} onApplyFilterClick={onApplyFilterClick} schoolId={props.school_id} batchNameList={batchNameList} setSelectedGrade={setSelectedGrade}/>}


                    {filterName === 'subject' || filterName === 'section' ? (<PrincipleComponent setSelectedSubjectStudCount={setSelectedSubjectStudCount} selectedSubjectStudCount={selectedSubjectStudCount} handleScroll={handleScroll} pageNumber={pageNumber} schoolId={props.school_id} boardName={props.board_name} classId={classId} className={className} subjectId={subjectId} subjectName={subjectName} subjectNameList={subjectNameList} studentListLoading={studentListLoading} setStudentListLoading={setStudentListLoading} batchNameList={batchList} selectedGrade={sectionName} setSelectedGrade={setSelectedGrade} subjectList={subjectList} filterName={filterName} setSubjectId={setSubjectId} onApplyFilterClick={onApplyFilterClick} />) : ("")}


                    {filterName === 'student' ? (<UserProfileComponentForPriniciple board_name={studentSearchDetails.board_name} grade_id={studentSearchDetails.grade_id} grade_name={studentSearchDetails.grade_name} school_id={studentSearchDetails.school_id} id={studentSearchDetails.user_id} selectedGrade={studentSearchDetails.user_batch} />) : ("")}


                    {filterName === 'teacher' ? (<TeacherProfileComponentForPriniciple board_name={teacherSearchDetails.board_name} grade_id={teacherSearchDetails.grade_id} grade_name={teacherSearchDetails.grade_name} school_id={teacherSearchDetails.school_id} id={teacherSearchDetails.user_id} teacher_name={teacherSearchDetails.full_name} />) : ("")}

                    <div className="header"></div>
                    {className === '' && filterName == "" ? <LandingComponent board_name ={props.board_name} schoolId={props.school_id} /> : ""}
                    <Loader showLoader={showLoader} />
                </div>
            </div>
        </>
    )
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PrincipleDashboard)
);
// export default connect(mapStateToProps, mapDispatchToProps)(PrincipleDashboard);
// export default PrincipleDashboard; 