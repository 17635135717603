import { ProgressBar } from "react-bootstrap";
import { useEffect, useState } from "react";
import React from "react"
import accuracy from "../../../../../assets/images/accuracy.png";
import efficiency from "../../../../../assets/images/efficiency.png";
import progress from "../../../../../assets/images/progress.png";
import total_score from "../../../../../assets/images/total_score.png";
import { useTranslation } from "react-i18next";
import store from "../../../../../store/store"
import { getUTFDecodedText } from "services/utility";

function ProfileDashboardComponent(props) {

    const [className, setClassName] = useState(props.className)
    const [subjectName, setSubjectName] = useState(props.subjectName)

    const [chapterWisePerformance, setChapterWisePerformance] = useState([])

    var { user } = store.getState();


    const { t } = useTranslation()    // debugger
    useEffect(() => {
        getUserChapterTopic();
    }, [props.userDetails])

    if (className !== props.className) {

        setClassName(props.className)
    }
    if (subjectName !== props.subjectName) {
        setSubjectName(props.subjectName)
    }

    const userData = props.studentList.find(e => e.user_id == props.userId)
    // debugger

    const onClickHandler = (e) => {

        const hiddenElement = e.currentTarget.parentElement.getElementsByClassName('collapse')
        e.target.className.indexOf("dd-arrow showing") > -1 ? e.target.classList.remove("showing") : e.target.classList.add("showing");
        for (var i = 0; i < hiddenElement.length; i++) {
            hiddenElement[i].className.indexOf("collapse show") > -1 ? hiddenElement[i].classList.remove("show") : hiddenElement[i].classList.add("show");
        }
    }


    const getUserChapterTopic = async () => {
        console.log(props.userDetails)
        if (props.userDetails.length !== 0) {
            const res = await fetch(
                `https://b7rd2d9uye.execute-api.ap-south-1.amazonaws.com/prod/chapter-topic-analysis?language=${user.lang_pref}&school_id=${props.school_id}&board_id=${props.userDetails.board_name}&grade_id=${encodeURIComponent(props.selectedGradeId)}&user_id=${props.userDetails.user_id}&subject_id=${props.selectedSubjectId}`
            );
            const data = await res.json()

            // debugger
            if (data.statusCode === 200) {
                setChapterWisePerformance(data.chapter_topic_details)
                // setDropdownLoading(false)
            }
        }

    }
    return (
        <>
            <div className="container-fluid" style={{ position: "relative" }}>
                <div className="profile_section_heading"><span>{userData.fullname !== '' && userData.fullname !== ' ' ? userData.fullname : userData.user_id}’s</span> {t("header.profile")}</div>
                <div className="profile_page_wrapper">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="scrore_section_heading">{t(`subjects.${subjectName}`)} {t("super_admin_dashboard.performance")}</div>
                            <div className="score_section">
                                <div className="icon_section">
                                    <div className="icon">
                                        <img src={total_score} />
                                        {getUTFDecodedText(t('dashboard.total_score_with_br'))}
                                    </div>
                                    <div className="dots">:</div>
                                    <div className="percentage">{isNaN(Math.floor(props.userPerformanceGraph[0]?.user_performance.total_score)) ? 0 : Math.floor(props.userPerformanceGraph[0]?.user_performance.total_score)}%</div>
                                </div>
                                <div className="icon_section">
                                    <div className="icon">
                                        <img src={accuracy} />
                                        {getUTFDecodedText(t('dashboard.overall_accuracy_with_br'))}
                                    </div>
                                    <div className="dots">:</div>
                                    <div className="percentage">{isNaN(Math.floor(props.userPerformanceGraph[0]?.user_performance.overall_accuracy)) ? 0 : Math.floor(props.userPerformanceGraph[0]?.user_performance.overall_accuracy)}%</div>
                                </div>
                                <div className="icon_section">
                                    <div className="icon">
                                        <img src={efficiency} />
                                        {getUTFDecodedText(t('dashboard.time_efficiency_with_br'))}
                                    </div>
                                    <div className="dots">:</div>
                                    <div className="percentage">{isNaN(Math.floor(props.userPerformanceGraph[0]?.user_performance.time_efficiency)) ? 0 : Math.floor(props.userPerformanceGraph[0]?.user_performance.time_efficiency)}%</div>
                                </div>
                                <div className="icon_section">
                                    <div className="icon">
                                        <img src={progress} />
                                        {t('dashboard.progress')}
                                    </div>
                                    <div className="dots">:</div>
                                    <div className="percentage">{isNaN(Math.floor(props.userPerformanceGraph[0]?.user_performance.progress)) ? 0 : Math.floor(props.userPerformanceGraph[0]?.user_performance.progress)}%</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="scrore_section_heading">{t("teacher_dashboard.overview")}</div>
                            <div className="score_section">
                                <div className="icon_section">
                                    <div className="">
                                    {getUTFDecodedText(t('dashboard.avg_time_spent_br'))}
                                    </div>
                                    <div className="dots">:</div>
                                    <div className="percentage" style={{ width: "170px" }}>{props.userOverview.avg_time_spend} {t("teacher_dashboard.minute_everyday")}</div>
                                </div>
                                <div className="icon_section">
                                    <div className="">
                                    {getUTFDecodedText(t('dashboard.currently_playing_br'))}
                                    </div>
                                    <div className="dots">:</div>
                                    <div className="percentage" style={{ width: "170px" }}>{props.userOverview.translated_currently_playing}</div>
                                </div>
                                <div className="icon_section">
                                    <div className="">
                                    {getUTFDecodedText(t('dashboard.strongest_topic_br'))}
                                    </div>
                                    <div className="dots">:</div>
                                    <div className="percentage" style={{ width: "170px" }}>{props.userOverview.translated_strongest_topic}</div>
                                </div>
                                <div className="icon_section">
                                    <div className="">
                                    {getUTFDecodedText(t('dashboard.weakest_topic_br'))}
                                    </div>
                                    <div className="dots">:</div>
                                    <div className="percentage" style={{ width: "170px" }}>{props.userOverview.translated_weakest_topic}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {props.subjectName !== "all" && <div className="performance_section" style={{ marginTop: "30px" }}>
                        <div className="performance_section_heading"><span>{userData.fullname}'s</span> {t("super_admin_dashboard.performance")}</div>
                        <div className="section_scrollable">
                            <table className="table table-bordered performance_section_table">
                                <thead>
                                    <tr>
                                        <th>{t("dashboard.chapter_name")}</th>
                                        <th>{t("dashboard.total_score")}</th>
                                        <th>{t("dashboard.progress")}</th>
                                    </tr>
                                </thead>
                                {
                                    chapterWisePerformance.length > 0 && chapterWisePerformance.map((val, index) => {
                                        return (
                                            <tbody>
                                                <tr key={"chapter-" + index} className="d-down" onClick={onClickHandler}>
                                                    <td className="dd-arrow">
                                                        {(index + 1)}. {val.translated_chapter_name}
                                                        <span>
                                                            {/* <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.1767 0.612128L12.1767 4.30802L6.35059 10.2112L0.524434 4.30801L0.524434 0.612127L6.35059 6.54095L12.1767 0.612128Z" fill="#9C9C9C"></path>
                                                            </svg> */}
                                                        </span>
                                                    </td>
                                                    <td><div className="progressandper"><span>{Math.floor(val.chapter_performance_graph.chapter_performance.total_score)}%</span><ProgressBar now={val.chapter_performance_graph.chapter_performance.total_score} /></div></td>
                                                    {val.chapter_performance_graph.chapter_performance
                            .total_score > 0 ? (
                            <td>
                              <div className="progressandper">
                                <span>100%</span>
                                <ProgressBar now={100} />
                              </div>
                            </td>
                          ) : (
                            <td>
                              <div className="progressandper">
                                <span>
                                  {Math.floor(
                                    val.chapter_performance_graph
                                      .chapter_performance.progress
                                  )}
                                  %
                                </span>
                                <ProgressBar
                                  now={
                                    val.chapter_performance_graph
                                      .chapter_performance.progress
                                  }
                                />
                              </div>
                            </td>
                          )}                                                </tr>
                                                {
                                                    val.topics.map((tval, tindex) => {
                                                        return (
                                                            <tr key={"topic-" + tindex} className="collapse">
                                                                <td>
                                                                    {(index + 1)}.{(tindex + 1)}. {tval.topic_name}
                                                                </td>
                                                                <td><div className="progressandper"><span>{Math.floor(tval.topic_performance_graph.topic_performance.total_score)}%</span><ProgressBar now={tval.topic_performance_graph.topic_performance.total_score} /></div></td>
                                                                <td><div className="progressandper"><span>{Math.floor(tval.topic_performance_graph.topic_performance.progress)}%</span><ProgressBar now={tval.topic_performance_graph.topic_performance.progress} /></div></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        )
                                    })
                                }
                            </table>
                        </div>

                    </div>}
                </div>
                {/* <Loader showLoader={props.showLoader} /> */}
            </div>
        </>
    )

} export default ProfileDashboardComponent