import React, { useEffect, Suspense } from "react";
import { getGlobal, useGlobal } from "reactn";
import { ToastProvider } from "react-toast-notifications";
import "./App.sass";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./assets/scss/themes.scss";
import Config from "./config";
import { authProtectedRoutes, publicRoutes } from "./routes/routes";
//import layouts
import NonAuthLayout from "./layouts/NonAuth";
import AuthLayout from "./layouts/AuthLayout/";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { isSafari } from "react-device-detect";
import { showNoInternetAlert, showInternetAlert } from "./services/utility";
import { secureStorage } from "services/utility";
import AppLoader from "elements/AppLoader";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import Loading from './loading';



var NewshowExitModal = false;
let deferredPrompt = null;

const registerPushListener = (pushNotification) => {
  if (navigator.serviceWorker)
    navigator.serviceWorker.addEventListener("message", (event) => {
      // console.log("Event", event);
      // console.log("The service worker sent me a message:", event.data);
      NewshowExitModal = true;
      // console.log("NewShowExitMOdals", NewshowExitModal);
      pushNotification(event.data.firebaseMessaging.payload.data.notification);
    });
};
const App = () => {
  const dispatch = useDispatch();
  const io = useSelector((state) => state.io.io);
  const isProduction = process.env.NODE_ENV === 'production';
  const token = useGlobal("token")[0];
  const setStartingPoint = useGlobal("entryPath")[1];
 

  // const toHome = token && <Redirect to="/" />;
  //const toLogin = !token && <Redirect to="/login" />;

  const isAuthProtected = token ? true : false;

  if (!["dark", "light"].includes(Config.theme)) Config.theme = "light";

  if (!window.loaded) {
    setStartingPoint(window.location.pathname);
    window.loaded = true;
  }
  var bc;
  if (!isSafari) {
    bc = new BroadcastChannel("PROFILE_CHANGE");
  }

  useEffect(() => {
    if (!isSafari) {
      bc.onmessage = function (ev) {
        // console.log("Page Refresh event ------------->", ev);
        if (
          ev.target.name == "PROFILE_CHANGE" &&
          ev.data == "PROFILE DATA UPDATED" &&
          ev.origin == window.location.origin
        ) {
          // location.reload();
          window.location.reload(false);
        }
      };
    }
    if (!io || !getGlobal().user || !token) return;
    let focusCount = 0;
    // let interval = setInterval(() => {
    //   if (!document.hasFocus()) {
    //     focusCount++;
    //     if (focusCount === 10) {
    //       io.emit("status", { status: "away" });
    //     }
    //   } else if (focusCount !== 0) {
    //     focusCount = 0;
    //     io.emit("status", { status: "online" });
    //   }
    // }, 1000);
    // return () => clearInterval(interval);
  }, [io, token]);

  useEffect(() => {
    return () => {
      try {
        if (getGlobal().audioStream)
          getGlobal()
            .audioStream.getTracks()
            .forEach((track) => track.stop());
      } catch (e) {}
      try {
        if (getGlobal().videoStream)
          getGlobal()
            .videoStream.getTracks()
            .forEach((track) => track.stop());
      } catch (e) {}
      if (!isSafari) {
        bc.close();
      }
    };
  }, []);

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
    >
    {/* // rendering the router with layout */}
    <BrowserRouter>
    
      {/* <GuardProvider
        guards={[routeGuard]}
        loading={() => (
          <AppLoader/>
        )}
      > */}
      <React.Fragment>
        <Suspense fallback={<AppLoader />}>

        <TransitionGroup>
        <CSSTransition 
      classNames="slide" 
      timeout={1000}
    > 
          <Switch>

            {/* public routes */}
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                meta={route.meta}
              />
            ))}

            {/* private/auth protected routes */}
            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={AuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                theme={Config.theme}
                meta={route.meta}
              />
            ))}
          </Switch>
        </CSSTransition>
        </TransitionGroup>

        </Suspense>
      </React.Fragment>
      {/* </GuardProvider> */}
    </BrowserRouter>
    </CacheBuster>
  );

  /* return (
        <ToastProvider
            autoDismiss
            autoDismissTimeout={6000}
            placement="top-right"
        >
                                    
            <div className={`theme ${Config.theme}`}>
                <Router>
                    <Switch>
                        <Route exact path="/login">
                            {toHome}
                            {!toHome && <Login/>}
                        </Route>
                        <Route exact path="/reports">
                            {toLogin}
                            {!toLogin && <Reports/>}
                        </Route> 
                        <Route exact path="/create-quiz">
                            {toLogin}
                            {!toLogin && <CreateQuiz/>}
                        </Route>
                        <Route exact path="/quiz-detail">
                            {toLogin}
                            {!toLogin && <QuizDetail />}
                        </Route>
                       <Route exact path="/quiz-listing">
                            {toLogin}
                            {!toLogin && <QuizListing />}
                        </Route>
                         <Route exact path="/assessment-quiz">
                            {toLogin}
                            {!toLogin && <AssessmentSec />}
                        </Route>
                        <Route exact path="/report-details">
                            {toLogin}
                            {!toLogin && <ReportDetail/>}
                        </Route>
                        <Route exact path="/live-class">
                            {toLogin}
                            {!toLogin && <QuizListing/>}
                        </Route>
                        <Route exact path="/chat">
                            {toLogin}
                            {!toLogin && <Chat/>}
                        </Route>
                        <Route exact path="/drive">
                            {toLogin}
                            {!toLogin && <Drive/>}
                        </Route>
                        <Route exact path="/assessments">
                            {toLogin}
                            {!toLogin && <Assessments/>}
                        </Route>
                        <Route exact path="/doubts">
                            {toLogin}
                            {!toLogin && <Doubts/>}
                        </Route>
                        
                        <Route exact path="/class-rooms/*">

                            {toLogin}
                            {!toLogin && <ClassRoomsHome/>}
                        </Route>
                        <Route exact path="/class-rooms/*">
                            {toLogin}
                            {!toLogin && <ClassRoomsHome/>}
                        </Route>
                        <Route exact path="/home/doubts">
                            {toLogin}
                            {!toLogin && <Doubts/>}
                        </Route>
                        <Route exact path="/home/doubts/*">
                            {toLogin}
                            {!toLogin && <Doubts/>}
                        </Route>
                        <Route exact path="/broadcast">
                            {toLogin}
                            {!toLogin && <BoardCastClass />}
                        </Route>
                        <Route path="/">
                            {toLogin}
                            {!toLogin && <Home/>}
                        </Route>
                    </Switch>
                </Router>
            </div>
        </ToastProvider>
    ); */
};

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  theme: theme,
  meta: meta,
  ...rest
}) => {
  return (
    
    <Route
      meta={meta}
      {...rest}
      render={(props) => {
        if (!isAuthProtected) {
          return (
                <ToastProvider
                autoDismiss
                autoDismissTimeout={6000}
                placement="top-right"
                >
            <Layout theme={theme} meta={meta}>
              <Component {...props} />
            </Layout>
              </ToastProvider>
            /*  <Redirect to={{ pathname: "/login", state: { from: props.location } }} /> */
          );
        }
        // authorised so return component
        return (
          <ToastProvider
            autoDismiss
            autoDismissTimeout={6000}
            placement="top-right"
          >
            <Layout theme={theme} meta={meta}>
              <Component {...props} />
            </Layout>
          </ToastProvider>
        );
      }}
    />
  );
};

export default compose(
  withState("token", "setToken", ""),
  withState("notifications", "setNotifications", []),
  withHandlers({
    pushNotification:
      ({ setNotifications, notifications }) =>
      (newNotification) =>
        setNotifications(notifications.concat(newNotification)),
  }),
  lifecycle({
    async componentDidMount() {
      window.addEventListener("DOMContentLoaded", () => {
        let displayMode = "browser-tab";
        if (navigator.standalone) {
          displayMode = "standalone-ios";
        } else if (window.matchMedia("(display-mode: standalone)").matches) {
          displayMode = "standalone";
        }
        secureStorage.setItem("launchmode", displayMode);

        // Log launch display mode to analytics
        // console.log("DISPLAY_MODE_LAUNCH:", displayMode);
      });

      window.addEventListener("beforeinstallprompt", (e) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();

        // Stash the event so it can be triggered later.
        window.deferredPrompt = e;
        //console.log("Registerd event");
        // Update UI notify the user they can install the PWA
        secureStorage.setItem("pwanotinstalled", "true");
        //this.showInstallPromotion();
      });
      window.addEventListener("appinstalled", (evt) => {
        // Log install to analytics
        //console.log("INSTALL: Success");
        secureStorage.setItem("pwanotinstalled", "false");
      });
      window.addEventListener("online", () => {
        //console.log("Connected Internet");
        showInternetAlert();
      });
      window.addEventListener("offline", () => {
        showNoInternetAlert();
        //console.log("No Internet");
      });

      // if (!isSafari) {
      //   const { pushNotification, setToken } = this.props;
      //   if ("serviceWorker" in navigator) {
      //     navigator.serviceWorker
      //       .register("/firebase-messaging-sw")
      //       .then(function (registration) {
      //         //console.log("Service Worker Registered");
      //         messaging.useServiceWorker(registration);
      //       })
      //       .catch((err) => {
      //         //console.log("Service Worker not Registered", err);
      //       });
      //   }
      //   if (messaging)
      //     messaging
      //       .requestPermission()
      //       .then(async function () {
      //         // const token = await messaging.getToken();
      //         // setToken(token);
      //         messaging.getToken().then((token) => {
      //           setToken(token);
      //           if (token) {
      //             setToken(token);
      //             // window.localStorage.setItem("firebaseToken", token);
      //             window
      //               .smartech("set", "token", token)
      //               .then(function (response) {
      //                 // console.log("$$Token in", response); // for debugging
      //                 //insert your business logic here
      //               });
      //             // sendTokenToServer(token);
      //             // updateUIForPushEnabled(token);
      //           } else {
      //             // Show permission request.
      //             // console.log(
      //             //   "No Instance ID token available. Request permission to generate one."
      //             // );
      //             // Show permission UI.
      //             // updateUIForPushPermissionRequired();
      //             // setTokenSentToServer(false);
      //           }
      //         });
      //       })
      //       .catch(function (err) {
      //         // console.log("Unable to get permission to notify.", err);
      //       });

      //   registerPushListener(pushNotification);
      // }
    },
  })
)(App);
