import { setGlobal } from "reactn";
import setAuthToken from "./actions/setAuthToken";
import initIO from "./actions/initIO";
import store from "./store/store";
import jwtDecode from "jwt-decode";
import { secureStorage } from "services/utility";
import Emitter from "services/EventEmitter";

const init = () => {
  document.addEventListener("gesturestart", (e) => {
    e.preventDefault();
  });

  //check here display-mode: fullscreen
  window.matchMedia("(display-mode: fullscreen)").addListener(({ matches }) => {
    if (matches) {
      // Apply fullscreenmode mode related changes
      Emitter.emit("FULL_SCREEN_MODE", "icon-exit-fullscreen");
    } else {
      // Remove fullscreenmode mode related changes
      Emitter.emit("FULL_SCREEN_MODE", "icon-fullscreen");
    }
  });

  const token = secureStorage.getItem("token");
  const { user } = store.getState();

  if (token) {
    setAuthToken(token);
    // store.dispatch(initIO(token));
  }

  const state = {
    version: "2.0.0",
    entryPath: window.location.pathname,
    token,
    user: user || (token ? jwtDecode(token) : {}),
    rooms: [],
    searchResults: [],
    favorites: [],
    meetings: [],
    nav: "rooms",
    search: "",
    over: null,
    isPicker: false,
    messages: [],
    streams: [],
    inCall: false,
    video: true,
    audio: true,
    audioStream: null,
    videoStream: null,
    screenStream: null,
    callStatus: null,
    counterpart: null,
    callDirection: null,
    meeting: null,
    showPanel: true,
    panel: "standard",
    newGroupUsers: [],
  };

  setGlobal(state).then(
    // () =>
    // console.log("Global state init complete!", state)
  );
};

export default init;
