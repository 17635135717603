import React from "react";
import {useTranslation} from "react-i18next";
import down_arrow_b2b from '../../../assets/images/down_arrow_b2b.png'

function SchoolFilter(props) {

    const {t} = useTranslation()

    const toggleOption = (id) => {
        props.setdisplayedClass(t("super_admin_dashboard.all_classes"))
      
        props.setSelectedSchools(prevSelected => {
            const newArray = [...prevSelected]
            if (newArray.includes(id)) {
                return newArray.filter(item => item != id)
            } else {
                newArray.push(id)
                return newArray;
            }
        })
    }

   
    let value=props.schoolList.map((opt)=>{
        if(opt.id==props.selectedSchools[0]){
            return opt.school_name
        }
    })

    return (
        <div className="form_elm">
            <div className="form-group select_btn_wrap">
                <label htmlFor="grade" className="select_btn">
                    <img src={down_arrow_b2b} alt="down_arrow" />
                </label>
                <MultiSelectDropdown options={props.schoolList} selected={props.selectedSchools} toggleOption={toggleOption} schoolName={value}/>
            </div>
        </div>
    )
}
export default SchoolFilter


const MultiSelectDropdown = ({ options, selected, toggleOption ,schoolName}) => {
    const {t} = useTranslation();

    return (
        <div className="c-multi-select-dropdown">
            <div className="c-multi-select-dropdown__selected">
                <div>{selected.length === 0 ? t("super_admin_dashboard.all_schools") : <>{selected.length> 1 ? t("super_admin_dashboard.multiple_schools") :schoolName}</>} </div>
            </div>
            <ul className="c-multi-select-dropdown__options">
                                    {options.map(option => {
                    const isSelected = selected.includes(option.id);
                    console.log("isSelected",isSelected)

                    return (
                        <li key={option.id} className="c-multi-select-dropdown__option" onClick={() => toggleOption(option.id)}>
                            <input type="checkbox" checked={isSelected} onChange={() => { }} className="c-multi-select-dropdown__option-checkbox"></input>
                            <span>{option.school_name}</span>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}