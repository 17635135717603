import React,{useState} from "react";
import {Tabs,Tab} from "react-bootstrap"
import * as XLSX from "xlsx"
import config from "config"
import Axios from 'axios'
import {useToasts} from "react-toast-notifications";
import { useTranslation } from "react-i18next";

const StudentListTable = (props) => {
  
  const [TeacherAnalysisTabs,setTeacherAnalysisTabs] = useState("1")
  const [sentList, setSentList] = useState([])
  const [isSent, setIsSent] = useState(false)
  const {addToast} = useToasts();
  const { t } = useTranslation();
  const sendEmailtoStudent = async (stu_info) =>{      
    
    let stu_list=[...sentList,stu_info.user_id]
    setSentList(stu_list)
    // if(stu_info.user_email =="") return 

    if(props.mail_type == "chapter"){
   
    let chapter_name = props.location.state.chapters[0].chapter_name;
    let due_date = props.location.state.dueDate_time.split(" ")[0];
    let hyperlink_url = window.location.origin +"/" +props.location.state.chapters[0].subject_slug + "/" + props.location.state.chapters[0].slug + "/topics?requestFullScreen=true"
    let schedule_time = props.location.state.schedule_time.split(" ")[0];
   
    let payload =  {      
      "subject" : "Chapter Assignment Reminder",
      "chapter_url":hyperlink_url,
      "schedule_date" :schedule_time,
      "due_date":due_date,
      "student_name" : stu_info.full_name,
      "chapter_name": chapter_name,
      "email":stu_info.user_email,
      "email_message": "mic testing 1-2-3...hello hello",
      "mail_type":"chapter",
    }
    let url = config.assessmentUrl + "/send-email-sms"
    let res = await Axios.post(url,payload)
  }else{
    let hypertext_url = window.location.origin +
				"/assessment/" +
				props.location.state.type +
				"/" +
				props.location.state.assessmentID +
				"?requestFullScreen=true";
   
    let payload =  {      
      "subject" : "Assessment Reminder",
      "quiz_url" : hypertext_url,
      "student_name" : stu_info.full_name,
      "email":stu_info.user_email,
      "email_message": "mic testing 1-2-3...hello hello",
      "mail_type":"quiz",
    }
    let url = config.assessmentUrl + "/send-email-sms"
    let res = await Axios.post(url,payload)
  }


  }

  const sendMailsToAll = async () =>{
    setIsSent(false)
    let stu_list=[...sentList]
    await props.displayChapterData?.inactive_student_list.map(async (stu_info)=>{
      stu_list=[...stu_list,stu_info.user_id]
     
      await sendEmailtoStudent(stu_info);
    })
    setSentList(stu_list)
    setIsSent(true)
    const successMessage =  t("assessment.email_sent_success");
      addToast(successMessage, {
      appearance: "success",
      autoDismiss: true,
    });
  }
  const downloadUserData = () => {
    let data1=props.displayChapterData?.active_student_list.map((s)=> ({
      "Rank": s.rank,
      "Name": s.full_name,
      "Score": s.score,
      "active/inactive": "active",
    }))

    let data2=props.displayChapterData?.inactive_student_list.map((s)=> ({
      "Name": s.full_name,
      "active/inactive": "inactive",
    }))

    return[...data1, ...data2]

}

const convert2Excel = React.useCallback(async () => {
  const worksheet = XLSX.utils.json_to_sheet(downloadUserData());
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, "DataSheet.xlsx");
})


  console.log("stuListTable_props",props)

  return (
    <div className="allContainer ">
      <div className="row align-items-center justify-content-between px-3">
        <div  className="col-3" style={{font: "normal normal 700 22px/28px Poppins", color: "#000"}}>
          <span>{t("assessment.student_list")}</span>
        </div>
        <div className="d-flex align-items-center col-md-9 col-sm-12 justify-content-sm-center justify-content-md-end">
        <div>{!isSent ? <button className=" singleButtonContainer pt-extra-small" style={{border: "none", backgroundColor: "#FC8643", color: "#fff"}} onClick={sendMailsToAll}>{t("assessment.send_email")}</button>
        :<button disabled className=" singleButtonContainer pt-extra-small" style={{border: "none", backgroundColor: "#7f7f7f", color: "#fff", pointerEvents:"none"}} >{t("assessment.email_sent")}</button>
          }</div>
        <div><button className="singleButtonContainer pt-extra-small" onClick={convert2Excel}>{t("assessment.download_report")}</button></div>
        </div>
      </div>
      <div className="tabContainer px-2">
        <Tabs
          activeKey={TeacherAnalysisTabs}
          onSelect={(k) => setTeacherAnalysisTabs(k)}
          className="custom_student_list_table"
        >
          <Tab eventKey="1" title={t('report_page.completed_by')} className="tab">
            {props.displayChapterData?.active_student_count === 0 ?
              <div style={{marginTop: "2rem",minHeight: "300px",maxHeight: "300px",overflowY: "scroll",textAlign: "center"}}>
                <span>{t("assessment.no_students_found")}</span>
              </div>
              :
              <div style={{marginTop: "2rem",minHeight: "300px",maxHeight: "300px", overflowY: "scroll", borderBottom: "2px solid #F1F4F6"}}>
                <table className='table table-borderless'>
                  <thead style={{background: "#F3F6F9", borderRadius: "6px", position: "sticky", top: 0}}>
                  <tr style={{font: "normal normal 600 12px/18px Poppins", color: "#B5B5C3"}}>
                    <th scope='col'>{t("assessment.rank")}</th>
                    <th scope='col'>{t("assessment.name")}</th>
                    {/*<th scope='col'>MARKS (OUT OF {props.totalMarks})</th>*/}
                    <th scope='col'>{t("assessment.score")}</th>
                    <th scope='col'>{t("assessment.accuracy")}</th>
                    {/*<th scope='col'>TIME EFFICIENCY</th>*/}
                  </tr>
                  </thead>
                {props.displayChapterData?.active_student_list.map((stu, index) => {
                  return (
                    <tbody key={index}>
                      <tr style={{font: "normal normal 600 14px/21px Poppins"}}>
                        <td>{stu.rank}.</td>
                        <td>{stu.full_name}</td>
                        <td>{stu.score}</td>
                        <td>{stu.accuracy.toFixed(0)}%</td>
                        {/*<td>{stu.efficiency?.toFixed(0)}%</td>*/}
                      </tr>
                    </tbody>
                  )
                })}
                </table>
              </div>  
            }
          </Tab>
          <Tab eventKey="2" title={t('report_page.not_completed_by')} className="tab">
            {props.displayChapterData?.inactive_student_count === 0 ?
              <div className="thread">
                <span>{t("assessment.no_students_found")}</span>
              </div>
              :
              <div style={{marginTop: "2rem",minHeight: "300px",maxHeight: "300px", overflowY: "scroll",  borderBottom: "2px solid #F1F4F6"}}>
                <table className='table table-borderless'>
                <thead style={{background: "#F3F6F9", borderRadius: "6px", position: "sticky", top: 0}}>
                  <tr style={{font: "normal normal 600 12px/18px Poppins", color: "#B5B5C3"}}>
                    <th scope='col'>{t("assessment.sr_no")}</th>
                    <th scope='col'>{t("assessment.name")}</th>
                    <th>{t("assessment.notify")}</th>
                  </tr>
                </thead>
                {props.displayChapterData?.inactive_student_list.map((stu,index) => {
                  return (
                    <tbody key={index}>
                      <tr style={{font: " normal normal 600 14px/21px Poppins"}}>
                        <td>{index + 1}.</td>
                        <td>{stu.full_name}</td>
                        <td >{!sentList.includes(stu.user_id)?<div onClick={()=>sendEmailtoStudent(stu)} style={{cursor: "pointer", fontSize: "16px"}}>🚀</div>:<div>✅</div>}</td>
                      </tr>
                    </tbody>
                  )
                })}
                </table>
              </div>
            }
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default StudentListTable




