const { innerWidth: width, innerHeight: height } = window;
const graphOptions = {
    maintainAspectRatio: width > 900,
    type: 'line',
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
            text: '',
        },
    },
    scales: {
        yAxes: [{
            display: true,
            ticks: {
                suggestedMin: 0,
                suggestedMax: 100,
                callback: function (value, index, values) {
                    return value + "%";
                }
            }
        }]
    },
    tooltips: {
        callbacks: {
            label: (tooltipItem, data) => {
                var label = data.datasets[tooltipItem.datasetIndex].label || '';

                if (label) {
                    label += ': ';
                }
                label += Math.round(tooltipItem.yLabel);
                return label;

            }
        }
    }
};
export default graphOptions