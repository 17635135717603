import React from 'react'
import * as XLSX from "xlsx"
import {MdFileDownload} from "react-icons/md"

const CustomCount = ({studentCount,headerText,subHeader,id, backgroundColor}) => {

    const downloadUserData = (myId) => {
        let data = [];
        if(studentCount.active_user_list.length > 0 && myId == "active_student") {
            data = studentCount.active_user_list;
        }
        else if(studentCount.inactive_user_list.length > 0 && myId == "inactive_student") {
            data = studentCount.inactive_user_list;
        }
        else if((studentCount.active_user_list.length > 0 || studentCount.inactive_user_list.length > 0) && myId == "Total_student") {
            let data1 = studentCount.active_user_list.map((s) => ({
                ...s,
                "active/inactive": "active",
            }));
            let data2 = studentCount.inactive_user_list.map((s) => ({
                ...s,
                "active/inactive": "inactive",
            }));
            data = [...data1,...data2];
        }
        return data;
    }

    let myId = id

    const convert2Excel = React.useCallback(async () => {
        const worksheet = XLSX.utils.json_to_sheet(downloadUserData(myId));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook,worksheet,"Sheet1");
        XLSX.writeFile(workbook,"DataSheet.xlsx");
    })

    return (
        <table
            className='table table-borderless outerContainer'
            style={{backgroundColor: backgroundColor}}
            id={myId}
        >
            <thead className='thead'>
                <tr>
                    <th>{headerText}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>{subHeader}</th>
                </tr>
            </tbody>
            <MdFileDownload
                size='25px'
                style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    margin: "6px",
                    cursor: "pointer",
                }}
                onClick={convert2Excel}
            />
        </table>
    )
}

export default CustomCount