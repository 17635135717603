import React, { Component } from 'react';

import {MainHeaderJsx} from './mainHeader.jsx';

export class mainHeader extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <MainHeaderJsx {...this.props}/>
        )
    }
}

export default mainHeader;