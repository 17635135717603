import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

function Teacher_name_filter(props) {
    const [teacherName, setTeacherName] = useState('')
    const [teacherList, setTeacherList] = useState([])

    const {t} = useTranslation();


    const studentFilterSubmit = (e) => {
        setTeacherName(e.full_name)
        props.setIsFilterOpen(false)
        props.onApplyFilterClick('teacher', e, '', '')
        setTeacherList([])
    }



    useEffect(() => {
        if (teacherName.length > 3) {
            // props.onApplyFilterClick('student', studentName, '', '')
            props.setFilterInUse('teacher_name_filter')
            searchStudentAPI()
        } else {
            setTeacherList([])
        }
    }, [teacherName])

    useEffect(() => {
        if (props.filterInUse !== 'teacher_name_filter') {
            setTeacherName('');
        }
    }, [props.filterInUse])

    const searchStudentAPI = async () => {
        let url = `https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/search-user?school_id=${props.schoolId}&search=${teacherName}&type=teacher`
        const res = await fetch(
            url
        );
        const data = await res.json()
        if (data.statusCode === 200) {
            if (data.data.totalCount === 0) {
                setTeacherList([])
            } else {
                setTeacherList(data.data.user)
            }

        } else {
            setTeacherList([])
        }
    }
    
    return (<>
        <div className="form_input_elm" style={{position: "relative"}}>
            <div className="input-group">
                <div className="input-group-prepend">
                    <div className="input-group-text">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                    </div>
                </div>
                <input type="text" className="form-control" id="inlineFormInputGroup" placeholder={t("dashboard.enter_teacher_name")} value={teacherName}
                    onChange={(e) => { setTeacherName(e.target.value) }} />
            </div>
            {
                teacherList && <div className={`search_box_dropdown ${teacherList.length > 0 ? "show" : ""}`}>
                    {teacherList && teacherList.map((value, index) => {
                        return (
                            <div onClick={() => { studentFilterSubmit(value) }} key={index}>{value.full_name}</div>
                        )
                    })}
                </div>
            }
        </div>
    </>)
}
export default Teacher_name_filter