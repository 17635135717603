import { SET_CONFIG, CLEAR_CONFIG } from './types';
import config from 'config';

const INITIAL_STATE = {
    showLanguages: config.languages,
    enableChangeLanguage: false,
    redirectUrl: '',
};

export default function (state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case SET_CONFIG: {
            return {
                ...state,
                ...payload,
            };
        }
        case CLEAR_CONFIG: {
            return {
                ...state,
                ...INITIAL_STATE,
            };
        }
        default: return state;
    }
};