import React, { useState } from "react";
import { useTranslation } from "react-i18next";
function ChapterListing2(props) {

 const {t} =  useTranslation()

    const showChapterReport = (id) => {

        if (id === props.chapterId && props.topicId === '') {
            props.onTabSelect(2)
            return
        }

        props.setDashboardData('chapter_report', id)
    }

    const onClickHandler = (e) => {

        const hiddenElement = e.currentTarget.parentElement.parentElement.parentElement.getElementsByClassName('collapse')
        const current_target_value = e.currentTarget;


        current_target_value.className.indexOf("collapse_element_click show") > -1 ? current_target_value.classList.remove("show") : current_target_value.classList.add("show")
        for (var i = 0; i < hiddenElement.length; i++) {
            hiddenElement[i].className.indexOf("collapse show") > -1 ? hiddenElement[i].classList.remove("show") : hiddenElement[i].classList.add("show");
        }
    }
    return (
        <>
            <div style={{ backgroundColor: "#fff", padding: "10px" }}>
                <table className="table table-borderless performance_section_table" >
                    <thead style={{ borderBottom: "1px solid lightgray" }}>
                        <tr>
                            <th style={{ maxWidth: "200px" }}>{t("dashboard.chapter")}</th>
                            <th style={{visibility:"hidden"}}>{t("dashboard.topics")}</th>
                            <th>{t("dashboard.report")}</th>
                        </tr>
                    </thead>
                    {

                        props.chapterTopicPerformance.map((value, index1) => {
                
                            return (
                                value.map((val, index) => {

                                    return (
                                        <tbody key={index}>
                                            <tr key={"chapter-" + index} >
                                                <td>{(index + 1)}. {val.translated_chapter_name.toLowerCase()}</td>
                                                <td >
                                                    <div className="collapse_element_click" onClick={onClickHandler}>
                                                     
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className={`make_it_clickable ${props.topicId === '' && props.chapterId === val.chapter_id ? "active" : ""}`} onClick={() => { showChapterReport(val.chapter_id) }} style={{ textAlign: "center" }}>
                                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 1V17.9179H17.5305M4.09947 12.623L9.39439 7.32808L13.2687 10.9441L18.9511 5.39091" stroke="#9C9C9C" strokeWidth="1.79511" strokeLinecap="round" />
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>
                                         
                                        </tbody>
                                    )
                                })
                            )

                        })
                    }
                </table>
            </div>
        </>
    )
} export default ChapterListing2