import React from "react";
import Options from "./Option.jsx";
import { convertStringToLatex, initMathJax } from "services/utility.js";

const QuestionCard = ({
  ques,
  index,
  showAnswer,
  spaceForSubj,
  colorList,
  t,
  ref,
}) => {
  const { type, additional_info, answer, data, sol_image, questionText, image, q_question_marks } = ques;

  const isMCQ = type === "mcq";
  const isSubjective = type === "subjective";
  const isMCMS = type === "mcms";
  const hasLatex = additional_info?.has_latex === "Y";

  initMathJax();

  const createMarkupForOptions = (option_value) => {
    try {
      let optionValue = hasLatex && typeof option_value !== "number" && option_value
        ? convertStringToLatex(option_value)
        : option_value;
      return { __html: optionValue };
    } catch (err) {
      console.error(err);
    }
  };

  const renderAnswerContent = () => {
    if (isMCQ || isMCMS) {
      return (
        <Options
          options={data.options}
          answer={answer}
          showAnswer={showAnswer}
        />
      );
    }

    if (isSubjective) {
      return (
        <div className="singleOption subjectivePrint" style={{ backgroundColor: "#C8FCBF", color: "#124609" }}>
          <div style={{ color: "#2B2B2B" }}>{t("assessment.ans")}</div>
          {sol_image ? (
            <img src={sol_image} alt="" className="subjectiveImage" style={{maxHeight: "clamp(150px, 20vw, 250px)", objectFit: "contain" }} />
          ) : (
            <div dangerouslySetInnerHTML={createMarkupForOptions(answer)} />
          )}
        </div>
      );
    }
    return (
      <div className="optionBox" style={{ backgroundColor: colorList[Math.floor(Math.random() * colorList.length)] }}>
        <div className="qest-text" style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "10px", flexWrap: "wrap", marginTop: "30px" }}
          dangerouslySetInnerHTML={createMarkupForOptions(answer.option_value)} />
      </div>
    );
  };

  const renderMCMSOptions = () => {
    const selectedAnswers = answer.map(item => item.option_name);
    return (
      <>
        {data.options.map((el, i) => (
          <div key={i} className={`singleOption ${selectedAnswers.includes(el.option_name) ? "subjectivePrint" : ""}`}
            style={selectedAnswers.includes(el.option_name)
              ? { backgroundColor: '#C8FCBF', color: '#124609' }
              : { backgroundColor: 'transparent', color: '#999898' }}>
            {el.option_type === "image" ? (
              <>
                <div style={{ color: '#2B2B2B' }}>{el.option_name}.</div>
                <img src={el.option_image} alt='' style={{ maxHeight: '250px', objectFit: 'contain' }} />
                {selectedAnswers.includes(el.option_name) && <img src={el.questCorrectArrow} alt="" style={{ width: '15px', height: '15px' }} />}
              </>
            ) : (
              <>
                <div style={{ color: '#2B2B2B' }}>{el.option_name}.</div>
                <div dangerouslySetInnerHTML={createMarkupForOptions(el.option_value)} />
              </>
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="questionCardContainer printFont" key={ques._id} ref={ref}
      style={{ borderLeft: "none", borderRight: "none", margin: "30px", pageBreakInside: "avoid", pageBreakAfter: "auto" }}>
      <span className="quesType" style={{ color: "#FC8643", fontWeight: 700 }}>
        {type.toUpperCase()}
      </span>
      <div className="question_Button">
        <div className="index_ques">
          {`${index + 1}`}.&nbsp;
          {image ? (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
              <div className="qest-text" dangerouslySetInnerHTML={createMarkupForOptions(questionText)} />
              <img src={image} alt="" style={{ maxHeight: "250px", objectFit: "contain" }} />
            </div>
          ) : (
            <div className="qest-text" dangerouslySetInnerHTML={createMarkupForOptions(questionText)} />
          )}
        </div>
        <div className="buttons quesMarks">
          <div className="quet-selected printMarks" style={{ display: "flex" }}>
            {q_question_marks?.positive[1]} <span style={{ marginLeft: "2px" }}>Marks</span>
          </div>
        </div>
      </div>
      <div className="mb-2 hrLine" />

      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem",  }}>
        {showAnswer ? (
          isMCMS ? renderMCMSOptions() : renderAnswerContent()
        ) : (
          isMCQ || isMCMS ? (
            <Options options={data.options} answer={answer} showAnswer={showAnswer} />
          ) : (
            isSubjective && spaceForSubj && (
              <div className="singleOption" style={{ color: "#124609" }}>
                <div style={{ color: "#2B2B2B" }}>
                  <div>{t("assessment.ans")}</div>
                  <textarea className="textAreaWidth" />
                </div>
              </div>
            )
          )
        )}
      </div>
    </div>
  );
};

export default QuestionCard;
