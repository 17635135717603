import React from 'react';
import { Line } from 'react-chartjs-2';
import graphOptions from './options';
import store from 'store/store';
import { useTranslation } from 'react-i18next';



function TeacherGraphForUser(props) {
    const {t} = useTranslation();
    var { user } = store.getState();
    console.log("userProfileData", user.user_type)


    const options = graphOptions

    if (user.user_type === "superadmin") {
        if (props.userPerformanceGraph.length > 0) {
            let allSections = props.userPerformanceGraph[0].all_sections;
            let your_section = props.userPerformanceGraph[0].section_graph;
            let user_performance = props.userPerformanceGraph[0].user_performance;

            let dataset = [
                {
                    label:props.subjectName==="all" ? t("subjects.All Subject")+" "+t("super_admin_dashboard.average") : t(`subjects.${props.subjectName}`) +" "+ t("super_admin_dashboard.average"),
                    data: [allSections.progress,allSections.total_score, allSections.overall_accuracy, allSections.time_efficiency],
                    borderColor: '#FC8643',
                    backgroundColor: '#FC8643',
                    fill: false,
                    lineTension: 0,
                    borderWidth: 1.3131,
                    pointBackgroundColor: "#FC8643"
                },
                {
                    label: props.userDetails?.fullname !== '' ? props.userDetails?.fullname + " " +t("super_admin_dashboard.performance") :  t("dashboard.user") +" "+ t("super_admin_dashboard.performance"),
                    data: [ user_performance.progress,user_performance.total_score, user_performance.overall_accuracy, user_performance.time_efficiency],
                    borderColor: '#37CE34',
                    backgroundColor: '#37CE34',
                    fill: false,
                    lineTension: 0,
                    borderWidth: 1.3131,
                    pointBackgroundColor: "#37CE34"
                }
            ]
            let labels = [ t("dashboard.progress"),t("dashboard.total_score"), t("dashboard.overall_accuracy"), t("dashboard.time_efficiency")];
       
            let data = {
                labels,
                datasets: dataset
            }
            return (
                <>
                    <div className='line_chart_section'>
                        <Line options={options} data={data} />
                    </div>


                </>
            )
        } else {
            return ("")
        }
    }
    else{
        if (props.userPerformanceGraph.length > 0) {
            let allSections = props.userPerformanceGraph[0].all_sections;
            let your_section = props.userPerformanceGraph[0].section_graph;
            let user_performance = props.userPerformanceGraph[0].user_performance;

            let dataset = [
                {
                    label: t("dashboard.grade") +" "+ props.userDetails.grade_name,
                    data: [allSections.progress,allSections.total_score, allSections.overall_accuracy, allSections.time_efficiency],
                    borderColor: '#FC8643',
                    backgroundColor: '#FC8643',
                    fill: false,
                    lineTension: 0,
                    borderWidth: 1.3131,
                    pointBackgroundColor: "#FC8643"
                },
                {
                    label:  t("dashboard.section") +" " + props.selectedBatch,
                    data: [ your_section.progress,your_section.total_score, your_section.overall_accuracy, your_section.time_efficiency],
                    borderColor: '#5164A6',
                    backgroundColor: '#5164A6',
                    fill: false,
                    lineTension: 0,
                    borderWidth: 1.3131,
                    pointBackgroundColor: "#5164A6"
                },
                {
                    label: props.userDetails?.fullname !== '' ? props.userDetails?.fullname +" " +t("super_admin_dashboard.performance") : t("dashboard.user") +" "+ t("super_admin_dashboard.performance"),
                    data: [user_performance.progress,user_performance.total_score, user_performance.overall_accuracy, user_performance.time_efficiency],
                    borderColor: '#37CE34',
                    backgroundColor: '#37CE34',
                    fill: false,
                    lineTension: 0,
                    borderWidth: 1.3131,
                    pointBackgroundColor: "#37CE34"
                }
            ]
            let labels = [ t("dashboard.progress"),t("dashboard.total_score"), t("dashboard.overall_accuracy"), t("dashboard.time_efficiency")];
       
            let data = {
                labels,
                datasets: dataset
            }
            return (
                <>
                    <div className='line_chart_section'>
                        <Line options={options} data={data} />
                    </div>


                </>
            )
        } else {
            return ("")
        }
    }

} export default TeacherGraphForUser