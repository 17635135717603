import React from 'react';
import { Line } from 'react-chartjs-2';
import graphOptions from './options';
import {useTranslation} from "react-i18next";

function GraphForUserStudent(props) {
    const {t} = useTranslation();

    const options = graphOptions

    if (props.userPerformanceGraph.length > 0) {


        let all_section_performance = props.userPerformanceGraph[0].all_section_performance;
        let section_performance = props.userPerformanceGraph[0].section_performance;
        let user_performance = props.userPerformanceGraph[0].user_performance;
        console.log(props.userDetails)
        let labels = [ t("dashboard.progress"),t("dashboard.total_score"), t("dashboard.overall_accuracy"), t("dashboard.time_efficiency")];

        let dataset = [
            {
                label: t("dashboard.your_grade"),
                data: [all_section_performance.progress,all_section_performance.total_score, all_section_performance.overall_accuracy, all_section_performance.time_efficiency],
                borderColor: '#FC8643',
                backgroundColor: '#FC8643',
                fill: false,
                lineTension: 0,
                borderWidth: 1.3131,
                pointBackgroundColor: "#FC8643"
            },
            {
                label: t("dashboard.your_section"),
                data: [section_performance.progress,section_performance.total_score, section_performance.overall_accuracy, section_performance.time_efficiency],
                borderColor: '#5164A6',
                backgroundColor: '#5164A6',
                fill: false,
                lineTension: 0,
                borderWidth: 1.3131,
                pointBackgroundColor: "#5164A6"
            },
            {
                label: t("dashboard.your_performance"),
                data: [ user_performance.progress,user_performance.total_score, user_performance.overall_accuracy, user_performance.time_efficiency],
                borderColor: '#37CE34',
                backgroundColor: '#37CE34',
                fill: false,
                lineTension: 0,
                borderWidth: 1.3131,
                pointBackgroundColor: "#37CE34"
            }
        ]

     
        let data = {
            labels,
            datasets: dataset
        }
        return (
            <>
                <div className='line_chart_section'>
                    <Line options={options} data={data} />
                </div>


            </>
        )
    } else {
        return ("")
    }

} export default GraphForUserStudent