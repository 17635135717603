import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import {useTranslation} from "react-i18next";

function SchoolRankClass(props) {

    const [schoolsList, setSchoolsList] = useState([])
    const [selectedClass, setSelectedClass] = useState(0)

    const {t} = useTranslation()

    const setListOfSchools = async (grade_name) => {

        setSchoolsList([])

        let url = `https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/schoolwise-rank?school_chain_id=${props.schoolChainId}`

        if (selectedClass != "0") {
            url = `https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/schoolwise-rank?school_chain_id=${props.schoolChainId}&grade_name=${encodeURIComponent(selectedClass)}`
        }

        console.log(url)
        const res = await fetch(url);
        const data = await res.json()
        console.log("data", data)
        if (data.statusCode === 200) {
            setSchoolsList(data.body)
        }
    }

    useEffect(() => {
        setListOfSchools()
    }, [selectedClass])

    const handleClassChange = (value) => {
        setSelectedClass(value)
    }

    console.log("selectedClass", selectedClass)
    console.log("schoolsList", schoolsList)
    return (
        <>
            <div className="super_school_rank_class">
                <div className="header">
                    <div className="heading">{t("super_admin_dashboard.schools_rank_in_class")}</div>
                    <div className="class_list">
                        <Dropdown className="custom_super_classlist_toggle">
                            <Dropdown.Toggle>
                                {selectedClass === 0 ? <span>{t("super_admin_dashboard.all_class")}</span> : <span>{t("super_admin_dashboard.class")} {(selectedClass !== undefined && selectedClass !== null) ? selectedClass : ""}</span>}
                                <i>
                                    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.0666 0.384342L11.0666 3.66902L5.88869 8.91539L0.710754 3.66902L0.710754 0.384342L5.88869 5.65352L11.0666 0.384342Z" fill="white" />
                                    </svg>
                                </i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {(selectedClass == 0) ?<Dropdown.Item style = {{color:"#FC8643"}} key={0} onClick={() => { handleClassChange(0) }}>{t("super_admin_dashboard.all_class")}</Dropdown.Item>: <Dropdown.Item style = {{color:"#7B7B7B"}} key={0} onClick={() => { handleClassChange(0) }}>{t("super_admin_dashboard.all_class")}</Dropdown.Item>}
                                {props.classList.map((value) => {
                                    return (
                                    (selectedClass == value.grade_name) ?<Dropdown.Item style = {{color:"#FC8643"}} key={value.grade_id} onClick={() => { handleClassChange(value.grade_name) }}>{t("super_admin_dashboard.class")} {value.grade_name}</Dropdown.Item>: <Dropdown.Item style = {{color:"#7B7B7B"}} key={value.grade_id} onClick={() => { handleClassChange(value.grade_name) }}>{t("super_admin_dashboard.class")} {value.grade_name}</Dropdown.Item>)
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="body">
                    <div className="table-responsive super_listing">
                        <table className="table table-bordered performance_section_table">
                            <thead>
                                <tr>
                                    <th>{t("super_admin_dashboard.rank")}</th>
                                    <th>{t("super_admin_dashboard.name")}</th>
                                    <th>{t("super_admin_dashboard.total_score_average")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {schoolsList && schoolsList.map((value, index) => {
                                    return (
                                        <tr key={value.school_id}>
                                            <td>{value.rank}</td>
                                            <td>{value.school_name === '' || value.school_name === ' ' ? value.user_id : value.school_name}</td>
                                            <td>{Math.floor(value.total_score_average)}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SchoolRankClass