import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
function StudentsRankClass(props) {

    const [studentList, setStudentList] = useState([])
    const [selectedClass, setSelectedClass] = useState(0)
    const {t} = useTranslation();

    const setListOfStudents = async (class_id) => {
        
        setStudentList([])

        let url = `https://duknujr2a2.execute-api.ap-south-1.amazonaws.com/prod/userwise-rank?school_id=${props.schoolId}`
        if (selectedClass !== 0) {
            console.log("this is selected class",selectedClass)
            url = `https://duknujr2a2.execute-api.ap-south-1.amazonaws.com/prod/userwise-rank?school_id=${props.schoolId}&grade_name=${encodeURIComponent(selectedClass.grade_name)}`
        }

        const res = await fetch(url);
        const data = await res.json()
        if (data.statusCode === 200) {
            
            setStudentList(data.userRank)
        }
    }
    useEffect(() => {
        setListOfStudents()
    }, [selectedClass])

    useEffect(() => {
        if (props.classList.length > 0) {
            setSelectedClass(props.classList[0])
        }

    }, [props.classList])
    const handleClassChange = (value) => {
        setSelectedClass(value)
    }
    console.log("studentList",studentList)
    return (
        <>
            <div className="super_school_rank_class">
                <div className="header">
                    <div className="heading">{t("dashboard.student_rank_in_class")}</div>
                    <div className="class_list">
                        <Dropdown className="custom_super_classlist_toggle">
                            <Dropdown.Toggle>
                                {selectedClass === 0 ? <span></span> : <span>{t("super_admin_dashboard.class")} {(selectedClass!==undefined && selectedClass!==null)? selectedClass.grade_name:""}</span>}
                                <i>
                                    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.0666 0.384342L11.0666 3.66902L5.88869 8.91539L0.710754 3.66902L0.710754 0.384342L5.88869 5.65352L11.0666 0.384342Z" fill="white" />
                                    </svg>
                                </i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {props.classList.map((value, index) => {
                                    return (<Dropdown.Item key={index} className={`${selectedClass.grade_name === value.grade_name ? 'active' : ""}`} onClick={() => { handleClassChange(value) }}>{t("super_admin_dashboard.class")} {value.grade_name}</Dropdown.Item>)

                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="body">
                    <div className="table-responsive super_listing">
                        <table className="table table-bordered performance_section_table">
                            <thead>
                                <tr>
                                <th>{t("super_admin_dashboard.rank")}</th>
                                    <th>{t("super_admin_dashboard.name")}</th>
                                    <th>{t("super_admin_dashboard.batch")}</th>
                                    <th>{t("super_admin_dashboard.total_score_average")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {studentList && studentList.map((value, index) => {

                                    return (<tr key={value.user_id}>
                                        <td>{value.rank}</td>
                                        <td>{value.fullname === '' || value.fullname === ' ' ? value.user_id : value.fullname}</td>
                                        <td>{value.user_batch === '' || value.user_batch === ' ' ? value.user_id : value.user_batch}</td>
                                        <td>{Math.floor(value.total_score)}</td>
                                    </tr>)
                                })}

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </>
    )
}
export default StudentsRankClass