import React, { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import { studentAvatar } from "services/utility";

function StudentListing(props) {

    const [studentList,setStudentList] = useState(props.studentList)
    const [classId,setClassId] = useState(props.classId)
    const [subjectId,setSubjectId] = useState(props.subjectId)

    const [activeReportId,setActiveReportId] = useState('')
    const [activeProfileId,setActiveProfileId] = useState('')

    const {t}= useTranslation()

    if(classId !== props.classId) {
        setClassId(props.classId)
    }
    if(subjectId !== props.subjectId) {
        setSubjectId(props.subjectId)
    }
    if(studentList.length !== props.studentList.length) {
        setStudentList(props.studentList)
    }

    useEffect(() => {
    },[activeProfileId])
    const getStudents = async () => {
        setActiveReportId('')
        setActiveProfileId('')
    }
    useEffect(() => {
        getStudents()
    },[studentList])

    useEffect(() => {
        if(props.userId === '') {
            setActiveReportId('')
            setActiveProfileId('')
        }
    },[props.userId])
    const showStudentReport = (user_id) => {
        if(user_id === props.userId && props.dashboardType === 'report') {
            setActiveReportId('');
            props.setDashboardData('','')
            return
        }
        setActiveReportId(user_id);
        setActiveProfileId('')
        props.setDashboardData('report',user_id)
    }

    const showStudentProfile = (user_id) => {

        if(user_id === props.userId && props.dashboardType === 'profile') {
            setActiveProfileId('');
            props.setDashboardData('','')
            return
        }
        setActiveProfileId(user_id);
        setActiveReportId('')
        props.setDashboardData('profile',user_id)
    }

    console.log("student_listing",props)

    return (
        <>
            {
                props.studentList.length > 0 && <div className="student_listing">
                    <table className="table table-borderless custom_student_listing_table" style={{marginBottom: "0px"}}>
                        <thead>
                            <tr>
                                <th className="px-1 text-center">{t("super_admin_dashboard.rank")}</th>
                                <th className="px-1 text-center">{t("super_admin_dashboard.name")}</th>
                                <th className="px-1 text-center">{t("super_admin_dashboard.score")}</th>
                                <th className="px-1 text-center">{t("quizzes_report_page.report")}</th>
                                <th className="px-1 text-center">{t("left_side_bar_menu.profile")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.studentList && props.studentList.map((val,index) => {
                                return (
                                    <tr key={index}>
                                        <td>{val.rank}</td>
                                        <td>
                                            <div className="row flex-nowrap">
                                                <div className="col-3 pr-0 mr-2">
                                                    <img
                                                        className="img-circle img-fluid"
                                                        src={studentAvatar(val.avatar)}
                                                        alt=""
                                                        width={"30"}
                                                        style={{minWidth: '20px'}}
                                                    /></div>
                                                <div className="text-left col-9 py-0 pl-0">{val.fullname === '' || val.fullname === ' ' ? val.user_id : val.fullname}</div>
                                            </div>
                                        </td>
                                        <td lassName="px-1 text-center" style={{color: "#464E5F"}}>{val.total_knowledge_wealth?.toFixed(1)}</td>
                                        {val.total_knowledge_wealth ?
                                            <>
                                                <td style={{padding: "0.5rem 0rem"}}>
                                                    <div className={`make_it_clickable ${activeReportId === val.user_id ? "active" : ""}`} onClick={() => {showStudentReport(val.user_id);}}>
                                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 1V17.9179H17.5305M4.09947 12.623L9.39439 7.32808L13.2687 10.9441L18.9511 5.39091" stroke="#9C9C9C" strokeWidth="1.79511" strokeLinecap="round" />
                                                        </svg>
                                                    </div>
                                                </td>
                                                <td style={{padding: "0.5rem 0rem"}}>
                                                    <div className={`make_it_clickable ${activeProfileId} ${activeProfileId === val.user_id ? "active" : ""}`} onClick={() => {showStudentProfile(val.user_id);}}>
                                                        <svg width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 8.59494C4.67106 2.86362 14.1852 -5.16023 22.8734 8.59494" stroke="#9C9C9C" strokeWidth="1.44444" strokeLinecap="round" />
                                                            <circle cx="12.0886" cy="8.89887" r="3.19849" stroke="#9C9C9C" strokeWidth="1.80556" />
                                                        </svg>

                                                    </div>
                                                </td>
                                            </>
                                            :
                                            <>
                                                <td style={{textAlign: "right"}}>{t("super_admin_dashboard.not_played")}</td>

                                            </>
                                        }

                                    </tr>

                                )
                            })}
                        </tbody>
                    </table>
                    {
                        props.studentListLoading && (
                            <div style={{textAlign: "center"}}>
                                <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="60px"
                                    height="60px"
                                    viewBox="0 0 80 30"
                                    style={{textAlign: "center",margin: "auto"}}
                                    className="m-auto text-center"
                                >
                                    <circle cx={10} cy={10} r={10} fill="#f16b24">
                                        <animate
                                            attributeName="cx"
                                            from={10}
                                            to={40}
                                            dur="0.5s"
                                            calcMode="spline"
                                            keySplines="0.42 0 0.58 1"
                                            keyTimes="0;1"
                                            repeatCount="indefinite"
                                        />
                                    </circle>
                                    <circle cx={10} cy={10} r={0} fill="#e4ac13">
                                        <animate
                                            attributeName="r"
                                            from={0}
                                            to={10}
                                            dur="0.5s"
                                            calcMode="spline"
                                            keySplines="0.42 0 0.58 1"
                                            keyTimes="0;1"
                                            repeatCount="indefinite"
                                        />
                                    </circle>
                                    <circle cx={40} cy={10} r={10} fill="#f16b24">
                                        <animate
                                            attributeName="cx"
                                            from={40}
                                            to={70}
                                            dur="0.5s"
                                            calcMode="spline"
                                            keySplines="0.42 0 0.58 1"
                                            keyTimes="0;1"
                                            repeatCount="indefinite"
                                        />
                                    </circle>
                                    <circle cx={70} cy={10} r={10} fill="#f16b24">
                                        <animate
                                            attributeName="r"
                                            from={10}
                                            to={0}
                                            dur="0.5s"
                                            calcMode="spline"
                                            keySplines="0.42 0 0.58 1"
                                            keyTimes="0;1"
                                            repeatCount="indefinite"
                                        />
                                    </circle>
                                </svg>
                            </div>
                        )
                    }
                </div>
            }
        </>
    )
}
export default StudentListing;