import React, { Component } from 'react';

import {LeftSidebarJsx} from './LeftSidebarMenu.jsx';
 

export class LeftSidebar extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <LeftSidebarJsx {...this.props}/>
        )
    } 
} 

export default LeftSidebar;