import Axios from "axios";
import { stringify } from "qs";
import { toBase64 } from "crypto-api/src/encoder/base64";
import { fromUtf } from "crypto-api/src/encoder/utf";
import Hmac from "crypto-api/src/mac/hmac";
import Sha256 from "crypto-api/src/hasher/sha256";

import urlconfig from "../pwaConfig.json";

export function getVerifyParam(queryString) {
  let HASHKey = urlconfig.tokenHmacKey;
  let hasher = new Sha256();
  let hmac = new Hmac(fromUtf(HASHKey), hasher);
  let timestamp = Math.round(new Date().getTime() / 1000); //1591927981; //
  let separator = `${queryString.includes("?") ? "&" : "?"}verify`;
  hmac.update(fromUtf(queryString + timestamp));
  let hmacresult = toBase64(hmac.finalize());
  let updatedParam = `${separator}=${timestamp}-${encodeURIComponent(
    hmacresult
  )}`;
  return updatedParam;
}

function isTokenRequired(url) {
  return url.startsWith(urlconfig.apiEndPoint);
}

function getUrlWithToken(url) {
  if (isTokenRequired(url)) {
    let arrParamUrl = url.split(urlconfig.apiEndPoint);
    if (arrParamUrl && arrParamUrl.length > 0) {
      let urlWithToken = getVerifyParam(arrParamUrl[1]);
      return url + urlWithToken;
    }
  } else {
    return url;
  }
}

Axios.interceptors.request.use(
  (conf) => {
    conf.url = getUrlWithToken(conf.url);
    return conf;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function createAxios() {
 
  const axios = Axios.create();
  axios.defaults.baseURL = `${urlconfig.apiEndPoint}/`;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.timeout = 120000; // 2*60*1000 = 120000 = 2 minutes
  // axios.defaults.headers.common["Authorization"] = authToken;
  axios.interceptors.request.use(
    (conf) => {
      conf.url = getUrlWithToken(conf.url);
      return conf;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => response?.data,
    (error) => {
      if (error?.response?.status === 401) {
        // unauthorized call
        //   return store.dispatch(logout());
      }
      if (error?.response?.data) return Promise.reject(error.response.data);

      return Promise.reject(error);
    }
  );
  return axios;
  // }
}


// Initialise Axios
const api = createAxios();

const service = {
  //third party GET API call
  getData(route, query = {}, options = {}) {
    return api.get(`${route}?${stringify(query)}`, options);
  },
  getById(route, id, options = {}) {
    return api.get(`${route}/${id}`, options);
  },
  post(route, payload = {}, options = {}) {
    return api.post(route, payload, options);
  },
  postQs(route, query = {}, payload = {}, options = {}) {
    return api.post(`${route}?${stringify(query)}`, payload, options);
  },

  get: Axios.get,
  // post: Axios.post,
  // put: Axios.put,
  // delete: Axios.delete,
  getApi(route, query = {}, options = {}) {
    return api.get(
      `${urlconfig.apiEndPoint}${route}?${stringify(query)}`,
      options
    );
  },

  getFeedApi(route, query = {}, options = {}) {
    return api.get(
      `${urlconfig.apiFeedEndPoint}${route}?${stringify(query)}`,
      options
    );
  },

  getByIdApi(route, id, options = {}) {
    return api.get(`${route}/${id}`, options);
  },
  postApi(route, payload = {}, options = {}) {
    return api.post(route, payload, options);
  },
  postQsApi(route, query = {}, payload = {}, options = {}) {
    return api.post(`${route}?${stringify(query)}`, payload, options);
  },
};

export default service;
