const routesNotAllowed = [
    // "dashboard",
    "pages-starter",
    "home/:roomId",
    "home",
    "room",
    "meeting/:roomId",
    "meeting",
    "reports",
    "create-quiz",
    "quiz-detail",
    "quiz-listing",
    "assessment-quiz",
    "report-details",
    "live-class",
    "chat",
    "assessments",
    "drive",
    "class-rooms/",
    "class-rooms",
    "class-rooms/*",
    "doubts",
    "doubts/*",
    "broadcast",
    "teacher/login",
    "teacher",
]

export default routesNotAllowed;