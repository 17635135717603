import { combineReducers } from "redux";
import io from "./io";
import user from "./user";
import auth from "./auth";
import uiStates from "./ui-states";
import config from "./config";
import playersReducer from "../quiz-socket/reducers/players";
import gameReducer from "../quiz-socket/reducers/game";
import clientTypeReducer from "../quiz-socket/reducers/clientType";

export default combineReducers({
  io,
  user,
  config,
  auth,
  uiStates,
  players: playersReducer,
  type: clientTypeReducer,
  game: gameReducer,
});
