import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";
import translationEN from "../src/locales/en/translation.json";
import translationGU from "../src/locales/gu/translation.json";
import translationHI from "../src/locales/hi/translation.json";
import translationMR from "../src/locales/mr/translation.json";
import translationTA from "../src/locales/ta/translation.json";
import translationTE from "../src/locales/te/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  gu: {
    translation: translationGU,
  },
  hi: {
    translation: translationHI,
  },
  mr: {
    translation: translationMR,
  },
  ta: {
    translation: translationTA,
  },
  te: {
    translation: translationTE,
  },
};

const detectionOption = {
  order: ["localStorage"],
  lookupLocalStorage: "I18N_LANGUAGE",
  caches: ["localStorage"],
};

i18n
  // .use(reactI18nextModule) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    // lng: "en",
    fallbackLng: "en", // English language would be used in case of language not found
    // keySeparator: false, // we do not use keys in form messages.welcome
    detection: detectionOption,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      bindI18n: "languageChanged", //function binding can be used from any component
    },
  });

export default i18n;