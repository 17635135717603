import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { MdEdit } from "react-icons/md";
import { showErrorMessage } from "services/utility";

function PrintPdfQuizModal({ questionsListArr }) {
  const [subject, setSubject] = useState("");
  const [board, setBoard] = useState("");
  const [grade, setGrade] = useState("");
  const [tempSubject, setTempSubject] = useState("");
  const [tempGrade, setTempGrade] = useState("");
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (questionsListArr && questionsListArr.length > 0) {
      setSubject(questionsListArr[0]?.subject || "");
      setBoard(questionsListArr[0]?.board || "");
      setGrade(questionsListArr[0]?.grade || "");
    }
  }, [questionsListArr]);

  const toggleModal = () => {
    if (!showModal) {
        setTempSubject(subject)
        setTempGrade(grade)
    }
    setShowModal(!showModal);
  };
  const handleSave =()=>{
   
    if(tempSubject === "" ){
        setSubject(questionsListArr[0]?.subject )
        
      showErrorMessage("Quiz name was empty")
    }else if (tempGrade === ""){
      setGrade(questionsListArr[0]?.grade)
      
      showErrorMessage("Grade was empty")
    }else{
      setGrade(tempGrade)
      setSubject(tempSubject)
    }
    // setGrade(tempGrade)
    toggleModal();
  }

  return (
    <>
      <div className="quizInfo" style={{ display: "flex" }}>
        <div className="infoSection" style={{ display: "flex", textTransform: "capitalize" }}>
          <span className="subjectInfo">{subject}</span>
        </div>
        <div style={{display:"flex"}}>
        <span className="printGrade" style={{ display: "flex", fontFamily:"Poppins", fontWeight:"600", 
                textTransform:"uppercase" }}>
            {board} | Grade {grade}
          </span>
        <div
          style={{
            border: "0.2px solid #000",
            display: "flex",
            alignItems: "center",
            paddingLeft: "0.2rem",
            paddingRight: "0.5rem",
            borderRadius: "8px",
            marginBottom: "20px",
            cursor: "pointer",
          }}
          onClick={toggleModal}
          className="editModalQuiz"
        >
         
          <MdEdit className="editIcon" /> <span className="editIconText">Edit</span>
        </div>
        </div>
      </div>

      <Modal isOpen={showModal} toggle={toggleModal} centered>
        <ModalHeader
          style={{ fontFamily: "Poppins", fontWeight: "600", color: "red" }}
          toggle={toggleModal}
        >
          Edit Quiz
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              marginBottom: "10px",
            }}
          >
            <label
              style={{
                fontFamily: "Poppins",
                fontWeight: "700",
                padding: "0px",
                margin: "0px",
              }}
            >
              Name of the Quiz
            </label>
            <input
              id="quizName"
              type="text"
              style={{
                border: "0.2px solid #DDDDDD",
                padding: "5px",
                borderRadius: "5px",
              }}
              value={tempSubject}
              onChange={(e)=>setTempSubject(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label
              style={{
                fontFamily: "Poppins",
                fontWeight: "700",
                padding: "0px",
                margin: "0px",
              }}
            >
              Grade
            </label>
            <input
              id="grade"
              type="number"
              style={{
                width: "150px",
                border: "0.2px solid #DDDDDD",
                padding: "5px",
                borderRadius: "5px",
              }}
              value={tempGrade}
              onChange={(e)=>{
                const value = e.target.value
                if(value.length <= 3){
                    setTempGrade(value)
                }
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{
              color: "#fff",
              marginLeft: "auto",
              marginRight: "auto",
              backgroundColor: "#FC8643",
              border: "none",
              borderRadius: "20px",
              fontSize: "18px",
              width: "100px",
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default PrintPdfQuizModal;
