import React from 'react';
import { Line } from 'react-chartjs-2';
import graphOptions from './options';
import { useTranslation } from 'react-i18next';

function GraphForTopic(props) {
    const {t} = useTranslation();

    const options = graphOptions
    if (props.topic.length !== 0) {
        let labels = [ t("dashboard.progress"),t("dashboard.total_score"), t("dashboard.overall_accuracy"), t("dashboard.time_efficiency")];
       

        let allSections = props.topic.topic_performance_graph.overall_topic_performance
        let grade_subjectWise = props.topic.topic_performance_graph.topic_performance

        let data = {
            labels,
            datasets: [
                {
                    label: t("dashboard.your_grade"),
                    data: [ allSections.progress,allSections.total_score, Math.floor(allSections.overall_accuracy), Math.floor(allSections.time_efficiency)],
                    borderColor: '#FC8643',
                    backgroundColor: '#FC8643',
                    fill: false,
                    lineTension: 0,
                    borderWidth: 1.3131,
                    pointBackgroundColor: "#FC8643"
                },
                {
                    label: t("dashboard.section") +" "+ props.selectedBatch,
                    data: [grade_subjectWise.progress,grade_subjectWise.total_score, Math.floor(grade_subjectWise.overall_accuracy), Math.floor(grade_subjectWise.time_efficiency)],
                    borderColor: '#5164A6',
                    backgroundColor: '#5164A6',
                    fill: false,
                    lineTension: 0,
                    borderWidth: 1.3131,
                    pointBackgroundColor: "#5164A6"
                }
            ]
        }
        return (
            <>
                <div className='line_chart_section'>
                    <Line options={options} data={data} />
                </div>


            </>
        )
    } else {
        return ("")
    }

} export default GraphForTopic