const Actions = {
  IO_INIT: "IO_INIT",
  SET_MESSAGES: "SET_MESSAGES",
  MORE_MESSAGES: "MORE_MESSAGES",
  MESSAGE: "MESSAGE",
  SET_ROOM: "SET_ROOM",
  SET_ROOMS: "SET_ROOMS",
  SET_CHAT_ROOMS: "SET_CHAT_ROOMS",
  SET_DOUBTS_ROOMS: "SET_DOUBTS_ROOMS",
  SET_CLASS_ROOMS: "SET_CLASS_ROOMS",
  ONLINE_USERS: "ONLINE_USERS",

  SET_EMOJI: "SET_EMOJIS",

  RTC_PRODUCER: "RTC_PRODUCER",
  RTC_PRODUCERS: "RTC_PRODUCERS",
  RTC_RESET_PRODUCERS: "RTC_RESET_PRODUCERS",
  RTC_LEAVE: "RTC_LEAVE",
  RTC_ROOM_ID: "RTC_ROOM_ID",
  RTC_CONSUMERS: "RTC_CONSUMERS",
  RTC_NEW_PEER: "RTC_NEW_PEER",
  RTC_CALL: "RTC_CALL",
  RTC_CLOSE: "RTC_CLOSE",
  RTC_ANSWER: "RTC_ANSWER",
  RTC_SET_COUNTERPART: "RTC_SET_COUNTERPART",

  MESSAGES_ADD_ROOM_UNREAD: "MESSAGES_ADD_ROOM_UNREAD",
  MESSAGES_REMOVE_ROOM_UNREAD: "MESSAGES_REMOVE_ROOM_UNREAD",

  REFRESH_MEETINGS: "REFRESH_MEETINGS",
  // Calendar
  ENABLE_CALENDAR_MODAL: "ENABLE_CALENDAR_MODAL",
  DISABLE_CALENDAR_MODAL: "DISABLE_CALENDAR_MODAL",
  UPDATE_CALENDAR_FORM: "UPDATE_CALENDAR_FORM",
  CHANGE_END_TIME: "CHANGE_END_TIME",
  CLEAR_FORM: "CLEAR_FORM",
  SUBMIT_SUCCESS: "SUBMIT_SUCCESS",
  SUBMIT_FAILURE: "SUBMIT_FAILURE",
  LOCAL_INFO: "LOCAL_INFO",
  POPULATE_TOOL_TIP: "POPULATE_TOOL_TIP",
  CLEAR_LOCAL_INFO: "CLEAR_LOCAL_INFO",
};

export default Actions;
