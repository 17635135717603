import store from "../../store/store";
import api from "services/api";
import { getSelectedChapterFromLocal } from "services/dataServices/userContentService";

function getPageInfo({ meta, match }) {
  let grade, board;
  switch (meta.name) {
    case "subject":
      return {
        grade: "",
        board: "",
        productType: "subject",
        entityId: match.params.subjectslug,
      };
    case "chapter":
      [, grade, board] = match.params.subjectslug.split("-");
      return {
        grade,
        board,
        productType: "chapter",
        entityId: match.params.chapterslug,
      };
    case "topic":
      [, grade, board] = match.params.subjectslug.split("-");
      return {
        grade,
        board,
        productType: "topic",
        entityId: match.params.topicslug,
      };
    default:
      [, grade, board] = match.params.subjectslug.split("-");
      return {
        grade,
        board,
        productType: "topic",
        entityId: match.params.topicslug,
      };
  }
}

function getPayload(user, route) {
  const page = getPageInfo(route);
  return {
    userId: user?.id,
    boardName: page?.board,
    gradeName: page?.grade,
    // boardName: user && user.board_name,
    // gradeName: user && user.grade_name,
    schoolId: user?.school_id,
    productType: page?.productType,
    entityId: page?.entityId,
  };
}

async function shouldCheckEntitlement(user, route) {
  const { grade: routeGrade, board: routeBoard } = getPageInfo(route);
  //CODE TO CHECK CHAPTER PAYWALL, AND USER ENTITLEMENT
  if (route.match.params.chapterslug) {
    let selectedChapter = await getSelectedChapterFromLocal(
      route.match.params.subjectslug,
      route.match.params.chapterslug
    );
    let isPayWall = true;
    // await checkingChapterEntry(user, selectedChapter.payWall);
    // console.log("Selected chapter ", selectedChapter);
    return (
      user?.grade_name != routeGrade ||
      user?.board_name?.toLowerCase() != routeBoard?.toLowerCase() ||
      !isPayWall
    );
  }
  return (
    user?.grade_name != routeGrade ||
    user?.board_name?.toLowerCase() != routeBoard?.toLowerCase()
  );
}

function checkingChapterEntry(user, payWall) {
  if (user !== null && payWall == "free") {
    switch (user.entitlements[0]) {
      case "Free":
        return true;
      case "ALL":
        return true;
      case "CONCEPTS":
        return true;
      case "SCHOLARSHIP":
        return true;
      default:
        return true;
    }
  }
  if (user !== null && (payWall == "" || payWall == "svod")) {
    switch (user.entitlements[0]) {
      case "Free":
        return false;
      case "ALL":
        return true;
      case "CONCEPTS":
        return true;
      case "SCHOLARSHIP":
        return true;
      default:
        return false;
    }
  }
  return false;
}

const contentGuard = async (to) => {
  try {
    const { user } = store.getState();
    // console.log("content guard", { to });
    // if (to.match.params.chapterslug) {
    //   if (shouldCheckEntitlement(user, to)) {
    //     const payload = getPayload(user, to);
    //     const { status } = await api.getApi("/getentitlement", payload);
    //     if (!status) return false;
    //   }
    // }
    return true;
  } catch (err) {
    return false;
  }
};

export default contentGuard;
