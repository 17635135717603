import React from "react";
import DashboardHome from "pages/DashboardHome/index";

import QuizTeleporting from "pages/CreateQuiz/components/Quiz/QuizTeleporting";
import { Redirect } from "react-router-dom";
import QuizReport from "pages/CreateQuiz/components/Quiz/QuizReport";
import PrintPDF from "pages/CreateQuiz/components/Quiz/PrintPDF";



// auth
import LoginPadhao from "../pages/Login";

import Login from "../pages/Onboarding/OtpLogin/OtpLogin";
import EnabledOtpLogin from "pages/Onboarding/OtpLogin/EnabledOtpLogin";
import SkillDetails from "pages/LearningFlow/SelectSubject/Skills/SkillDetails";
import SkillVideoPlayer from "pages/LearningFlow/SelectSubject/Skills/SkillVideoPlayer";
import Acadza from "pages/Acadza";
// lazy load all the views
const Reports = React.lazy(() => import("../pages/Reports"));
const References = React.lazy(() => import("../pages/References/Reference_comp"));
//const Assessments = React.lazy(() => import("../pages/Assessments"));

const CreateQuiz = React.lazy(() => import("../pages/CreateQuiz"));
const QuizDetail = React.lazy(() =>
  import("../pages/CreateQuiz/components/Quiz/QuizDetail")
);
const HomeWorkDetail = React.lazy(() =>
  import("../pages/CreateQuiz/components/Homework/HomeWorkDetail")
)


const QuizListing = React.lazy(() =>
  import("../pages/CreateQuiz/QuizListing")
);

const Profile = React.lazy(() => import("../pages/Profile"));
const SelectSubject = React.lazy(() =>
  import("../pages/LearningFlow/SelectSubject/SelectSubject")
);
const SelectChapter = React.lazy(() =>
  import("../pages/LearningFlow/SelectChapter/SelectChapter")
);
const Quizzes = React.lazy(() => import("../pages/Quizzes"))

const QuizPlay = React.lazy(() =>
  import("../pages/Quizzes/components/Quiz/index")
);
const SelectQuizSubject = React.lazy(() =>
  import("../pages/Quizzes/components/SelectQuizSubject/SelectQuizSubject")
);
const SelectTestSubject = React.lazy(() =>
  import("../pages/Quizzes/components/SelectQuizSubject/SelectTestSubject")
);
const SelectChallengeSubject = React.lazy(() =>
  import("../pages/Quizzes/components/SelectQuizSubject/SelectChallengeSubject")
);
const LatexTesting = React.lazy(() =>
  import("../pages/Quizzes/components/SelectQuizSubject/LatexTesting")
);
const LatexNewComponent = React.lazy(() =>
  import("../pages/Quizzes/components/SelectQuizSubject/LatexNewComponent")
);
const AssessmentPlay = React.lazy(() =>
  import("../pages/Quizzes/components/Assessment/index")
);
const ChallengePlay = React.lazy(() =>
  import("../pages/Quizzes/components/Challenge/index")
);

const ShowChallengeQuestion = React.lazy(() =>
  import(
    "../pages/Quizzes/components/Challenge/components/ShowChallengeQuestion"
  )
);
const QuizCreatingGame = React.lazy(() =>
  import("../pages/Quizzes/components/Challenge/components/QuizCreatingGame")
);
const WaitingLobby = React.lazy(() =>
  import("../pages/Quizzes/components/Challenge/components/WaitingLobby")
);
const Island = React.lazy(() =>
  import("../pages/LearningFlow/ShowContent/Island")
);
const TopicContent = React.lazy(() =>
  import("../pages/LearningFlow/ShowContent/TopicContent")
);
const ReviseContent = React.lazy(() =>
  import("../pages/LearningFlow/ShowContent/ReviseContent")
);
const TreasureContent = React.lazy(() =>
  import("../pages/LearningFlow/ShowContent/TreasureContent")
);

const Plans = React.lazy(() => import("../pages/Plans/index"));

const Bookmark = React.lazy(() => import("../pages/Bookmark/index"));

const BookmarkList = React.lazy(() => import("../pages/Bookmark/BookmarkList"));


const NoContentFound = React.lazy(() =>
  import("../pages/LearningFlow/NoContentFound/NoContentFound")
);

const KnowMore = React.lazy(() => import("../pages/KnowMore/KnowMore"));

const AskDoubt = React.lazy(() => import("../pages/AskDoubt"))

const StepsSolution = React.lazy(() => import("../pages/StepsSolution"))

const Resources = React.lazy(() => import("../pages/Resources"))



const SolutionList = React.lazy(() => import("../pages/AskDoubt/SolutionList"))

// declare all routes
const authProtectedRoutes = [
  { path: "/reports", component: Reports, meta: { auth: true } },
  { path: "/references", component: References, meta: { auth: true } },
  { path: "/create-quiz", component: CreateQuiz, meta: { auth: true } },
  { path: "/quiz-detail", component: QuizDetail, meta: { auth: true } },
  { path: "/quiz-report", component: QuizReport, meta: { auth: true } },
  { path: "/homework-detail", component: HomeWorkDetail, meta: { auth: true } },
  { path: "/quiz-listing", component: QuizListing, meta: { auth: true } },
  //{path: "/assessment-quiz", component: AssessmentSec, meta: {auth: true}},
  {
    path: "/concepts",
    component: SelectSubject,
    temp: "false",
    meta: { auth: true, temp: false },
  },
  {
    path: "/knowmore",
    component: KnowMore,
    temp: "false",
    meta: { auth: true, temp: false },
  },
  {
    path: "/:subjectslug/chapters",
    component: SelectChapter,
    meta: { auth: true, name: "subject" },
  },
  {
    path: "/:subjectslug/:chapterslug/topics",
    component: Island,
    meta: { auth: true, name: "chapter" },
  },
  {
    path: "/:subjectslug/:chapterslug/:topicslug/concept",
    component: TopicContent,
    meta: { auth: true, name: "topic" },
  },
  {
    path: "/revise/:subjectslug/:chapterslug",
    component: ReviseContent,
    meta: { auth: true },
  },
  {
    path: "/treasurecontent/:subjectslug/:chapterslug",
    component: TreasureContent,
    meta: { auth: true },
  },

  { path: "/profile", component: Profile, meta: { auth: true } },

  // -------- Dashboard Routes ---------/
  { path: "/dashboard", component: DashboardHome, meta: { auth: true } },

  /*------------------Assessment routes start-----------------------*/
  {
    path: "/assessment/practicequiz/challenge/:subject",
    component: ChallengePlay,
    meta: { auth: true },
  },
  {
    path: "/assessment/practicequiz/challenge",
    component: SelectChallengeSubject,
    meta: { auth: true },
  },
  {
    path: "/assessment/practicequiz/:subject",
    component: QuizPlay,
    meta: { auth: true },
  },
  {
    path: "/assessment/practicequiz",
    component: SelectQuizSubject,
    meta: { auth: true },
  },
  {
    path: "/assessment/practicetest/:subject/:assessmentId",
    component: AssessmentPlay,
    meta: { auth: true },
  },
  {
    path: "/assessment/practicetest/:subject/",
    component: AssessmentPlay,
    meta: { auth: true },
  },
  {
    path: "/assessment/practicetest/",
    component: SelectTestSubject,
    meta: { auth: true },
  },
  {
    path: "/assessment/quiz/:assessmentId",
    component: QuizPlay,
    meta: { auth: true },
  },
  {
    path: "/assessment/WaitingLobby/:roomcode",
    component: WaitingLobby,
    meta: { auth: true },
  },
  {
    path: "/assessment/challenge/question",
    component: ShowChallengeQuestion,
    meta: { auth: true },
  },
  {
    path: "/assessment/challenge/:assessmentId",
    component: ChallengePlay,
    meta: { auth: true },
  },
  {
    path: "/assessment/test/:assessmentId",
    component: AssessmentPlay,
    meta: { auth: true },
  },
  {
    path: "/assessment/showPlayers/:roomcode",
    component: QuizCreatingGame,
    meta: { auth: true },
  },
  {
    path: "/assessment/testing",
    component: LatexTesting,
    meta: { auth: true },
  },
  { path: "/assessment", component: Quizzes, meta: { auth: true } },

  { path: "/quiz_library", component: QuizTeleporting, meta: { auth: true } },

  /*------------------Assessment routes end-----------------------*/

  /*------------------No Content routes start-----------------------*/
  { path: "/nocontent", component: NoContentFound, meta: { auth: true } },
  /*------------------No Content routes end-----------------------*/

  /*------------------Bookmarks routes start-----------------------*/

  {
    path: "/:bookmarked/:subjectslug/:chapterslug/:topicslug/concept",
    component: TopicContent,
    meta: { auth: true, name: "topic" },
  },

  {
    path: "/bookmarkList/:subject",
    component: BookmarkList,
    meta: { auth: true },
  },
  { path: "/bookmarks", component: Bookmark, meta: { auth: true } },
  /*------------------Bookmarks routes end-----------------------*/

  { path: "/plans", component: Plans, meta: { auth: true } },
  {
    path: "/latex",
    exact: true,
    component: LatexNewComponent,
    meta: { auth: true },
  },
  {
    path: "/ask-a-doubt",
    component: AskDoubt,
    meta: { auth: true },
  },
  {
    path: "/steps-solution",
    component: StepsSolution,
    meta: { auth: true },
  },
  {
    path: "/resources",
    component: Resources,
    meta: { auth: true },
  },
  {
    path: "/solution-list",
    component: SolutionList,
    meta: { auth: true },
  },
  {
    path: "/:commerce_subject_name/skills-details",
    component: SkillDetails,
    meta: { auth: true },
  },
  {
    path: "/:commerce_subject_name/chapter/skill-video-player",
    component: SkillVideoPlayer,
    meta: { auth: true },
  },
  {
    path: "/acadza",
    component: Acadza,
    meta: { auth: true },
  },
  {
    path: "/print-pdf",
    component: PrintPDF,
    meta: { auth: true },
  },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to='/concepts' />,
    meta: { auth: true },
  },
];

const publicRoutes = [
  {
    path: "/login",
    exact: true,
    component: Login,
    meta: { guest: true },
  }, //mahesh changed the route Use this to login with otp
  {
    path: "/student/login",
    exact: true,
    component: Login, //mahesh changed this from login to EnabledOtpLogin
    meta: { guest: true },
  },

  {
    path: "/teacher/login",
    exact: true,
    component: LoginPadhao,
    meta: { guest: true },
  },
];

const routes = [...authProtectedRoutes, ...publicRoutes];

export { authProtectedRoutes, publicRoutes, routes };
