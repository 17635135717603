import React from "react"
import * as XLSX from "xlsx"
import "./total_student_table.scss"
import downloadgif from "../../../../../assets/images/teacher_assessment/download_icon.png"
import { useTranslation } from "react-i18next"




const TotalStudentTable = ({ board_name, schoolId, stats, graph }) => {
  console.log("total_student_table stats", stats)
  console.log("total_student_table graph", graph)
  const {t} = useTranslation();

  let st = "st"
  let nd = "nd"
  let rd = "rd"
  let th = "th"

  const downloadExcel = async (grade_id) => {

    const res = await fetch(
      `https://3oq9qugaj0.execute-api.ap-south-1.amazonaws.com/prod/userdatalist?school_id=${schoolId}&board_id=${board_name}&grade_id=${encodeURIComponent(grade_id)}`
    );
    const data = await res.json()
    if (data.statusCode === 200) {

      let data1 = data.active_user_list.map((s) => ({
        ...s,
        "active/inactive": "active",
      }));

      let data2 = data.inactive_user_list.map((s) => ({
        ...s,
        "active/inactive": "inactive",
      }));


      let excelData = [...data1, ...data2];

      const worksheet = XLSX.utils.json_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "DataSheet.xlsx");
    
    }  
  }

  return (
    <div>
      <div className='tableContainer'>
        <div className='headerContent'>
          <div className='leftContent'>
            <span>{t("dashboard.total_students")}: {stats?.total_students}</span>
            <p>
              {t("dashboard.active")}:&nbsp;{stats?.total_active_students}
              &nbsp;|&nbsp;{t("dashboard.inactive")}:&nbsp;
              {stats?.total_inactive_students}
            </p>
          </div>
          {/* <div className='rightContent'>
            <button type='button' onClick={downloadExcel}>
              DOWNLOAD&nbsp;EXCEL
            </button>
          </div> */}
        </div>
        <table className='table table-borderless' id='Table2XLSX'>
          <thead className='thread'>
            <tr>
              <th scope='col'>{t("dashboard.grade")}</th>
              <th scope='col'>{t("dashboard.total_students")}</th>
              <th scope='col'>{t("dashboard.active_students")}</th>
              <th scope='col'>{t("dashboard.inactive_students")}</th>
              { }
            </tr>
          </thead>
          {graph?.map((d) => (
            <tbody key={d.grade_id} style={{ margin: "1rem 0rem" }}>
              <tr>
                <th>
                  {d.grade_name === "1" && d.grade_name + st}
                  {d.grade_name === "2" && d.grade_name + nd}
                  {d.grade_name === "3" && d.grade_name + rd}
                  {d.grade_name > 3 && d.grade_name + th}
                </th>
                <td>{d.total_users}</td>
                <td>{d.active_users_count}</td>
                <td>{d.inactive_users_count}</td>
                <td>{window.innerWidth>720?
                  <button className='downloadButton' type='button' onClick={()=>downloadExcel(d.grade_id)}>
                    {t("dashboard.download")}
                  </button>
                  :<div style={{marginLeft:"-45px",maxHeight:"20px",maxWidth:"20px"}} onClick={()=>downloadExcel(d.grade_id)}>
                    <img src={downloadgif} /></div>}
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
      <div style={{ margin: "3rem 0", visibility: "hidden" }}>
        <h1>{t("assessment.giving_space")}</h1>
      </div>
    </div>
  )
}

export default React.memo(TotalStudentTable)
