
import React from 'react';
import { Line } from 'react-chartjs-2';
import graphOptions from './options';
import { useTranslation } from 'react-i18next';


function GraphForUserSubject(props) {
    const {t} = useTranslation();

    const options = graphOptions

    if (props.selectedSubjectData) {
        console.log("test",props)
        let subject = props.selectedSubjectData;
        let all_sections = props.allSectionsPerformance;
        let your_sections = props.selectedSubjectBatchData || [];
        let labels = [ t("dashboard.progress"),t("dashboard.total_score"), t("dashboard.overall_accuracy"), t("dashboard.time_efficiency")];
       
        let data = {
            labels,
            datasets: [
                {
                    label: t("dashboard.your_grade"),
                    data: [all_sections.progress,all_sections.total_score, all_sections.overall_accuracy, all_sections.time_efficiency],
                    borderColor: '#FC8643',
                    backgroundColor: '#FC8643',
                    fill: false,
                    lineTension: 0,
                    borderWidth: 1.3131,
                    pointBackgroundColor: "#FC8643"
                },
                {
                    label: t("dashboard.your_section"),
                    data: [your_sections.progress,your_sections.total_score, your_sections.overall_accuracy, your_sections.time_efficiency],
                    borderColor: '#5164A6',
                    backgroundColor: '#5164A6',
                    fill: false,
                    lineTension: 0,
                    borderWidth: 1.3131,
                    pointBackgroundColor: "#5164A6"
                },
                {
                    label: t("dashboard.your_performance"),
                    data: [subject.progress,subject.total_score, subject.overall_accuracy, subject.time_efficiency],
                    borderColor: '#37CE34',
                    backgroundColor: '#37CE34',
                    fill: false,
                    lineTension: 0,
                    borderWidth: 1.3131,
                    pointBackgroundColor: "#37CE34"
                }
            ]
        }
        return (
            <>
                <div className='line_chart_section'>
                    <Line options={options} data={data} />
                </div>


            </>
        )
    } else {
        return ("")
    }

} export default GraphForUserSubject